export enum timerStatus {
  MEASURING = 'measuring',
  FINISHED = 'finished',
}

export type TimerEntity = {
  userUuid: string
  taskUuid: string
  timerStatus: timerStatus
}

export type MeasuringTimer = {
  taskName: string
  parentWbsItemName: string
  startTime: number
  wbsItemTypeUrl: string
  taskUuid: string
}

export type TimerMeasuringTaskListDetail = {
  userName: string
  taskName?: string
  parentTaskName?: string
  startTime?: number
}
