import {
  Button,
  ClickAwayListener,
  Popper,
  styled,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material'
import AddIcon from '../../../../assets/add.svg'
import ArrowIcon from '../../../../assets/subdirectory_arrow_right.svg'
import { colorPalette } from '../../../style/colorPallete'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { useMemo } from 'react'
import { intl } from '../../../../i18n'

const BUTTON_SIZE: number = 14
const BUTTON_MARGIN: number = 5
const ADD_BUTTON_X: string = `${-BUTTON_SIZE * 1.5 - BUTTON_MARGIN}px`
const TOP_BUTTON_Y: string = `${-BUTTON_SIZE / 2}px`
const CHILD_BUTTON_X: string = `${-BUTTON_SIZE / 2}px`

const StyledIcon = styled('img')({
  width: '10px',
  height: '10px',
})

const StyledButton = styled(Button)({
  position: 'absolute',
  width: `${BUTTON_SIZE}px`,
  height: `${BUTTON_SIZE}px`,
  padding: '2px 0px 0px 0px',
  gap: '10px',
  borderRadius: '50%',
  border: `1px solid ${colorPalette.monotone[1]}`,
  opacity: '0px',
  color: colorPalette.monotone[4],
  background: colorPalette.monotone[0],
  minWidth: `${BUTTON_SIZE}px`,
  cursor: 'pointer',
  boxShadow: `0px 2px 4px 0px #7B8CAA4D`,
  '&:hover': {
    background: colorPalette.skyBlue[1],
    border: `1px solid ${colorPalette.skyBlue[1]}`,
  },
})

export const ButtonTypes = {
  addRowAbove: 'ADD_ROW_ABOVE',
  addRowBelow: 'ADD_ROW_BELOW',
  addChileRow: 'ADD_CHILD_ROW',
} as const

export type ButtonType = (typeof ButtonTypes)[keyof typeof ButtonTypes]

type Props = {
  anchorEl: HTMLElement | undefined
  originX?: number
  originY?: number
  height?: number | undefined
  visibleButtonTypes?: ButtonType[]
  onClickAddRowAbove?: () => void
  onClickAddRowBelow?: () => void
  onClickAddChildRow?: () => void
  onClose: () => void
}

const ActionButton = ({
  tooltipLabelId,
  tooltipPlacement,
  onClick,
  posX,
  posY,
  iconSource,
  iconSx,
}: {
  tooltipLabelId: string
  tooltipPlacement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  onClick: () => void
  posX: string
  posY: string
  iconSource: string
  iconSx?: SxProps<Theme>
}) => {
  return (
    <Tooltip
      title={intl.formatMessage({
        id: tooltipLabelId,
      })}
      placement={tooltipPlacement}
    >
      <StyledButton
        onClick={onClick}
        sx={{
          top: posX,
          left: posY,
        }}
      >
        <StyledIcon src={iconSource} sx={iconSx} />
      </StyledButton>
    </Tooltip>
  )
}

const ActionPopper = ({
  anchorEl,
  originX = 0,
  originY = 0,
  height = ROW_HEIGHT.SMALL,
  visibleButtonTypes = Object.values(ButtonTypes),
  onClickAddRowAbove,
  onClickAddRowBelow,
  onClickAddChildRow,
  onClose,
}: Props) => {
  const bottomButtonY = useMemo(() => `${height - BUTTON_SIZE / 2}px`, [height])
  const visibleAddRowAbove = useMemo(
    () => visibleButtonTypes.includes(ButtonTypes.addRowAbove),
    [visibleButtonTypes]
  )
  const visibleAddRowBelow = useMemo(
    () => visibleButtonTypes.includes(ButtonTypes.addRowBelow),
    [visibleButtonTypes]
  )
  const visibleAddChildRow = useMemo(
    () => visibleButtonTypes.includes(ButtonTypes.addChileRow),
    [visibleButtonTypes]
  )

  if (!anchorEl) return <></>
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement={'top-start'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [originX, -originY - height],
            },
          },
        ]}
        sx={{
          height: `${height}px`,
          zIndex: 9999,
        }}
      >
        {visibleAddRowAbove && (
          <ActionButton
            tooltipLabelId={'action.add.row.above'}
            tooltipPlacement={'top'}
            onClick={() => onClickAddRowAbove && onClickAddRowAbove()}
            posX={TOP_BUTTON_Y}
            posY={ADD_BUTTON_X}
            iconSource={AddIcon}
            iconSx={{
              margin: '0 0 2px 0',
            }}
          />
        )}
        {visibleAddRowBelow && (
          <ActionButton
            tooltipLabelId={'action.add.row.below'}
            tooltipPlacement={'bottom'}
            onClick={() => onClickAddRowBelow && onClickAddRowBelow()}
            posX={bottomButtonY}
            posY={ADD_BUTTON_X}
            iconSource={AddIcon}
            iconSx={{
              margin: '0 0 2px 0',
            }}
          />
        )}
        {visibleAddChildRow && (
          <ActionButton
            tooltipLabelId={'action.add.row.child'}
            tooltipPlacement={'bottom'}
            onClick={() => onClickAddChildRow && onClickAddChildRow()}
            posX={bottomButtonY}
            posY={CHILD_BUTTON_X}
            iconSource={ArrowIcon}
            iconSx={{
              margin: '0 0 2px 2px',
            }}
          />
        )}
      </Popper>
    </ClickAwayListener>
  )
}

export default ActionPopper
