import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { formatDate, formatDateTimeWithDay } from '../../../../utils/date'
import { FontSize } from '../../../../styles/commonStyles'
import { TimerMeasuringTaskListDetail } from '../../../../domain/entity/TimerEntity'
import { intl } from '../../../../i18n'
import { convertUnixToHMS } from '../../../../utils/time'
import { getTimerMeasuringTaskList } from '../../../../services/adaptors/timerRepositoryAdapter'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../store'

interface ResultList {
  userName: string
  taskName: string
  startTime: string
  elapsedTime: string
}

const RootDiv = styled('div')({
  width: '100%',
  height: 'calc(100% - 7px)',
  overflow: 'auto',
})
const TitleDiv = styled('div')({
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: `${FontSize.LARGE}px`,
})
const HeaderCell = styled(TableCell)({
  padding: '8px',
  borderBottom: 'none',
  fontWeight: 'bold',
})

const nameFormat = (Name: string) => {
  return Name.length > 10 ? Name.slice(0, 10) + '...' : Name
}

const TimerMeasuringTaskList = () => {
  const [timerMeasuringTaskListData, setTimerMeasuringTaskListData] =
    useState<TimerMeasuringTaskListDetail[]>()
  const [timerMeasuringTaskList, setTimerMeasuringTaskList] = useState<
    ResultList[]
  >([])
  const projectUuid = useSelector<AllState, string | undefined>(
    state => state.project.selected
  )
  const today = formatDate(new Date())
  const intervalForSendAPI = 30000
  const intervalForRendering = 1000

  useEffect(() => {
    if (projectUuid) {
      const fetchTimerMeasuringTasks = async () => {
        const newTimerMeasuringTaskList = await getTimerMeasuringTaskList(
          projectUuid
        )
        setTimerMeasuringTaskListData(newTimerMeasuringTaskList)
      }
      fetchTimerMeasuringTasks()
      const intervalId = setInterval(
        fetchTimerMeasuringTasks,
        intervalForSendAPI
      )
      return () => clearInterval(intervalId)
    }
  }, [projectUuid])

  useEffect(() => {
    const updateTimerMeasuringTaskList = () => {
      const resultList = timerMeasuringTaskListData?.map(item => {
        const startTime = item.startTime ? new Date(item.startTime) : new Date()
        const elapsedTime = new Date().getTime() - startTime.getTime()
        const formattedDate = formatDateTimeWithDay(item.startTime)
        const isInvalidData =
          !item.startTime || !item.taskName || !formattedDate

        return isInvalidData
          ? {
              userName: nameFormat(item.userName),
              taskName: intl.formatMessage({
                id: 'timerMeasuringList.header.inoccupation',
              }),
              startTime: '---',
              elapsedTime: '---',
            }
          : {
              userName: nameFormat(item.userName),
              taskName: `${nameFormat(item.parentTaskName!)} / ${nameFormat(
                item.taskName!
              )}`,
              startTime: formattedDate.toString(),
              elapsedTime: convertUnixToHMS(elapsedTime),
            }
      })
      setTimerMeasuringTaskList(resultList!)
    }

    updateTimerMeasuringTaskList()
    const intervalId = setInterval(
      updateTimerMeasuringTaskList,
      intervalForRendering
    )
    return () => clearInterval(intervalId)
  }, [timerMeasuringTaskListData])

  return (
    <RootDiv>
      <TitleDiv>{today}</TitleDiv>
      <Table aria-label="simple table" sx={{ boxShadow: 'none' }}>
        <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
          <TableRow>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.user',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.task',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.start',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.elapsed',
              })}
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timerMeasuringTaskList?.map((timerMeasuringTaskList, index) => (
            <TableRow key={index}>
              <TableCell sx={{ width: '170px' }}>
                {timerMeasuringTaskList.userName}
              </TableCell>
              <TableCell>{timerMeasuringTaskList.taskName}</TableCell>
              <TableCell sx={{ width: '200px' }}>
                {timerMeasuringTaskList.startTime}
              </TableCell>
              <TableCell sx={{ width: '100px' }}>
                {timerMeasuringTaskList.elapsedTime}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </RootDiv>
  )
}

export default TimerMeasuringTaskList
