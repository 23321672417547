import {
  Avatar,
  Box,
  ClickAwayListener,
  List,
  ListItem,
  Paper,
  styled,
  Typography,
  Popper,
} from '@mui/material'
import { format } from 'date-fns'
import { WbsItemDeltaInput, WbsItemRow } from '../../../lib/functions/wbsItem'
import { NewWbsItemRow } from '../../pages/ProjectPlanNew/projectPlanNew'
import {
  BorderColor,
  FontWeight,
  TextColor,
} from '../../../styles/commonStyles'
import { MyWbsItemRowBody } from '../../pages/MyWbsItems/myWbsItems'
import { intl } from '../../../i18n'
import moment from 'moment'
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_FORMAT_WITH_YEAR_DAY,
} from '../../../utils/date'
import { convertDecimalToHM } from '../../../utils/time'
const Header = styled(Box)({
  height: '32px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: TextColor.WHITE,
  backgroundColor: '#888888',
  borderRadius: '5px 5px 0 0',
})
const HeaderDate = styled(Typography)({
  marginLeft: '10px',
  fontSize: '12px',
})
const TaskName = styled(List)({
  padding: '0 0 0 10px',
  height: '30px',
  overflowX: 'hidden',
  overflowY: 'scroll',
  boxSizing: 'border-box',
}) as typeof List

const TimerActualWorkRow = styled(ListItem)({
  height: '30px',
  padding: 0,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const TimerList = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  margin: '0 20px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '32px',
  borderBottom: `1px solid ${BorderColor.GREY}`,
})
const StartTime = styled(Typography)({
  display: 'flex',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
  marginRight: 'auto',
})
const Endtime = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
  marginRight: 'auto',
})
const ElapsedTime = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
  display: 'flex',
})
const TotalRow = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '32px',
})
const Total = styled(Typography)({
  display: 'flex',
  fontWeight: FontWeight.BOLD,
  justifyContent: 'flex-end',
})
const PaperStyle = styled(Paper)({
  width: '320px',
  maxHeight: '400px',
  overflow: 'auto',
  borderRadius: '5px',
  fontSize: '11px',
  zIndex: 10,
  boxSizing: 'border-box',
})
const TaskNameBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  Width: '190px',
  height: '100%',
  alignItems: 'center',
})
const StyledAvatar = styled(Avatar)({
  width: 20,
  height: 20,
  margin: '0 5px 0 0',
})
const TaskNameText = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '0 8px 0 0',
})

interface Props {
  timer?: MyWbsItemRowBody | undefined
  anchorEl: HTMLElement | null
  projectUuid?: string
  wbsItem?: WbsItemRow | NewWbsItemRow
  wbsItemDelta?: Partial<WbsItemDeltaInput>
  onAfterUpdate: (wbsItem: WbsItemRow | NewWbsItemRow) => void
  onClose: () => void
}

function trimDateFromId(id: string): string {
  const match = id.match(/\d{4}-\d{2}-\d{2}/)
  return match ? match[0] : '日付が見つかりません'
}

export const TimerListDailyPopper: React.FC<Props> = props => {
  const { onClose, anchorEl, wbsItem, timer } = props
  const open = Boolean(anchorEl)
  const trimmedDate = trimDateFromId(anchorEl?.id || '')
  const clickedDay = moment(trimmedDate).format(DISPLAY_DATE_FORMAT)

  const filteredTimer = timer
    ? Object.values(timer).filter(item => {
        const itemDateStr = format(
          new Date(item.timerStartDateTime),
          'yyyy/MM/dd'
        )
        if (itemDateStr === clickedDay) {
          return item
        }
      })
    : []

  let total = filteredTimer
    ? Object.values(filteredTimer)
        .map(v => Number(v.timerElapsedTime))
        .reduce((acc, cur) => acc + cur, 0)
    : 0

  if (filteredTimer.length === 0) {
    return null
  }
  return (
    <Popper
      open={open}
      anchorEl={props.anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [-320, 0],
          },
        },
      ]}
      style={{ zIndex: 1300 }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <PaperStyle>
          <Header>
            <HeaderDate>
              {moment(clickedDay).format(DISPLAY_DATE_FORMAT_WITH_YEAR_DAY)}
            </HeaderDate>
          </Header>
          <TaskName>
            <TimerActualWorkRow>
              {wbsItem?.displayName && (
                <TaskNameBox>
                  <StyledAvatar
                    variant="circular"
                    src={wbsItem.wbsItemType?.iconUrl}
                  />
                  <TaskNameText>{wbsItem?.displayName}</TaskNameText>
                </TaskNameBox>
              )}
            </TimerActualWorkRow>
          </TaskName>
          {filteredTimer.map((timerRow, index) => (
            <TimerList key={index}>
              <StartTime>
                {`${intl.formatMessage({
                  id: 'myWbsItems.timer.startTime',
                })}: ${format(new Date(timerRow.timerStartDateTime), 'HH:mm')}`}
              </StartTime>
              <Endtime>
                {`${intl.formatMessage({
                  id: 'myWbsItems.timer.endTime',
                })}: ${format(new Date(timerRow.timerEndDateTime), 'HH:mm')}`}
              </Endtime>
              <ElapsedTime>
                {`${intl.formatMessage({
                  id: 'myWbsItems.timer.workingHours',
                })}: ${convertDecimalToHM(timerRow.timerElapsedTime)}`}
              </ElapsedTime>
            </TimerList>
          ))}
          <TotalRow>
            <Total>{`Total: ${convertDecimalToHM(total)}`}</Total>
          </TotalRow>
        </PaperStyle>
      </ClickAwayListener>
    </Popper>
  )
}

export default TimerListDailyPopper
