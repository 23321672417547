import API, { APIResponse } from '../../../lib/commons/api'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { Row } from '../../containers/BulkSheetView/model'
import { ProjectMemberProps } from '../../../lib/functions/projectMember'
import { TeamProps } from '../../../lib/functions/team'
import { UserBasic } from '../../../lib/functions/user'
import { DateTerm } from '../../../utils/date'
import { AttachmentSummary } from '../../../utils/attachment'
import { CommentSummary } from '../../../store/comments'
import { ProjectDetail } from '../../../lib/functions/project'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { SprintDetail } from '../../../lib/functions/sprint'
import { CUSTOM_ENUM_NONE } from '../../../lib/commons/customEnum'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../../domain/value-object/WbsItemTypeVO'
import store from '../../../store'
import { EntityExtensionValue } from '../../containers/meta/entityExtension'
import { MyWbsItemSearchConditionApiRequest } from '../../../services/model/myWbsItemSearchConditionApiRequest'
import { TagForWbsItem } from '../../../lib/functions/tag'

export class MyWbsItemData {
  uuid: string
  lockVersion: number

  projectUuid: string
  code?: string
  type: WbsItemType
  typeDto: WbsItemTypeObject
  baseTypeDto: WbsItemTypeObject
  displayName?: string
  description?: string
  team?: TeamProps
  accountable?: ProjectMemberProps
  responsible?: ProjectMemberProps
  assignee?: ProjectMemberProps
  estimatedStoryPoint?: number
  estimatedHour?: number
  priority?: string
  difficulty?: string
  scheduledDate?: DateTerm
  actualDate?: DateTerm
  actualHour?: number
  status?: string
  substatus?: string
  estimatedAmount?: number
  actualAmount?: number
  ticketType?: string
  ticketListUuid?: string
  watchers?: ProjectMemberProps[]
  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic

  project: ProjectDetail
  path: string
  parentWbsItem: MyWbsItem
  cumulation?: ProjectPlanCumulation
  sprint?: SprintDetail
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  actualWorks?: ActualWork[]
  tags?: TagForWbsItem[]
  timerStatus?: string
  timerElapsedTimes?: TimerElapsedTimes[]
}

export interface MyWbsItemRow extends Row {
  isTotalRow?: boolean
  type?: WbsItemType
  wbsItemUuid: string
  body?: MyWbsItemRowBody
  added?: boolean
  edited?: boolean
  editedData?: { [key: string]: any }
}

export class MyWbsItemRowBody {
  type: WbsItemType
  wbsItemUuid: string
  wbsItem: MyWbsItem
  project: ProjectDetail
  path: string
  parentWbsItem: MyWbsItem
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  extensions?: EntityExtensionValue[]
  actualWorks?: ActualWork[]
  tags?: TagForWbsItem[]
  timerStatus?: string
  timerElapsedTimes?: TimerElapsedTimes[]

  constructor(src: MyWbsItemData) {
    this.wbsItem = {
      projectUuid: src.projectUuid,
      uuid: src.uuid,
      lockVersion: src.lockVersion,

      code: src.code,
      type: src.type,
      wbsItemType: src.typeDto
        ? new WbsItemTypeVO(src.typeDto)
        : store.getState().project.wbsItemTypes.get(src.type)!,
      baseWbsItemType: src.baseTypeDto
        ? new WbsItemTypeVO(src.baseTypeDto)
        : store.getState().project.wbsItemTypes.get(src.type)!,
      displayName: src.displayName,
      status: src.status ? (src.status as WbsItemStatus) : undefined,
      substatus: src.substatus === CUSTOM_ENUM_NONE ? undefined : src.substatus,
      priority: src.priority === CUSTOM_ENUM_NONE ? undefined : src.priority,
      difficulty:
        src.difficulty === CUSTOM_ENUM_NONE ? undefined : src.difficulty,
      description: src.description ?? '',
      team: src.team,
      accountable: src.accountable,
      responsible: src.responsible,
      assignee: src.assignee,
      estimatedStoryPoint: src.estimatedStoryPoint,
      estimatedHour: src.estimatedHour,
      scheduledDate: {
        startDate: src.scheduledDate?.startDate,
        endDate: src.scheduledDate?.endDate,
      },
      actualDate: {
        startDate: src.actualDate?.startDate,
        endDate: src.actualDate?.endDate,
      },

      actualHour: src.actualHour,

      estimatedAmount: src.estimatedAmount,
      actualAmount: src.actualAmount,

      ticketType:
        src.ticketType === CUSTOM_ENUM_NONE ? undefined : src.ticketType,
      ticketListUuid: src.ticketListUuid,

      watchers: src.watchers,

      revision: src.revision,
      updatedAt: src.updatedAt,
      updatedBy: src.updatedBy,
      createdAt: src.createdAt,
      createdBy: src.createdBy,
      sprint: src.sprint,
    }
    this.project = src.project
    this.path = src.path
    this.parentWbsItem = src.parentWbsItem
    this.cumulation = src.cumulation
    this.deliverableAttachmentSummary = src.deliverableAttachmentSummary
    this.commentSummary = src.commentSummary
    this.actualWorks = src.actualWorks
    this.tags = src.tags
    this.timerStatus = src.timerStatus
    this.timerElapsedTimes = src.timerElapsedTimes
  }
}

export class MyWbsItem {
  uuid?: string
  lockVersion?: number
  projectUuid: string

  code?: string
  type: WbsItemType
  wbsItemType: WbsItemTypeVO
  baseWbsItemType: WbsItemTypeVO
  displayName?: string
  description?: string
  team?: TeamProps
  accountable?: ProjectMemberProps
  responsible?: ProjectMemberProps
  assignee?: ProjectMemberProps
  estimatedStoryPoint?: number
  estimatedHour?: number
  priority?: string
  difficulty?: string
  scheduledDate?: DateTerm
  actualDate?: DateTerm
  actualHour?: number
  status?: WbsItemStatus
  substatus?: string

  estimatedAmount?: number
  actualAmount?: number

  ticketType?: string
  ticketListUuid?: string

  watchers?: ProjectMemberProps[]
  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic

  sprint?: SprintDetail
}

export interface ActualWork {
  uuid: string
  lockVersion: number
  hour: number
  actualDate: string
  isEdited: boolean
}

export interface TimerElapsedTimes {
  timerDate: string
  timerStartDateTime: Number
  timerEndDateTime: Number
  timerElapsedTime: Number
}

export const fetchMyTask = async (
  request: MyWbsItemSearchConditionApiRequest
): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/projects/wbs_items/my_wbs_items/task/find',
    request
  )
}

export const fetchMyDeliverable = async (
  request: MyWbsItemSearchConditionApiRequest
): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/projects/wbs_items/my_wbs_items/deliverable/find',
    request
  )
}

export const fetchMyWatcher = async (
  request: MyWbsItemSearchConditionApiRequest
): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/projects/wbs_items/my_wbs_items/watcher/find',
    request
  )
}
