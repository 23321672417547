import { ICellRendererParams } from 'ag-grid-community'
import { ProjectPlanNewRow } from '../../projectPlanNew'
import styled from 'styled-components'
import { useCallback, useMemo } from 'react'

const NumberDiv = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const ProjectPlanNewActualHourCellRenderer = (
  params: ICellRendererParams<ProjectPlanNewRow>
) => {
  const { value, data } = params
  const format = useCallback((v: number) => {
    return Number.isFinite(v) ? parseFloat(v.toString()).toFixed(2) : ''
  }, [])
  const isClicable = useMemo(
    () =>
      data?.body?.wbsItem.wbsItemType?.isTask() &&
      Number.EPSILON < Math.abs(value),
    [data, value]
  )
  const displayValue = useMemo(() => format(value), [value, format])
  const summary = useMemo(() => {
    const c = data?.body?.cumulation
    if (
      !c ||
      c.countTask - c.countStatusTaskDiscard <= 0 ||
      !data?.body?.wbsItem?.wbsItemType?.isTask()
    ) {
      return undefined
    }
    const rate: number =
      params.context.workloadUnitState?.hoursPerSelectedUnit || 1
    const sumActualHour = ((c.actualHour ?? 0) + (c.sumActualHour ?? 0)) / rate
    return ` / ${format(sumActualHour / rate)}`
  }, [data, params.context.workloadUnitState?.hoursPerSelectedUnit, format])

  if (value === undefined) {
    return ''
  }

  return (
    <NumberDiv
      className={'sevend-ag-cell'}
      style={{ cursor: isClicable ? 'pointer' : undefined }}
    >
      <span style={{ color: isClicable ? 'blue' : undefined }}>
        {displayValue}
      </span>
      {summary && <span>&nbsp;{summary}</span>}
    </NumberDiv>
  )
}
