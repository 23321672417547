import _ from 'lodash'
import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community'
import {
  createNewProjectPlanNewRow,
  ProjectPlanNewRow,
} from '../projectPlanNew'
import { intl } from '../../../../i18n'
import {
  addRowsBefore,
  addRowsToLastChild,
  updateRows,
} from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { dateTermToViewConfig, openProgressReport } from '../../ProgressReport'
import store from '../../../../store'
import { open } from '../../../router'
import {
  openWbsItemSearch,
  SearchConditionEndDelayed,
} from '../../WbsItemSearch/wbsItemSearchOptions'
import { openTicketList } from '../../TicketLists/TicketListsOptions'
import { getParent } from '../../../containers/BulkSheetView/lib/tree'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import {
  focusRow,
  getSelectedNode,
} from '../../../containers/BulkSheetView/lib/gridApi'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import {
  getEarliestScheduledDate,
  getLatestScheduledDate,
} from '../../../../lib/functions/projectPlan'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import DateVO from '../../../../vo/DateVO'
import { ReportReCalculator } from './cumulation'
import { APPLICATION_FUNCTION_EXTERNAL_ID, getPathByExternalId } from '../..'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'
import { openKanban } from '../../Kanban/Kanban'
import { StatusKanbanSearchCondition } from '../../Kanban/hooks/useSearchCondition'
import { dateTermVoService } from '../../../../domain/value-object/DateTermVO'
import { moveRowsToLastChild } from '../../../containers/BulkSheetView/hooks/actions/moveTreeRows'
import ReactDOMServer from 'react-dom/server'
import { TicketListSubMenuItemsIcon } from '../../../components/icons/TicketListSubMenuItemsIcon'
import SelectColorPalette from '../../ProgressReport/Chart/Legend/SelectColorPalette'

export const addRowAboveMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  setData: (d: ProjectPlanNewRow[], r: ProjectPlanNewRow[]) => void
): MenuItemDef[] => {
  const row = params.node?.data
  const parent = getParent(data, row)
  const count = params.api.getSelectedNodes().length

  const types = store.getState().project.wbsItemTypes
  const subMenuTypes = !parent?.body
    ? []
    : [
        types.process,
        types.deliverableList,
        types.deliverable,
        types.task,
      ].filter(type => type.canBeChildOf(parent.body!.wbsItem.wbsItemType))

  if (subMenuTypes.length === 0) return []

  const subMenu = subMenuTypes.map(type => ({
    name: type.name,
    icon: `<img src="${type.iconUrl}" />`,
    action: () => {
      const rows = Array.from({ length: count }).map(_ =>
        createNewProjectPlanNewRow(type)
      )
      const newData = addRowsBefore(data, rows, row.uuid)
      const uuids = rows.map(v => v.uuid)
      const newRows = newData.filter(v => uuids.includes(v.uuid))
      setData(newData, newRows)
      rows[0]?.uuid && focusRow(params.api, rows[0].uuid)
    },
    shortcut: intl.formatMessage(
      { id: 'bulksheet.contextMenu.shortcut.alt.shift' },
      {
        shortcutKey: type.isProcess()
          ? 'H'
          : type.isDeliverableList()
          ? 'J'
          : type.isDeliverable()
          ? 'K'
          : 'L',
      }
    ),
  }))

  return [
    {
      name: intl.formatMessage({
        id: 'bulksheet.contextMenu.insert.above.row',
      }),
      icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
      subMenu,
    },
  ]
}

export const addRowToChildMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  setData: (d: ProjectPlanNewRow[], r: ProjectPlanNewRow[]) => void
): MenuItemDef[] => {
  const row = params.node?.data

  const types = store.getState().project.wbsItemTypes
  const subMenuTypes = !row?.body
    ? []
    : [
        types.process,
        types.deliverableList,
        types.deliverable,
        types.task,
      ].filter(type => type.canBeChildOf(row.body!.wbsItem.wbsItemType))

  const subMenu = subMenuTypes.map(type => ({
    name: type.name,
    icon: `<img src="${type.iconUrl}" />`,
    action: () => {
      const newRow = createNewProjectPlanNewRow(type)
      const newData = addRowsToLastChild(data, [newRow], row.uuid)
      const newRows = newData.filter(v => v.uuid === newRow.uuid)
      setData(newData, newRows)
      params.node?.setExpanded(true)
      focusRow(params.api, newRow.uuid)
    },
    shortcut: intl.formatMessage(
      { id: 'bulksheet.contextMenu.shortcut.ctrl.shift' },
      {
        shortcutKey: type.isProcess()
          ? 'H'
          : type.isDeliverableList()
          ? 'J'
          : type.isDeliverable()
          ? 'K'
          : 'L',
      }
    ),
  }))

  if (subMenu.length === 0) return []

  return [
    {
      name: intl.formatMessage({
        id: 'bulksheet.contextMenu.insert.toChild.row',
      }),
      icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
      subMenu,
    },
  ]
}

export const addRowsToChildMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  action: {
    onAddRows: (type: WbsItemTypeVO, parentUuid: string) => void
  }
): MenuItemDef[] => {
  const row = params.node?.data

  const wbsItemTypes = store.getState().project.wbsItemTypes
  const subMenuTypes = wbsItemTypes
    .getAll()
    .filter(
      type =>
        !type.isWorkgroup() && type.canBeChildOf(row.body!.wbsItem.wbsItemType)
    )

  if (subMenuTypes.length === 0) return []

  return [
    {
      name: intl.formatMessage({ id: 'addMultipleRows.wbsItem' }),
      icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
      subMenu: subMenuTypes.map(type => ({
        name: type.name,
        icon: `<img src="${type.iconUrl}" />`,
        action: () => action.onAddRows(type, row.uuid),
      })),
    },
  ]
}

export const addWorkgroupMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  action: { setData: (data: ProjectPlanNewRow[]) => void }
) => {
  const wbsItemTypes = store.getState().project.wbsItemTypes
  const workgroupType = wbsItemTypes.workgroup
  return [
    {
      name: intl.formatMessage({ id: 'bulksheet.contextMenu.workgroup' }),
      icon: `<img src="${workgroupType.iconUrl}" />`,
      action: () => {
        const workgroupType = store.getState().project.wbsItemTypes.workgroup
        const workgroup = createNewProjectPlanNewRow(workgroupType)
        workgroup.treeValue = [workgroup.uuid]
        workgroup.added = true
        action.setData([...data.concat(), workgroup])
        focusRow(params.api, workgroup.uuid)
      },
    },
  ]
}

export const changeProcessToWorkgroupMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  action: {
    setData: (data: ProjectPlanNewRow[], moved: ProjectPlanNewRow[]) => void
  }
) => {
  const row: ProjectPlanNewRow = params.node?.data
  const isChangeable = row?.body?.wbsItem.wbsItemType.isProcess()

  const wbsItemTypes = store.getState().project.wbsItemTypes
  const workgroupType = wbsItemTypes.workgroup
  return [
    {
      name: intl.formatMessage({
        id: 'bulksheet.contextMenu.changeProcessToWorkgroup',
      }),
      icon: `<img src="${workgroupType.iconUrl}" />`,
      disabled: !isChangeable,
      action: () => {
        if (!row || !row.body) {
          return
        }
        const workgroupType = store.getState().project.wbsItemTypes.workgroup
        row.body.wbsItem.wbsItemType = workgroupType
        row.edited = true
        if (!row.editedData) {
          row.editedData = {}
        }
        row.editedData['body.wbsItem.wbsItemType'] =
          row.body.wbsItem.wbsItemType
        action.setData(moveRowsToLastChild(data, [row], undefined), [row])
      },
    },
  ]
}

export const addTicketMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  action: {
    setData: (d: ProjectPlanNewRow[], r: ProjectPlanNewRow[]) => void
    onAddTicket: (parentUuid: string, ticketType: WbsItemTypeVO) => void
    onAddTickets: (parentUuid: string) => void
  }
): (string | MenuItemDef)[] => {
  const row = params.node?.data
  const ticketTypes = store.getState().project.ticketListTypes
  if (!row?.body || !ticketTypes) return []

  const ticketListSubMenuItems = addTicketListSubMenuItems(
    params,
    data,
    action.setData
  )
  const ticketSubMenuItems = addTicketSubMenuItems(
    params,
    data,
    action.onAddTicket
  )
  const menus = [
    ticketListSubMenuItems.length
      ? {
          name: intl.formatMessage({
            id: 'bulksheet.contextMenu.ticketList',
          }),
          subMenu: ticketListSubMenuItems,
        }
      : undefined,
    ticketSubMenuItems.length
      ? {
          name: intl.formatMessage({
            id: 'bulksheet.contextMenu.ticket.add.multiple',
          }),
          action: () => action.onAddTickets(row.uuid),
        }
      : undefined,
    ticketSubMenuItems.length
      ? {
          name: intl.formatMessage({
            id: 'bulksheet.contextMenu.ticket',
          }),
          subMenu: ticketSubMenuItems,
        }
      : undefined,
  ].filter(v => !!v) as MenuItemDef[]
  if (menus.length) return ['separator', ...menus]
  return []
}

const addTicketListSubMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  setData: (d: ProjectPlanNewRow[], r: ProjectPlanNewRow[]) => void
): MenuItemDef[] => {
  const row = params.node?.data
  const ticketTypes = store.getState().project.ticketListTypes
  if (!row?.body || !ticketTypes) return []

  return ticketTypes
    .filter((ticketType: WbsItemTypeVO) =>
      ticketType.canBeChildOf(row.body!.wbsItem.wbsItemType)
    )
    .map(ticketType => ({
      name: ticketType.name,
      // /src/view/components/icons/TicketListSubMenuItemsIcon.tsx に配置したコンポーネントをhtmlに変換するために
      // ReactDOMServer.renderToStaticMarkupを使用している
      icon: ReactDOMServer.renderToStaticMarkup(
        TicketListSubMenuItemsIcon(ticketType)
      ),
      action: () => {
        const newTicketList = createNewProjectPlanNewRow(ticketType)
        const newData = addRowsToLastChild(data, [newTicketList], row.uuid)
        const newRows = newData.filter(v => v.uuid === newTicketList.uuid)
        setData(newData, newRows)
        params.node?.setExpanded(true)
        focusRow(params.api, newTicketList.uuid)
      },
    }))
}

const addTicketSubMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  onAddTicket: (parentUuid: string, ticketType: WbsItemTypeVO) => void
): MenuItemDef[] => {
  const row = params.node?.data
  const ticketTypes = store.getState().project.ticketTypes
  if (!row?.body || !ticketTypes) return []

  return ticketTypes
    .filter((ticketType: WbsItemTypeVO) =>
      ticketType.canBeChildOf(row.body!.wbsItem.wbsItemType)
    )
    .map((ticketType: WbsItemTypeVO) => ({
      name: ticketType.name,
      icon: `<img src="${ticketType.iconUrl}" />`,
      action: () => onAddTicket(row.uuid, ticketType),
    }))
}

export const deleteRowMenuItems = (
  params: GetContextMenuItemsParams,
  selectedNodes: RowNode<ProjectPlanNewRow>[],
  data: ProjectPlanNewRow[],
  action: () => void
): MenuItemDef[] => {
  const cumulation = params.node?.data?.body?.cumulation
  const isActualHourRegistered = !(
    cumulation &&
    cumulation.sumActualHour === 0 &&
    cumulation.actualHour === 0
  )
  const allChildren = selectedNodes
    .map(v => v.allLeafChildren)
    .flat()
    .map(v => v?.data)
    .filter(v => v)
  const targets: ProjectPlanNewRow[] = _.uniqBy(allChildren, 'uuid')
  const hasTicketListWithChild = targets.some(
    v => 0 < (v.body?.ticketCumulation?.countTicket ?? 0)
  )
  const canDelete =
    params.node?.data?.added ||
    (!isActualHourRegistered && !hasTicketListWithChild)
  return [
    {
      name: intl.formatMessage(
        { id: 'bulksheet.contextMenu.delete' },
        { count: selectedNodes.length }
      ),
      icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
      action,
      disabled: !canDelete,
      tooltip: isActualHourRegistered
        ? intl.formatMessage({
            id: 'bulksheet.contextMenu.remove.disabled.reason.actual.registerd',
          })
        : hasTicketListWithChild
        ? intl.formatMessage({
            id: 'bulksheet.contextMenu.remove.disabled.reason.exists.ticket',
          })
        : undefined,
      shortcut: intl.formatMessage({
        id: 'bulksheet.contextMenu.shortcut.row.delete',
      }),
    },
  ]
}

export const reportMenuItems = (
  params: GetContextMenuItemsParams,
  data: ProjectPlanNewRow[],
  action: { setData: (data: ProjectPlanNewRow[]) => void }
): (string | MenuItemDef)[] => {
  const row = params.node?.data
  const wbsItem = row?.body?.wbsItem
  const projectUuid = params.context.projectUuid
  const updateRow = (updated: ProjectPlanNewRow, node: RowNode) => {
    updated.edited = true
    action.setData(updateRows(data, [updated]))
    node &&
      params.api.refreshCells({
        rowNodes: [node],
        columns: [
          'body.wbsItem.scheduledDate.startDate',
          'body.wbsItem.scheduledDate.endDate',
          'ganttChart',
        ],
      })
  }
  const updateAncestors = (
    before: ProjectPlanNewRow,
    after: ProjectPlanNewRow
  ) => {
    const ancestors = before.treeValue
      .slice(0, -1)
      .map(id => params.api.getRowNode(id)?.data)
      .filter(v => v) as ProjectPlanNewRow[]
    ReportReCalculator.update(before, after, ancestors).forEach(ancestor => {
      const node = params.api.getRowNode(ancestor.uuid)
      node?.setData(ancestor)
    })
  }
  if (!row || !wbsItem || !projectUuid) return []
  const menus = [
    // Open progress report
    params.node?.hasChildren()
      ? {
          name: intl.formatMessage({ id: 'openProgressReport' }),
          icon: getMenuIconHtml(ContextMenuItemId.OPEN_PROGRESS_REPORT),
          action: () => {
            const searchCondition = {
              rootWbsItemUuid: wbsItem.uuid,
            }
            const viewConfig = dateTermToViewConfig(wbsItem.scheduledDate)
            openProgressReport(projectUuid, searchCondition, viewConfig)
          },
        }
      : undefined,
    // Open sprint report
    (wbsItem.wbsItemType.isProcess() || wbsItem.wbsItemType.isWorkgroup()) &&
    !row.added
      ? {
          name: intl.formatMessage({ id: 'openSprintReport' }),
          icon: getMenuIconHtml(ContextMenuItemId.OPEN_SPRINT_REPORT),
          action: async () => {
            const projectCode = store.getState().project.current?.code
            open(
              `/sprintReport/${projectCode}?rootWbsItem=${wbsItem.uuid}`,
              undefined,
              undefined,
              true
            )
          },
        }
      : undefined,
    // Open kanban
    row?.body?.cumulation?.countTask > 0 && !row.added
      ? {
          name: intl.formatMessage({ id: 'kanban' }),
          icon: getMenuIconHtml(ContextMenuItemId.OPEN_KANBAN),
          action: () => {
            const projectCode = store.getState().project.current?.code
            const query: Partial<StatusKanbanSearchCondition> = {
              rootUuid: wbsItem.uuid,
            }
            openKanban(projectCode!, query)
          },
        }
      : undefined,
    // Search delayed wbs
    params.node?.hasChildren()
      ? {
          name: intl.formatMessage({ id: 'searchDelayedWbs' }),
          icon: getMenuIconHtml(ContextMenuItemId.SEARCH_DELAYED_WBS),
          action: () => {
            openWbsItemSearch(
              projectUuid,
              SearchConditionEndDelayed.with({
                rootUuid: wbsItem.uuid,
              })
            )
          },
        }
      : undefined,
    // Open ticket list
    wbsItem.wbsItemType.isDeliverable() && wbsItem.wbsItemType.isTicket()
      ? {
          name: intl.formatMessage({ id: 'openTicketList' }),
          icon: getMenuIconHtml(ContextMenuItemId.OPEN_TICKET_LIST),
          action: () => {
            openTicketList(wbsItem.uuid)
          },
        }
      : undefined,
    params.node?.hasChildren()
      ? {
          name: intl.formatMessage({ id: 'getPlanFromChildren' }),
          subMenu: [
            {
              name: intl.formatMessage({
                id: 'getPlanFromChildren.bothDate',
              }),
              action: async () => {
                params.api.getSelectedNodes().forEach(async node => {
                  const data = node.allLeafChildren.map(v => v.data)
                  const row = node.data
                  if (!row.body?.wbsItem) return
                  const earliestDate = await getEarliestDate(row, data)
                  const latestDate = await getLatestDate(row, data)
                  const before = _.cloneDeep(row)
                  row.body.wbsItem.scheduledDate = {
                    startDate: earliestDate,
                    endDate: latestDate,
                  }
                  updateRow(row, node)
                  updateAncestors(before, row)
                })
              },
              icon: getMenuIconHtml(
                ContextMenuItemId.GET_PLAN_FROM_CHILDREN_BOTH_DATE
              ),
            },
            {
              name: intl.formatMessage({
                id: 'getPlanFromChildren.earliestDate',
              }),
              action: async () => {
                params.api.getSelectedNodes().forEach(async node => {
                  const data = node.allLeafChildren.map(v => v.data)
                  const row = node.data
                  if (!row.body?.wbsItem) return
                  const before = _.cloneDeep(row)
                  row.body.wbsItem.scheduledDate.startDate =
                    await getEarliestDate(row, data)
                  updateRow(row, node)
                  updateAncestors(before, row)
                })
              },
              icon: getMenuIconHtml(
                ContextMenuItemId.GET_PLAN_FROM_CHILDREN_EARLIEST_DATE
              ),
            },
            {
              name: intl.formatMessage({
                id: 'getPlanFromChildren.latestDate',
              }),
              action: async () => {
                params.api.getSelectedNodes().forEach(async node => {
                  const data = node.allLeafChildren.map(v => v.data)
                  const row = node.data
                  if (!row.body?.wbsItem) return
                  const before = _.cloneDeep(row)
                  row.body.wbsItem.scheduledDate.endDate = await getLatestDate(
                    row,
                    data
                  )
                  updateRow(row, node)
                  updateAncestors(before, row)
                })
              },
              icon: getMenuIconHtml(
                ContextMenuItemId.GET_PLAN_FROM_CHILDREN_LATEST_DATE
              ),
            },
          ],
          disabled: !params.api
            .getSelectedNodes()
            .every(node => node.hasChildren()),
          icon: getMenuIconHtml(ContextMenuItemId.GET_PLAN_FROM_CHILDREN),
          tooltip: !params.node.hasChildren()
            ? intl.formatMessage({
                id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
              })
            : undefined,
        }
      : undefined,
  ].filter(v => !!v) as MenuItemDef[]
  if (menus.length) return ['separator', ...menus]
  return []
}

const getEarliestDate = async (
  row: RowNode['data'],
  data: ProjectPlanNewRow[]
): Promise<string | undefined> => {
  const stored = await fetchEarliestDate(row)
  const displayed = getEarliestDateFromGrid(row, data)
  const list = [stored, displayed].filter(v => !!v) as DateVO[]
  if (0 < list.length) {
    return DateVO.min(list)?.serialize()
  }
  const { projectUuid } = row.body.wbsItem
  store.dispatch(
    addScreenMessage(projectUuid, {
      type: MessageLevel.WARN,
      title: intl.formatMessage({
        id: 'projectPlan.getPlanFromChildren.has.no.earliest.date',
      }),
    })
  )
  return undefined
}

const fetchEarliestDate = async (
  row: RowNode['data']
): Promise<DateVO | undefined> => {
  const { projectUuid } = row.body.wbsItem
  if (row.added) return undefined
  const projectPlanUuid = row.uuid
  const date = (await getEarliestScheduledDate(projectUuid, projectPlanUuid))
    .json
  return isNaN(Date.parse(date)) ? undefined : new DateVO(date)
}

const getEarliestDateFromGrid = (
  row: RowNode['data'],
  data: ProjectPlanNewRow[]
): DateVO | undefined => {
  const projectPlanUuid = row.uuid
  const dateList = data
    .filter(
      v =>
        v.edited &&
        v.uuid !== projectPlanUuid &&
        v.treeValue.includes(projectPlanUuid) &&
        !!v.body?.wbsItem.scheduledDate?.startDate
    )
    .map(v => new DateVO(v.body!.wbsItem.scheduledDate!.startDate))
  return 0 < dateList.length ? DateVO.min(dateList) : undefined
}

const getLatestDate = async (
  row: RowNode['data'],
  data: ProjectPlanNewRow[]
): Promise<string | undefined> => {
  const stored = await fetchLatestDate(row)
  const displayed = getLatestDateFromGrid(row, data)
  const list = [stored, displayed].filter(v => !!v) as DateVO[]
  if (0 < list.length) {
    return DateVO.max(list)?.serialize()
  }
  const { projectUuid } = row.body.wbsItem
  store.dispatch(
    addScreenMessage(projectUuid, {
      type: MessageLevel.WARN,
      title: intl.formatMessage({
        id: 'projectPlan.getPlanFromChildren.has.no.latest.date',
      }),
    })
  )
  return undefined
}

const fetchLatestDate = async (
  row: RowNode['data']
): Promise<DateVO | undefined> => {
  const { projectUuid } = row.body.wbsItem
  if (row.added) return undefined
  const projectPlanUuid = row.uuid
  const date = (await getLatestScheduledDate(projectUuid, projectPlanUuid)).json
  return isNaN(Date.parse(date)) ? undefined : new DateVO(date)
}

const getLatestDateFromGrid = (
  row: RowNode['data'],
  data: ProjectPlanNewRow[]
): DateVO | undefined => {
  const projectPlanUuid = row.uuid
  const dateList = data
    .filter(
      v =>
        v.edited &&
        v.uuid !== projectPlanUuid &&
        v.treeValue.includes(projectPlanUuid) &&
        !!v.body?.wbsItem.scheduledDate?.endDate
    )
    .map(v => new DateVO(v.body!.wbsItem.scheduledDate!.endDate))
  return 0 < dateList.length ? DateVO.max(dateList) : undefined
}

export const switchRootWbsMenuItems = (
  params: GetContextMenuItemsParams
): MenuItemDef[] => {
  const row: ProjectPlanNewRow = params.node?.data

  return [
    {
      name: intl.formatMessage({
        id: 'bulksheet.contextMenu.switchRoot.wbsItem',
      }),
      disabled: !row,
      action: async () => {
        window.open(
          `${window.location.origin}/projectPlan/${
            store.getState().project.current?.code
          }?treeRootUuid=${row.uuid}`
        )
      },
      icon: getMenuIconHtml(ContextMenuItemId.SWITCH_ROOT_WBS_ITEM),
    },
  ]
}
