import { useMemo } from 'react'
import {
  ProgressOverviewPageContent,
  ProgressReportByProcessPageContent,
  ProgressReportByTeamPageContent,
  VersionReportVersionEntity,
  VersionReportVersionPageContent,
  VersionReportVersionPageEntity,
} from '../../../../domain/entity/version-report/VersionReportVersionEntity'
import { useVersionReportVersionRepository } from '../../../../services/adaptors/versionReportVersionRepositoryAdaptor'
import { isAiAvailable } from '../../../../lib/functions/ai'

export const useEvaluate = (
  currentVersion: VersionReportVersionEntity | undefined,
  currentPage: VersionReportVersionPageEntity | undefined,
  updatePageContent: <
    K extends keyof VersionReportVersionPageContent,
    V extends VersionReportVersionPageContent[K]
  >(
    path: K,
    value: V
  ) => void
) => {
  const {
    evaluateOverview,
    evaluateProgressByProcess,
    evaluateProgressByTeam,
  } = useVersionReportVersionRepository()
  const evaluate = useMemo(() => {
    if (!isAiAvailable()) {
      return undefined
    }
    if (!currentPage || !currentVersion) {
      return undefined
    }
    switch (currentPage.pageType) {
      case 'PROGRESS_OVERVIEW':
        return async () => {
          if (currentPage.config.type === 'PROGRESS_OVERVIEW') {
            const response = await evaluateOverview({
              snapshotUuid: currentVersion.snapshotUuid,
              leafUuids: currentPage.config.targetUuids,
            })
            const update = updatePageContent as <
              K extends keyof ProgressOverviewPageContent,
              V extends ProgressOverviewPageContent[K]
            >(
              path: K,
              value: V
            ) => void
            update('status', response.result.status)
            update('comment', response.result.comment)
          }
        }

      case 'PROGRESS_REPORT_BY_PROCESS':
        return async () => {
          if (currentPage.config.type === 'PROGRESS_REPORT_BY_PROCESS') {
            const response = await evaluateProgressByProcess({
              snapshotUuid: currentVersion.snapshotUuid,
              projectPlanUuids: currentPage.config.tableRows,
            })

            const update = updatePageContent as <
              K extends keyof ProgressReportByProcessPageContent,
              V extends ProgressReportByProcessPageContent[K]
            >(
              path: K,
              value: V
            ) => void
            update('tableContents', response.result)
          }
        }

      case 'PROGRESS_REPORT_BY_TEAM':
        return async () => {
          if (currentPage.config.type === 'PROGRESS_REPORT_BY_TEAM') {
            const response = await evaluateProgressByTeam({
              snapshotUuid: currentVersion.snapshotUuid,
              teamUuids: currentPage.config.tableRows,
              rootUuid: currentPage.config.rootUuid,
            })

            const update = updatePageContent as <
              K extends keyof ProgressReportByTeamPageContent,
              V extends ProgressReportByTeamPageContent[K]
            >(
              path: K,
              value: V
            ) => void
            update('tableContents', response.result)
          }
        }
    }
  }, [
    currentPage,
    currentVersion,
    evaluateOverview,
    evaluateProgressByProcess,
    evaluateProgressByTeam,
    updatePageContent,
  ])
  return { evaluate }
}
