import { StopCircle } from '@mui/icons-material'
import { Avatar, Box, Typography } from '@mui/material'
import styled from 'styled-components'
import { FontSize, FontWeight } from '../../../../styles/commonStyles'
import { colorPalette } from '../../../style/colorPallete'
import { useCallback, useEffect, useState } from 'react'
import { useTimerRepository } from '../../../../services/adaptors/timerRepositoryAdapter'
import { stopTimer } from '../../../../store/measuringTimer'
import { useDispatch } from 'react-redux'
import { convertUnixToHM } from '../../../../utils/time'

const UPDATE_INTERVAL = 60000

const MeasuringTimerBox = styled(Box)({
  width: '260px',
  height: '55px',
  border: 'solid 1px',
  borderColor: '#CCCCCC',
  borderRadius: '4px',
  position: 'relative',
  margin: '0 40px 0 0px',
  color: colorPalette.monotone[1],
})
const TaskNameText = styled(Typography)({
  fontSize: FontSize.LARGE,
  padding: '5px 10px 0 0',
  width: '150px',
  color: colorPalette.monotone[10],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
const StyledAvatar = styled(Avatar)({
  width: 20,
  height: 20,
  margin: '5px 5px 0 0',
})

const Timer = styled(Typography)({
  fontSize: FontSize.X_LARGE,
  padding: '5px 10px 0 70px',
  fontWeight: FontWeight.BOLD,
  position: 'absolute',
  top: '12px',
  right: '50px',
  color: colorPalette.monotone[10],
})

const StopButton = styled(StopCircle)({
  color: '#F05291',
  width: '40px',
  height: '40px',
  position: 'absolute',
  top: '5px',
  right: '5px',
})

interface Props {
  taskName: string
  parentWbsItemName: string
  startTime: number
  wbsItemType: string
  userUuid: string
  taskUuid: string
}

const MeasuringTimer = ({
  taskName,
  parentWbsItemName,
  startTime,
  wbsItemType,
  userUuid,
  taskUuid,
}: Props) => {
  const [timerDiff, setTimerDiff] = useState(0)
  const dispatch = useDispatch()

  const updateTimerDiff = useCallback(() => {
    const nowTime = new Date().getTime()
    const diffMilliSeconds = nowTime - startTime
    if (diffMilliSeconds < 0) {
      setTimerDiff(0)
      return
    }
    setTimerDiff(diffMilliSeconds)
  }, [startTime])

  useEffect(() => {
    const timerId = setInterval(updateTimerDiff, UPDATE_INTERVAL)
    updateTimerDiff()
    return () => clearInterval(timerId)
  }, [updateTimerDiff])

  const { stop } = useTimerRepository()
  const handleClick = useCallback(async () => {
    await stop(userUuid, taskUuid)
    dispatch(stopTimer())
  }, [userUuid, taskUuid])

  return (
    <MeasuringTimerBox>
      <TaskNameText>{parentWbsItemName}</TaskNameText>
      <Box display="flex">
        <StyledAvatar variant="circular" src={wbsItemType} />
        <TaskNameText>{taskName}</TaskNameText>
        <Timer>{`${convertUnixToHM(timerDiff)}`}</Timer>
        <StopButton onClick={handleClick} />
      </Box>
    </MeasuringTimerBox>
  )
}

export default MeasuringTimer
