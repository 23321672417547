import React, { useMemo } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import DateVO from '../../../../../../../../vo/DateVO'
import { intl } from '../../../../../../../../i18n'
import { CalendarDateVO } from '../../../../../../../../domain/value-object/CalendarDateVO'
import _ from 'lodash'
import {
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../../../../../../domain/value-object/GanttParameterVO'

const GanttGroupHeader = ({ children }) => (
  <div className="sevend-ag-cell-gantt-group-header">{children}</div>
)

const GanttGroupMonthHeader = ({ children, style }) => (
  <span
    style={style}
    className="sevend-ag-cell-gantt-group-header__month-header"
  >
    {children}
  </span>
)

export default (props: IHeaderParams) => {
  const timeScale: CalendarDateVO[] = props.context.ganttDisplayTimeScale
  const parameter: GanttParameterVO = props.context.ganttParameter
  if (!timeScale || timeScale.length === 0) {
    return <div>{props?.column?.getColDef()?.headerName}</div>
  }
  const groups: { label: string; length: number }[] = useMemo(() => {
    const groupedTimeScale = timeScale.map(v => {
      const date = new DateVO(v.date)
      const { unit } = parameter
      return date.format(
        intl.formatMessage({
          id:
            unit === GanttDisplayUnit.MONTH
              ? 'dateFormat.yyyy'
              : 'date.format.yyyy.m',
        })
      )
    })
    const uniqTimeScale = _.sortedUniq(groupedTimeScale)
    return uniqTimeScale.map(label => {
      return {
        label: label,
        length: groupedTimeScale.filter(v => v === label).length,
      }
    })
  }, [timeScale, parameter])

  return (
    <GanttGroupHeader>
      {groups.map(({ label, length }, index) => (
        <GanttGroupMonthHeader
          key={`group-gantt-header-${index}`}
          style={{ width: `${(length / timeScale.length) * 100}%` }}
        >
          {label}
        </GanttGroupMonthHeader>
      ))}
    </GanttGroupHeader>
  )
}
