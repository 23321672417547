import _ from 'lodash'
import { createDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { APIResponse } from '../../../../lib/commons/api'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import {
  CreateTicketInput,
  UpdateTicketDeltaInput,
  TicketType,
  TicketDetail,
} from '../../../../lib/functions/ticket'
import { ProjectMemberProps } from '../../../../lib/functions/projectMember'
import { isTagColumnEdited } from '../../../../lib/functions/tag'
import { WbsItemDeltaInput } from '../../../../lib/functions/wbsItem'
import { hasDiffDateTerm } from '../../../../utils/date'
import { generateUuid } from '../../../../utils/uuids'
import {
  EntityExtensionValue,
  EntityExtensionValueDelta,
  serializeExtensionValue,
} from '../../../containers/meta/entityExtension'
import { NewWbsItemRow } from '../../ProjectPlanNew/projectPlanNew'
import { TicketRow, TicketWbsItemRow } from '../tickets'
import {
  getGeneralTicketDataManager,
  createRowByResponse as generalCreateRowByResponse,
} from './general'
import { getRiskTicketDataManager } from './risk'
import { TicketListBasic } from '../../../../lib/functions/ticketList'
import { getRefinementTicketDataManager } from './refinement'
import { serializeExtensionValueDelta } from '../../../containers/BulkSheetView/gridOptions/extension'
import { wbsItemAdditionalPropertyValuesVoService } from '../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'

export const generateCreateTicketInput = ({
  projectUuid,
  row,
  prevSiblingUuid,
  extensionInput,
}: {
  projectUuid: string
  row: TicketRow
  prevSiblingUuid: string | undefined
  extensionInput:
    | {
        uuid: string
        value: any
      }[]
    | undefined
}): CreateTicketInput => {
  const wbsItem: TicketWbsItemRow | undefined = row.wbsItem
  if (!wbsItem) return {} as CreateTicketInput

  return {
    projectUuid,
    uuid: row.uuid,
    ticketType: wbsItem.ticketType!,
    ticketListUuid: row.ticketList?.uuid!,
    prevSiblingUuid,
    parentWbsItemUuid: row.parentWbsItem?.uuid!,
    projectPlan: {
      projectUuid,
      uuid: generateUuid(),
      type: wbsItem.type,
      ticketType: wbsItem.ticketType,
      wbsItem: {
        ...wbsItem,
        typeUuid: wbsItem.wbsItemType.uuid,
        displayName: wbsItem.displayName ?? '',
        description: wbsItem.description ?? '',
        teamUuid: wbsItem.team?.uuid,
        accountableUuid: wbsItem.accountable?.uuid,
        responsibleUuid: wbsItem.responsible?.uuid,
        assigneeUuid: wbsItem.assignee?.uuid,
        watchers: wbsItem.watchers?.map(v => v.uuid),
        scheduledDate: {
          startDate: wbsItem.scheduledDate?.startDate,
          endDate: wbsItem.scheduledDate?.endDate,
        },
        actualDate: {
          startDate: wbsItem.actualDate?.startDate,
          endDate: wbsItem.actualDate?.endDate,
        },
        sprintUuid: wbsItem.sprint?.uuid,
        additionalPropertyValues: wbsItem.additionalPropertyValues
          ? wbsItemAdditionalPropertyValuesVoService.serialize(
              wbsItem.additionalPropertyValues
            )
          : undefined,
      },
      productBacklogItem: false,
    },
    extensions: extensionInput,
  }
}

export const generateUpdateWbsItemDeltaInput = (
  original: NewWbsItemRow,
  wbsItem: NewWbsItemRow,
  extension: EntityExtensionValueDelta[]
): WbsItemDeltaInput => {
  return {
    uuid: wbsItem.uuid,
    type: wbsItem.type,
    status: createDelta(original.status, wbsItem.status),
    substatus: createDelta(original.substatus, wbsItem.substatus),
    displayName: createDelta(original.displayName, wbsItem.displayName),
    description: createDelta(original.description, wbsItem.description),
    teamUuid: createDelta(original.team?.uuid, wbsItem.team?.uuid),
    accountableUuid: createDelta(
      original.accountable?.uuid,
      wbsItem.accountable?.uuid
    ),
    responsibleUuid: createDelta(
      original.responsible?.uuid,
      wbsItem.responsible?.uuid
    ),
    assigneeUuid: createDelta(original.assignee?.uuid, wbsItem.assignee?.uuid),
    estimatedStoryPoint: createDelta(
      original.estimatedStoryPoint,
      wbsItem.estimatedStoryPoint
    ),
    estimatedHour: createDelta(original.estimatedHour, wbsItem.estimatedHour),
    priority: createDelta(original.priority, wbsItem.priority),
    difficulty: createDelta(original.difficulty, wbsItem.difficulty),
    scheduledDate: hasDiffDateTerm(
      original.scheduledDate,
      wbsItem.scheduledDate
    )
      ? {
          oldValue: original.scheduledDate,
          newValue: wbsItem.scheduledDate,
        }
      : undefined,
    actualDate: hasDiffDateTerm(original.actualDate, wbsItem.actualDate)
      ? {
          oldValue: original.actualDate,
          newValue: wbsItem.actualDate,
        }
      : undefined,
    estimatedAmount: createDelta(
      original.estimatedAmount,
      wbsItem.estimatedAmount
    ),
    actualAmount: createDelta(original.actualAmount, wbsItem.actualAmount),
    extensions: extension,
    sprintUuid: createDelta(original.sprint?.uuid, wbsItem.sprint?.uuid),
    additionalPropertyValues: wbsItem.additionalPropertyValues
      ? wbsItemAdditionalPropertyValuesVoService.serializeDelta(
          original.additionalPropertyValues,
          wbsItem.additionalPropertyValues
        )
      : undefined,
  } as WbsItemDeltaInput
}

export const generateUpdateTicketDeltaInput = ({
  originalRow,
  currentRow,
  originalPrevSiblingUuid,
  prevSiblingUuid,
  extensionInput,
}: {
  originalRow: TicketRow
  currentRow: TicketRow
  originalPrevSiblingUuid: string | undefined
  prevSiblingUuid: string | undefined
  extensionInput: EntityExtensionValueDelta[] | undefined
}): UpdateTicketDeltaInput => {
  return {
    uuid: currentRow.uuid,
    ticketListUuid: createDelta(
      originalRow.ticketList?.uuid,
      currentRow.ticketList?.uuid
    ),
    prevSiblingUuid: {
      oldValue: originalPrevSiblingUuid,
      newValue: prevSiblingUuid,
    },
    wbsItem: generateUpdateWbsItemDeltaInput(
      originalRow.wbsItem!,
      currentRow.wbsItem!,
      extensionInput ?? []
    ),
    extensions: extensionInput ?? [],
  }
}

export const gernerateTicketDeleteRequest = (
  deletedRows: TicketRow[],
  getUuidAndLockVersion: (row: TicketRow) => {
    uuid: string | undefined
    lockVersion: number | undefined
  }
) => {
  return deletedRows
    .filter((row: TicketRow) => row.wbsItem?.uuid && row.wbsItem?.lockVersion)
    .map((row: TicketRow) => {
      const { uuid, lockVersion } = getUuidAndLockVersion(row)
      return {
        uuid,
        lockVersion,
        wbsItemUuid: row.wbsItem!.uuid!,
        wbsItemLockVersion: row.wbsItem!.lockVersion!,
      }
    })
}

export const generateUpdateWathersRequest = (
  originalRows: TicketRow[],
  changedTicketRows: TicketRow[]
) => {
  return changedTicketRows
    .filter((row: TicketRow) => {
      const watcherToString = (watcher: ProjectMemberProps[]) =>
        watcher
          .map(w => w.uuid)
          .sort()
          .join()
      const original =
        originalRows.find(d => d.uuid === row.uuid)?.wbsItem?.watchers ?? []
      const newData = row.wbsItem?.watchers ?? []
      return (
        (row.added || row.edited) &&
        row.wbsItem &&
        watcherToString(original) !== watcherToString(newData)
      )
    })
    .map((row: TicketRow) => {
      const wbsItem: TicketWbsItemRow = row.wbsItem!
      return {
        uuid: row.uuid,
        wbsItemUuid: wbsItem.uuid,
        userUuids:
          wbsItem.watchers && Array.isArray(wbsItem.watchers)
            ? wbsItem.watchers.map(v => v.uuid)
            : [],
      }
    })
}

export const generateUpdateTagsRequest = (
  originalRows: TicketRow[],
  changedTicketRows: TicketRow[]
) => {
  return changedTicketRows
    .filter((row: TicketRow) => {
      const original =
        originalRows.find(v => v.uuid === row.uuid)?.wbsItem?.tags ?? []
      const newData = row.wbsItem?.tags ?? []
      return (
        (row.added || row.edited) &&
        row.wbsItem &&
        isTagColumnEdited(original, newData)
      )
    })
    .map((row: TicketRow) => {
      const wbsItem: TicketWbsItemRow = row.wbsItem!
      return {
        uuid: row.uuid,
        wbsItemUuid: wbsItem.uuid,
        tagUuids:
          wbsItem.tags && Array.isArray(wbsItem.tags)
            ? wbsItem.tags.map(v => v.uuid)
            : [],
      }
    })
}

interface GenerateRequestParams<TRow extends TicketRow, TTicketRequest> {
  row?: TRow
  ticketRequest?: TTicketRequest
  extensionInput?: any[] | undefined
}

export interface GenerateCreateRequestParams<TRow extends TicketRow>
  extends GenerateRequestParams<TRow, CreateTicketInput> {}

export const generateCreateRequest = <TRow extends TicketRow, TInput>(
  projectUuid: string,
  allData: TicketRow[],
  changed: TicketRow[],
  generateCreateInput: (
    params: GenerateCreateRequestParams<TRow>
  ) => TInput | undefined
): TInput[] => {
  return changed
    .filter((row: TicketRow) => row.added && row.wbsItem?.displayName)
    .map((row: TicketRow): TInput | undefined => {
      const index = allData.findIndex(d => d.uuid === row.uuid)
      const prevSiblingUuid = index <= 0 ? undefined : allData[index - 1]?.uuid
      const extensionInput = row?.extensions?.map(v =>
        serializeExtensionValue(v.uuid, v.value)
      )
      const createTicketRequest = generateCreateTicketInput({
        projectUuid,
        row,
        prevSiblingUuid,
        extensionInput,
      })
      return generateCreateInput({
        row: row as TRow,
        ticketRequest: createTicketRequest,
        extensionInput,
      })
    })
    .filter(d => !!d) as TInput[]
}

export interface GenerateUpdateRequestParams<TRow extends TicketRow>
  extends GenerateRequestParams<TRow, UpdateTicketDeltaInput> {
  originalRow: TRow
}

export const generateUpdateDeltaBatchRequest = <TRow extends TicketRow, TInput>(
  originalData: TicketRow[],
  currentData: TicketRow[],
  changed: TicketRow[],
  generateUpdateDeltaInput: (
    params: GenerateUpdateRequestParams<TRow>
  ) => TInput | undefined
) => {
  return changed
    .filter((row: TicketRow) => row.edited && !row.added && row.wbsItem)
    .map((row: TicketRow): TInput | undefined => {
      const originalIndex = originalData.findIndex(d => d.uuid === row.uuid)
      if (originalIndex < 0) return undefined

      const original = originalData[originalIndex]!
      const originalPrevSiblingUuid =
        originalIndex <= 0 ? undefined : originalData[originalIndex - 1]?.uuid
      const originalExtensions = original.extensions ?? []
      const extensionInput = (row.extensions ?? [])
        .map(v =>
          serializeExtensionValueDelta(v.uuid, {
            oldValue: originalExtensions.find(e => e.uuid === v.uuid)?.value,
            newValue: v.value,
          })
        )
        .filter(v => !!v) as EntityExtensionValueDelta[]

      const index = currentData.findIndex(d => d.uuid === row.uuid)
      const prevSiblingUuid =
        index <= 0 ? undefined : currentData[index - 1]?.uuid

      const updateTicketDeltaInput = generateUpdateTicketDeltaInput({
        originalRow: original,
        currentRow: row,
        originalPrevSiblingUuid,
        prevSiblingUuid,
        extensionInput,
      })
      return generateUpdateDeltaInput({
        originalRow: original as TRow,
        row: row as TRow,
        ticketRequest: updateTicketDeltaInput,
        extensionInput,
      })
    })
    .filter(d => !!d) as TInput[]
}

// For types with specific parameters
export interface FetchResponseByTypeBase {
  uuid: string
  lockVersion: number
  ticket: TicketDetail
  extensions?: EntityExtensionValue[]
}

export const createRowByTypeResponse = <
  TRow extends TicketRow,
  TResponse extends FetchResponseByTypeBase
>(
  response: any,
  dailyWorkHours: number,
  monthlyWorkDays: number,
  extensionProperties: FunctionProperty[] | undefined
): TRow => {
  const reponseByType: TResponse = response
  const specificParams = _.omit(response, [
    'uuid',
    'lockVersion',
    'ticket',
    'extension',
  ])

  return {
    ...specificParams,
    ...generalCreateRowByResponse(
      {
        ...reponseByType.ticket,
        extensions: reponseByType.extensions,
      },
      dailyWorkHours,
      monthlyWorkDays,
      extensionProperties
    ),
  }
}

export interface TicketDataManager {
  getUuid: (row: TicketRow) => string
  // Fetch -----
  getByTicketListUuid: (params: {
    ticketListUuid: string
  }) => Promise<APIResponse>
  getDetail: (params: { uuid: string }) => Promise<APIResponse>
  createRowByResponse: (
    response: any,
    dailyWorkHours: number,
    monthlyWorkDays: number,
    extensionProperties: FunctionProperty[] | undefined
  ) => TicketRow
  // Save -----
  updateBatchDelta: (request: any) => Promise<APIResponse>
  createUpdateDeltaBatchRequest: (
    projectUuid: string,
    originalData: TicketRow[],
    currentData: TicketRow[],
    deletedRows: TicketRow[]
  ) => any
  // Other -----
  createNewRow: (ticketList: TicketListBasic) => TicketRow
}

export const getDataManager = (ticketType: TicketType | undefined) => {
  switch (ticketType) {
    case TicketType.RISK:
      return getRiskTicketDataManager()
    case TicketType.REFINEMENT_NEW:
      return getRefinementTicketDataManager()
    default:
      return getGeneralTicketDataManager()
  }
}
