import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { AgGridRowResizeDragger } from '../../../dragger/rowResizeDragger'
import { getRowNumber } from '../../../../../../BulkSheetView/lib/gridApi'

type Props = ICellRendererParams & {
  switchRootWbsItem: (event: MouseEvent, rootProjectPlanUuid?: string) => void
  onChangeRowSize?: (rowHeight: number) => void
}

export const ProjectPlanSequenceNoCellRenderer = ({
  node,
  api,
  data,
  value,
  switchRootWbsItem,
  onChangeRowSize,
}: Props) => {
  const rowNumber = value
  const projectPlanUuid = data?.uuid
  const isLink = !data?.added

  return (
    <div className="sevend-ag-cell-sequence-no">
      {isLink && (
        <a
          className="sevend-ag-cell-sequence-no__link"
          onClick={event => {
            switchRootWbsItem(event as unknown as MouseEvent, projectPlanUuid)
          }}
        >
          {rowNumber}
        </a>
      )}
      {!isLink && (
        <p className="sevend-ag-cell-sequence-no__label">{rowNumber}</p>
      )}
      <AgGridRowResizeDragger
        node={node}
        api={api}
        onChangeRowSize={onChangeRowSize}
      />
    </div>
  )
}
