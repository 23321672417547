import React from 'react'
import { GridApi, RowNode } from 'ag-grid-community'
import { RowResizeDragger } from '../../../../../components/draggers/RowResizeDragger'

export const AgGridRowResizeDragger = (props: {
  node: RowNode
  api: GridApi
  onChangeRowSize?: (rowHeight: number) => void
}) => {
  const isServerSideModel = props.api.getModel().getType() === 'serverSide'
  const [dragged, setDragged] = React.useState<boolean>(false)
  const [selectedNodes, setSelectedNodes] = React.useState<RowNode[]>([])
  const stateRef = React.useRef<{ selectedNodes: RowNode[]; dragged: boolean }>(
    {
      selectedNodes: [],
      dragged: false,
    }
  )
  stateRef.current.selectedNodes = selectedNodes
  stateRef.current.dragged = dragged
  return (
    <RowResizeDragger
      onStart={() => {
        const selected = props.api.getSelectedNodes()
        if (
          selected.length === 1 &&
          1 < stateRef.current.selectedNodes.length
        ) {
          // Double clicked
          return
        }
        setSelectedNodes(props.api.getSelectedNodes())
      }}
      onResize={(value: number) => {
        setDragged(true)
        if (props.node.rowHeight) {
          const rowHeight = props.node.rowHeight + value
          if (rowHeight < 26) return
          if (props.onChangeRowSize) {
            props.api.forEachNode(rowNode => rowNode.setRowHeight(rowHeight))
          } else {
            props.node.setRowHeight(rowHeight)
          }
          props.api.onRowHeightChanged()
        }
      }}
      onComplete={() => {
        if (!stateRef.current.dragged) return
        try {
          if (props.onChangeRowSize) {
            const rowHeight = props.node.rowHeight ?? 26
            props.onChangeRowSize(rowHeight)
          } else {
            const selected = stateRef.current.selectedNodes
            if (selected.length === 0 || !props.node.rowHeight) return
            selected.forEach(node => {
              node.setRowHeight(props.node.rowHeight)
            })
          }
          props.api.onRowHeightChanged()
        } finally {
          setDragged(false)
          setSelectedNodes([])
        }
      }}
      onDoubleClick={() => {
        try {
          stateRef.current.selectedNodes.forEach(node =>
            node.setRowHeight(isServerSideModel ? 32 : undefined)
          )
          props.api.onRowHeightChanged()
        } finally {
          setDragged(false)
          setSelectedNodes([])
        }
      }}
    />
  )
}
