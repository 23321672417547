import { useEffect, useState } from 'react'
import { AuthError } from '../../../../error/AuthError'
import { intl } from '../../../../i18n'
import { useAuthRepository } from '../../../../services/adaptors/authRepositoryAdaptor'

export type AuthErrorMessage = {
  title: string
  detail?: string[]
}

export const useAuthErrorMessage = (authError: AuthError) => {
  const [message, setMessage] = useState<AuthErrorMessage | undefined>(
    undefined
  )
  const { getPasswordPolicy } = useAuthRepository()
  useEffect(() => {
    const toMessage = async () => {
      switch (authError.errorCode) {
        case 'NO_TENANT_ALIAS':
          return {
            title: intl.formatMessage({ id: 'login.error.noTenantUrl' }),
          }
        case 'TENANT_NOT_EXIST':
          return {
            title: intl.formatMessage({ id: 'login.error.wrongTenantUrl' }),
          }
        case 'NO_EMAIL_OR_PASSWORD':
          return {
            title: intl.formatMessage({
              id: 'auth.error.emptyEmailOrPassword',
            }),
          }
        case 'INCORRECT_EMAIL_OR_PASSWORD':
          return {
            title: intl.formatMessage({
              id: 'auth.error.incorrectEmailOrPassword',
            }),
          }
        case 'ATTEMPT_LIMIT_EXCEEDED':
          return {
            title: intl.formatMessage({
              id: 'auth.error.limitExceeded',
            }),
          }
        case 'NO_EMAIL_INPUT':
          return {
            title: intl.formatMessage({
              id: 'login.error.enterEmail',
            }),
          }
        case 'NO_RESET_CODE':
          return {
            title: intl.formatMessage({
              id: 'auth.error.noAuthCode',
            }),
          }

        case 'NO_PASSWORD_INPUT':
          return {
            title: intl.formatMessage({
              id: 'login.error.enterNewPassword',
            }),
          }
        case 'PASSWORD_CONFIRM_UNMATCH':
          return {
            title: intl.formatMessage({
              id: 'changePasswordNewUser.passwordNotMatch',
            }),
          }

        case 'INVALID_CHARACTOR_FOR_PASSWORD':
          return {
            title: intl.formatMessage({
              id: 'auth.error.containsInvalidChar',
            }),
          }

        case 'RESET_CODE_MISMATCH':
          return {
            title: intl.formatMessage({
              id: 'auth.error.invalidAuthCode',
            }),
          }

        case 'RESET_CODE_EXPIRED':
          return {
            title: intl.formatMessage({
              id: 'auth.error.codeExpired',
            }),
          }

        case 'NOT_SATISFY_PASSWORD_POLICY':
          const passwordPolicy = await getPasswordPolicy()
          const detail: string[] = []
          if (passwordPolicy.requireLowercase) {
            detail.push(
              intl.formatMessage(
                { id: 'auth.error.invalidPasswordPolicy' },
                {
                  requiredCharacters: intl.formatMessage({
                    id: 'password.policy.lowercase',
                  }),
                }
              )
            )
          }
          if (passwordPolicy.requireUppercase) {
            detail.push(
              intl.formatMessage(
                { id: 'auth.error.invalidPasswordPolicy' },
                {
                  requiredCharacters: intl.formatMessage({
                    id: 'password.policy.uppercase',
                  }),
                }
              )
            )
          }
          if (passwordPolicy.requireNumber) {
            detail.push(
              intl.formatMessage(
                { id: 'auth.error.invalidPasswordPolicy' },
                {
                  requiredCharacters: intl.formatMessage({
                    id: 'password.policy.number',
                  }),
                }
              )
            )
          }
          if (passwordPolicy.requireSymbol) {
            detail.push(
              intl.formatMessage(
                { id: 'auth.error.invalidPasswordPolicy' },
                {
                  requiredCharacters: intl.formatMessage({
                    id: 'password.policy.symbol',
                  }),
                }
              )
            )
          }
          if (passwordPolicy.minLength) {
            detail.push(
              intl.formatMessage(
                { id: 'auth.error.invalidPasswordLength' },
                { minLength: passwordPolicy.minLength }
              )
            )
          }
          return {
            title: intl.formatMessage({
              id: 'forgotPasswordSubmit.failedPassword',
            }),
            detail,
          }

        case 'NEED_RESCUE':
        default:
          return { title: 'auth.error.unhandled' }
      }
    }
    const fn = async () => {
      const generatedMessage = await toMessage()
      setMessage(generatedMessage)
    }
    fn()
  }, [authError])
  return message
}
