import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Popper,
  Radio,
  RadioGroup,
  Step,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Color, FontSize } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CSSProperties, useCallback, useState } from 'react'
import { QuickFilterKeys } from '../..'
import { colorPalette } from '../../../../style/colorPallete'

type Props = {
  onChangeQuickFilters: (
    key: QuickFilterKeys | undefined
  ) => void | Promise<void>
  selectedFilterGroups: QuickFilterKeys | undefined
}
const ProgressFilter = ({
  selectedFilterGroups,
  onChangeQuickFilters,
}: Props) => {
  const [openProgressFilter, setOpenProgressFilter] = useState<boolean>(false)
  const [filterAnchorEl, setFilterAnchorEl] = useState<Element>()
  const [progress, setProgress] = useState<QuickFilterKeys>(QuickFilterKeys.ALL)
  const [displayLabel, setDisplayLabel] = useState<string>(
    intl.formatMessage({ id: 'all' })
  )

  const handleFilterClose = () => {
    setFilterAnchorEl(undefined)
    setOpenProgressFilter(false)
  }

  const onChangeRadio = useCallback(
    (step: RadioStep) => {
      setProgress(step.value)
      if (step.value === QuickFilterKeys.ALL) {
        setDisplayLabel(intl.formatMessage({ id: 'all' }))
      } else {
        setDisplayLabel(step.label)
      }
      onChangeQuickFilters(step.value)
    },
    [selectedFilterGroups]
  )

  return (
    <Box
      sx={{
        margin: ' 0 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 'fit-content',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[5],
          fontSize: FontSize.MEDIUM,
          marginRight: '10px',
        }}
      >
        {intl.formatMessage({ id: 'wbs.header.progress' })}
      </Typography>
      <ClickAwayListener onClickAway={handleFilterClose}>
        <div>
          <Button
            sx={{
              border: `1px solid ${colorPalette.monotone[2]}`,
              background: colorPalette.monotone[0],
              '&:hover': { background: colorPalette.monotone[0] },
              color: colorPalette.monotone[4],
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={event => {
              if (openProgressFilter) {
                setFilterAnchorEl(undefined)
                setOpenProgressFilter(false)
              } else {
                setFilterAnchorEl(event.currentTarget)
                setOpenProgressFilter(true)
              }
            }}
          >
            <Typography
              sx={{ margin: '0 auto 0 10px', fontSize: FontSize.SMALL }}
            >
              {displayLabel}
            </Typography>
            {openProgressFilter ? (
              <ExpandLess
                sx={{ margin: '0 5px 0 auto', height: '13px', width: '13px' }}
              />
            ) : (
              <ExpandMore
                sx={{ margin: '0 5px 0 auto', height: '13px', width: '13px' }}
              />
            )}
          </Button>
          {openProgressFilter ? (
            <Popper
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              sx={{
                zIndex: '1300',
                background: colorPalette.monotone[0],
                height: 'fit-content',
                width: '240px',
                borderRadius: '4px',
                boxShadow: '0px 1px 2px #0000004D',
              }}
              placement={'bottom-start'}
              modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            >
              <Table>
                <TableRow>
                  <FormControl>
                    <RadioStepper
                      value={progress}
                      steps={[
                        {
                          label: intl.formatMessage({
                            id: 'bulksheet.toolbar.quickFilter.label.all',
                          }),
                          value: QuickFilterKeys.ALL,
                          style: { padding: '20px 20px 15px 20px' },
                        },
                        {
                          label: intl.formatMessage({
                            id: 'bulksheet.toolbar.quickFilter.label.startByToday',
                          }),
                          value: QuickFilterKeys.START_BY_TODAY,
                          style: { padding: '15px 20px 15px 20px' },
                        },
                        {
                          label: intl.formatMessage({
                            id: 'bulksheet.toolbar.quickFilter.label.endByToday',
                          }),
                          value: QuickFilterKeys.END_BY_TODAY,
                          style: { padding: '15px 20px 15px 20px' },
                        },
                        {
                          label: intl.formatMessage({
                            id: 'bulksheet.toolbar.quickFilter.label.startDelayed',
                          }),
                          value: QuickFilterKeys.START_DELAYED,
                          style: { padding: '15px 20px 15px 20px' },
                        },
                        {
                          label: intl.formatMessage({
                            id: 'bulksheet.toolbar.quickFilter.label.endDelayed',
                          }),
                          value: QuickFilterKeys.END_DELAYED,
                          style: { padding: '15px 20px 20px 20px' },
                        },
                      ]}
                      onChange={v => onChangeRadio(v)}
                    />
                  </FormControl>
                </TableRow>
              </Table>
            </Popper>
          ) : null}
        </div>
      </ClickAwayListener>
    </Box>
  )
}

interface RadioStep {
  label: string
  value: any
  style?: CSSProperties
}

interface RadioStepperProps {
  value: any
  steps: RadioStep[]
  onChange: (value: any) => void
  icon?: JSX.Element
}

const RadioStepper = (props: RadioStepperProps) => {
  const { value, steps, onChange } = props

  const getRadioIcon = useCallback(
    (step: RadioStep) => {
      const getOnClick = (step: RadioStep) => () => {
        onChange(step)
      }
      return (
        <Radio
          checked={step.value === value}
          style={{
            padding: 0,
            zIndex: 1,
            width: 20,
            height: 20,
            color: step.value === value ? Color.MAIN : Color.DISABLED,
          }}
          onClick={getOnClick(step)}
        />
      )
    },
    [props.value, props.onChange]
  )
  return (
    <RadioGroup value={value}>
      {steps.map((step, index) => {
        return (
          <Step key={`${step.value}-${index}`}>
            <TableCell
              sx={{
                display: 'flex',
                flexDirection: 'row',
                borderBottom: 'none',
                padding: step.style?.padding,
                alignItems: 'center',
                marginLeft: '13px',
              }}
            >
              <FormControlLabel
                label={
                  <span
                    style={{
                      color: colorPalette.monotone[4],
                      fontSize: '14px',
                      fontWeight: 400,
                      marginLeft: '10px',
                    }}
                  >
                    {intl.formatMessage({
                      id: step.label,
                    })}
                  </span>
                }
                value={step.value}
                control={getRadioIcon(step)}
              />
            </TableCell>
          </Step>
        )
      })}
    </RadioGroup>
  )
}

export default ProgressFilter
