import { WbsItemSearchConditionApiRequest } from './../../services/model/wbsItemSearchConditionApiRequest'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import API, { APIResponse } from '../commons/api'
import { TeamProps } from './team'
import { ProjectMemberProps } from './projectMember'
import { UserBasic } from './user'
import { SprintDetail } from './sprint'
import {
  DateTerm,
  formatDateTerm,
  formatDateTime,
  hasDiffDateTerm,
} from '../../utils/date'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../view/pages'
import { WbsItemStatus } from '../../view/containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import EntitySearch from '../commons/entitySearch'
import store from '../../store'
import { GetTasksProps } from './task'
import { GetDeliverablesProps } from './deliverable'
import { GetProcessesProps } from './process'
import Workload from '../functions/workload'
import objectUtil from '../../utils/objects'
import _ from 'lodash'
import { Tree } from '../commons/tree'
import { ProjectPlanCumulation } from './projectPlan'
import {
  SearchFilter,
  toPlainSearchFilterForApi,
} from '../../view/pages/WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { Attachment, AttachmentSummary } from '../../utils/attachment'
import { OpenDetailSpec } from '../../view/containers/BulkSheet'
import ViewMeta from '../../view/containers/meta/ViewMeta'
import { intl } from '../../i18n'
import { CommentSummary } from '../../store/comments'
import { EntityExtensionValue } from '../../view/containers/meta/entityExtension'
import DateVO from '../../vo/DateVO'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../domain/value-object/WbsItemTypeVO'
import SystemError from '../../error/SystemError'
import { TaskData } from '../../view/pages/TaskActualWork/TaskActualWorkOptions'
import { TagForWbsItem } from './tag'
import { TicketType } from './ticket'
import {
  WbsItemAdditionalPropertyValueDeltaInput,
  WbsItemAdditionalPropertyValueObject,
  WbsItemAdditionalPropertyValuesVO,
  wbsItemAdditionalPropertyValuesVoService,
} from '../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { CUSTOM_ENUM_NONE } from '../commons/customEnum'

export const wbsItemTypeLabel = {
  PROCESS: intl.formatMessage({ id: 'wbsItemType.process' }),
  DELIVERABLE_LIST: intl.formatMessage({
    id: 'wbsItemType.deliverableList',
  }),
  DELIVERABLE: intl.formatMessage({
    id: 'wbsItemType.deliverable',
  }),
  TASK: intl.formatMessage({
    id: 'wbsItemType.task',
  }),
}

export const getWbsItemStatusLabel = (status: WbsItemStatus) => {
  switch (status) {
    case WbsItemStatus.TODO:
      return 'ToDo'
    case WbsItemStatus.DOING:
      return 'Doing'
    case WbsItemStatus.REVIEW:
      return 'Review'
    case WbsItemStatus.DONE:
      return 'Done'
    case WbsItemStatus.DISCARD:
      return 'Discard'
  }
}

export const getWbsItemFunctionUuid = () => {
  const wbsItemFunction = store
    .getState()
    .appFunction.functions.find(
      v => v.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
    )
  return wbsItemFunction?.uuid
}

export const getExternalIdByWbsItem = (
  wbsItem: WbsItemRow | WbsItemDetail
): APPLICATION_FUNCTION_EXTERNAL_ID => {
  if (wbsItem.type === WbsItemType.TASK) {
    switch (wbsItem.ticketType) {
      case TicketType.RISK:
        return APPLICATION_FUNCTION_EXTERNAL_ID.RISK
      case TicketType.REFINEMENT_NEW:
        return APPLICATION_FUNCTION_EXTERNAL_ID.REFINEMENT
      default:
        break
    }
  }
  return APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
}

export async function getOpenWbsItemDetailSpec(
  openInDialog: boolean,
  wbsItem: WbsItemRow | WbsItemDetail
): Promise<OpenDetailSpec> {
  const externalId = getExternalIdByWbsItem(wbsItem)
  return {
    openInDialog: openInDialog,
    layer: {
      externalId,
      code: wbsItem.code!,
    },
  }
}

export function getWbsItemStatusColorCode(status: WbsItemStatus): string {
  switch (status) {
    case WbsItemStatus.DISCARD:
      return '#D6D6D6'
    case WbsItemStatus.TODO:
      return 'transparent'
    case WbsItemStatus.DOING:
      return '#FEFDD3'
    case WbsItemStatus.REVIEW:
      return '#E4FFD9'
    case WbsItemStatus.DONE:
      return '#D4F2FF'
  }
}

export function getWbsItemStatusDeepColorCode(status: WbsItemStatus): string {
  switch (status) {
    case WbsItemStatus.DISCARD:
      return '#D6D6D6'
    case WbsItemStatus.TODO:
      return '#F7F7F7'
    case WbsItemStatus.DOING:
      return '#FDF85E'
    case WbsItemStatus.REVIEW:
      return '#7CDF53'
    case WbsItemStatus.DONE:
      return '#59C5DE'
  }
}

export function getWbsItemTreeStatusColorCode(status: WbsItemStatus): string {
  switch (status) {
    case WbsItemStatus.DISCARD:
      return 'linear-gradient(to right, #D6D6D6 5px, #D6D6D6 5px)'
    case WbsItemStatus.TODO:
      return 'linear-gradient(to right, #F7F7F7 5px, transparent 5px)'
    case WbsItemStatus.DOING:
      return 'linear-gradient(to right, #FDF85E 5px, #FEFDD3 5px)'
    case WbsItemStatus.REVIEW:
      return 'linear-gradient(to right, #7CDF53 5px, #E4FFD9 5px)'
    case WbsItemStatus.DONE:
      return 'linear-gradient(to right, #59C5DE 5px, #D4F2FF 5px)'
  }
}

export class WbsItemRow {
  code?: string
  type?: WbsItemType
  wbsItemType?: WbsItemTypeVO
  baseWbsItemType?: WbsItemTypeVO
  displayName?: string
  description?: string
  startIf?: string
  completeIf?: string
  rule?: string
  ruleAttachments?: Attachment[]
  team?: TeamProps
  accountable?: ProjectMemberProps
  responsible?: ProjectMemberProps
  assignee?: ProjectMemberProps
  closedBy?: ProjectMemberProps
  revision?: string
  createdAt?: string
  createdBy?: UserBasic
  updatedAt?: string
  updatedBy?: UserBasic
  constraint?: string
  critical?: boolean
  deadline?: string
  difficulty?: string
  estimatedStoryPoint?: number
  estimatedWorkload?: Workload
  estimatedAmount?: number
  actualAmount?: number
  sprints?: SprintDetail[]
  sprint?: SprintDetail
  currentSprint?: SprintDetail
  priority?: string
  actualHour?: number
  scheduledDate?: DateTerm
  actualDate?: DateTerm
  deliverableAttachments?: Attachment[]
  deliverableTemplateAttachments?: Attachment[]
  status?: WbsItemStatus
  substatus?: string
  watchers?: ProjectMemberProps[]

  ticketType?: string

  uuid?: string
  lockVersion?: number
  projectUuid?: string

  tags?: TagForWbsItem[]
  additionalPropertyValues?: WbsItemAdditionalPropertyValuesVO
}

export interface WbsItemUpdateBatchDeltaRequest {
  projectUuid: string
  added: WbsItemInput[]
  edited: WbsItemDeltaInput[]
  deleted: {
    uuid: string
    lockVersion: number
  }[]
}

export interface WbsItemBatchDeltaRequest {
  wbsItems: WbsItemUpdateBatchDeltaRequest
  watchers: {
    wbsItemUuid: string
    userUuids: string[]
  }[]
  tags?: {
    wbsItemUuid: string
    tagUuids?: string[]
  }[]
}

export interface WbsItemBasic {
  uuid: string
  code: string
  projectUuid: string
  projectCode: string
  type: WbsItemType
  typeDto: WbsItemTypeObject
  displayName: string

  ticketType: string
  ticketUuid: string
  ticketListUuid: string
}

export interface WbsItemDetail {
  projectUuid: string
  uuid: string
  lockVersion: number
  revision: string
  code: string
  type: WbsItemType
  typeDto: WbsItemTypeObject
  status: string
  substatus: string
  path: string
  displayName: string
  description: string
  startIf: string
  completeIf: string
  rule: string
  team: TeamProps
  accountable: ProjectMemberProps
  responsible: ProjectMemberProps
  assignee?: ProjectMemberProps
  closedBy?: ProjectMemberProps
  critical: boolean
  deadline: string
  difficulty: string
  estimatedStoryPoint: number
  estimatedHour: number
  actualHour: number
  estimatedAmount: number
  actualAmount: number
  priority: string
  scheduledDate: DateTerm
  actualDate: DateTerm
  sprints?: SprintDetail[]
  sprint?: SprintDetail
  productBacklogItem?: boolean
  createdAt: number
  createdBy: UserBasic
  updatedAt: number
  updatedBy: UserBasic

  watchers: ProjectMemberProps[]

  ticketType?: string
  ticketListUuid?: string
  baseTypeDto?: WbsItemTypeObject
  extensions?: EntityExtensionValue[]

  tags?: TagForWbsItem[]
  additionalPropertyValues?: WbsItemAdditionalPropertyValueObject[]
}

export interface WbsItemSearchDetail extends Tree<WbsItemSearchDetail> {
  projectUuid: string
  uuid: string
  lockVersion: number
  revision: string
  code: string
  type: WbsItemType
  typeDto: WbsItemTypeVO
  baseTypeDto: WbsItemTypeVO
  status: string
  substatus: string
  path: string
  displayName: string
  description: string
  startIf: string
  completeIf: string
  rule: string
  team: TeamProps
  accountable: ProjectMemberProps
  responsible: ProjectMemberProps
  assignee?: ProjectMemberProps
  closedBy?: ProjectMemberProps
  critical: boolean
  deadline: string
  difficulty: string
  estimatedStoryPoint: number
  estimatedHour: number
  actualHour: number
  estimatedAmount: number
  actualAmount: number
  priority: string
  scheduledDate: DateTerm
  actualDate: DateTerm
  sprints?: SprintDetail[]
  sprint?: SprintDetail
  productBacklogItem?: boolean
  cumulation: ProjectPlanCumulation
  commentSummary: CommentSummary
  createdAt: number
  createdBy: UserBasic
  updatedAt: number
  updatedBy: UserBasic
  watchers: ProjectMemberProps[]
  ticketType: string
  parentWbsItem: WbsItemBasic | null
  deliverableAttachmentSummary?: AttachmentSummary
  extensions?: EntityExtensionValue[]

  tags?: TagForWbsItem[]
}

export interface WbsItemInput {
  uuid?: string // 更新時のみ
  lockVersion?: number // 更新時のみ
  code?: string
  type: WbsItemType
  typeUuid?: string
  status?: string
  substatus?: string
  displayName: string
  description?: string
  startIf?: string
  completeIf?: string
  rule?: string
  teamUuid?: string
  accountableUuid?: string
  responsibleUuid?: string
  assigneeUuid?: string
  watchers?: string[]
  closedByUuid?: string
  critical?: boolean
  deadline?: string
  difficulty?: string
  estimatedStoryPoint?: number
  estimatedHour?: number
  actualHour?: number
  estimatedAmount?: number
  actualAmount?: number
  priority?: string
  scheduledDate?: DateTerm
  actualDate?: DateTerm
  deliverableAttachments?: Attachment[]
  sprintUuid?: string
  additionalPropertyValues?: WbsItemAdditionalPropertyValueObject[]
}

export interface WbsItemDeltaInput {
  uuid: string
  type: WbsItemType
  typeUuid?: IItemDelta<string>
  status?: IItemDelta<string>
  substatus?: IItemDelta<string>
  displayName: IItemDelta<string>
  description?: IItemDelta<string>
  startIf?: IItemDelta<string>
  completeIf?: IItemDelta<string>
  rule?: IItemDelta<string>
  teamUuid?: IItemDelta<string>
  accountableUuid?: IItemDelta<string>
  responsibleUuid?: IItemDelta<string>
  assigneeUuid?: IItemDelta<string>
  watchers?: string[]
  closedByUuid?: IItemDelta<string>
  critical?: IItemDelta<boolean>
  deadline?: IItemDelta<string>
  difficulty?: IItemDelta<string>
  estimatedStoryPoint?: IItemDelta<number>
  estimatedHour?: IItemDelta<number>
  estimatedAmount?: IItemDelta<number>
  actualAmount?: IItemDelta<number>
  priority?: IItemDelta<string>
  scheduledDate?: IItemDelta<DateTerm>
  actualDate?: IItemDelta<DateTerm>
  extensions: {
    uuid: string
    oldValue: any
    newValue: any
  }[]
  sprintUuid?: IItemDelta<string>
  additionalPropertyValues?: WbsItemAdditionalPropertyValueDeltaInput[]
}

export interface WbsItemUpdateDeltaRequest {
  input: WbsItemDeltaInput
  watchers?: string[]
}

export interface WbsItemWorkDeltaInput {
  uuid: string
  type: string
  actualDate?: string
  status?: IItemDelta<string>
  assigneeUuid?: IItemDelta<string>
  priority?: IItemDelta<string>
  hour?: IItemDelta<number>
}

export interface BatchUpdateWbsItemResponse {
  added: UpdateWbsItemResponse[]
  edited: UpdateWbsItemResponse[]
  deleted: UpdateWbsItemResponse[]
}

export interface UpdateWbsItemResponse {
  uuid: string
  lockVersion: number
}

export interface WbsItemFindProps {
  projectUuid: string
  all?: string
  team?: {
    uuid?: string
    code?: string
    officialName?: string
    shortName?: string
  }
  searchFilter?: SearchFilter
  rootUuid?: string
}

export enum WbsItemAttachmentType {
  DELIVERABLE = 'DELIVERABLE',
  DELIVERABLE_TEMPLATE = 'DELIVERABLE_TEMPLATE',
  RULE = 'RULE',
}

export const getAttachmentType = (externalId: string) => {
  if (externalId.endsWith('deliverableAttachments')) {
    return WbsItemAttachmentType.DELIVERABLE
  } else if (externalId.endsWith('deliverableTemplateAttachments')) {
    return WbsItemAttachmentType.DELIVERABLE_TEMPLATE
  } else if (externalId.endsWith('ruleAttachments')) {
    return WbsItemAttachmentType.RULE
  } else {
    throw new Error(`Unsupported attachment item.(externalId: ${externalId})`)
  }
}

export const getLockVersionFromResponse = (
  response: UpdateWbsItemResponse[],
  targetUuid: string
) => {
  const updated = response.find(output => output.uuid === targetUuid)
  return updated ? updated.lockVersion : undefined
}

export const createRowByResponse = (
  detail: WbsItemDetail,
  cumulation?: ProjectPlanCumulation
): WbsItemRow => {
  const { dailyWorkHours, monthlyWorkDays } =
    store.getState().tenant.organization!
  return {
    code: detail.code,
    type: detail.type,
    wbsItemType: detail.typeDto
      ? new WbsItemTypeVO(detail.typeDto)
      : store.getState().project.wbsItemTypes.get(detail.type),
    baseWbsItemType: detail.baseTypeDto
      ? new WbsItemTypeVO(detail.baseTypeDto)
      : undefined,
    displayName: detail.displayName,
    description: detail.description,
    startIf: detail.startIf,
    completeIf: detail.completeIf,
    rule: detail.rule,
    team: detail.team,
    accountable: detail.accountable,
    responsible: detail.responsible,
    assignee: detail.assignee,
    closedBy: detail.closedBy,
    revision: detail.revision,
    createdAt: formatDateTime(detail.createdAt),
    createdBy: detail.createdBy,
    updatedAt: formatDateTime(detail.updatedAt),
    updatedBy: detail.updatedBy,
    critical: detail.critical,
    deadline: detail.deadline ? new DateVO(detail.deadline).format() : '',
    difficulty:
      detail.difficulty === CUSTOM_ENUM_NONE ? undefined : detail.difficulty,
    estimatedStoryPoint: detail.estimatedStoryPoint,
    estimatedWorkload: Workload.from({
      hour: detail.estimatedHour,
      standard: {
        dailyWorkHours,
        monthlyWorkDays,
      },
    }),
    actualHour: cumulation ? cumulation.actualHour : 0,
    estimatedAmount: detail.estimatedAmount,
    actualAmount: detail.actualAmount,
    sprint: detail.sprint,
    sprints: detail.sprints || [],
    priority:
      detail.priority === CUSTOM_ENUM_NONE ? undefined : detail.priority,
    scheduledDate: formatDateTerm(detail.scheduledDate),
    actualDate: formatDateTerm(detail.actualDate),
    status: detail.status ? (detail.status as WbsItemStatus) : undefined,
    substatus: detail.substatus,
    watchers: detail.watchers,
    ticketType: detail.ticketType,
    projectUuid: detail.projectUuid,
    uuid: detail.uuid,
    lockVersion: detail.lockVersion,
    tags: detail.tags,
    additionalPropertyValues: detail.additionalPropertyValues
      ? wbsItemAdditionalPropertyValuesVoService.of(
          detail.uuid,
          detail.additionalPropertyValues
        )
      : undefined,
  }
}

export const createRequestByRow = (
  row: WbsItemRow,
  viewMeta: ViewMeta,
  wbsItemPath: string,
  extensions?: EntityExtensionValue[]
): WbsItemInput => {
  return {
    ...row,
    projectUuid: store.getState().project.selected,
    uuid: row.uuid,
    lockVersion: row.lockVersion,
    type: row.type,
    typeUuid: row.wbsItemType?.isWorkgroup() ? row.wbsItemType.uuid : undefined,
    teamUuid: row.team && row.team.uuid,
    accountableUuid: row.accountable && row.accountable.uuid,
    responsibleUuid: row.responsible && row.responsible.uuid,
    assigneeUuid: row.assignee && row.assignee.uuid,
    watchers: row.watchers && row.watchers.map(w => w.uuid),
    closedByUuid: row.closedBy && row.closedBy.uuid,
    estimatedHour: row.estimatedWorkload?.getFixedHour(),
    estimatedAmount: row.estimatedAmount,
    actualAmount: row.actualAmount,
    deadline: row.deadline
      ? viewMeta.serializeInputForApi(
          row.deadline,
          viewMeta.functionMeta.properties.byId.get(wbsItemPath + '.deadline')!
        )
      : null,
    scheduledDate: {
      startDate: viewMeta.serializeInputForApi(
        row.scheduledDate && row.scheduledDate.startDate,
        viewMeta.functionMeta.properties.byId.get(
          wbsItemPath + '.scheduledDate.startDate'
        )!
      ),
      endDate: viewMeta.serializeInputForApi(
        row.scheduledDate && row.scheduledDate.endDate,
        viewMeta.functionMeta.properties.byId.get(
          wbsItemPath + '.scheduledDate.endDate'
        )!
      ),
    },
    actualDate: {
      startDate: viewMeta.serializeInputForApi(
        row.actualDate && row.actualDate.startDate,
        viewMeta.functionMeta.properties.byId.get(
          wbsItemPath + '.actualDate.startDate'
        )!
      ),
      endDate: viewMeta.serializeInputForApi(
        row.actualDate && row.actualDate.endDate,
        viewMeta.functionMeta.properties.byId.get(
          wbsItemPath + '.actualDate.endDate'
        )!
      ),
    },
    ruleAttachments: viewMeta.serializeFileForApi(row.ruleAttachments),
    deliverableAttachments: viewMeta.serializeFileForApi(
      row.deliverableAttachments
    ),
    deliverableTemplateAttachments: viewMeta.serializeFileForApi(
      row.deliverableTemplateAttachments
    ),
    status: row.status || WbsItemStatus.TODO,
    extensions: extensions
      ? viewMeta.serializeEntityExtensionsForApi(extensions)
      : undefined,
    sprintUuid: row.sprint && row.sprint.uuid,
  } as WbsItemInput
}

export const createDeltaRequestByRow = (
  editedRow: {
    before: WbsItemRow | TaskData
    after: WbsItemRow | TaskData
  },
  viewMeta: ViewMeta,
  wbsItemPath: string,
  editedExtensions: {
    before?: EntityExtensionValue[]
    after?: EntityExtensionValue[]
  }
): WbsItemDeltaInput => {
  const { before: editedRowBefore, after: editedRowAfter } = editedRow
  const beforeInput = createRequestByRow(
    editedRowBefore,
    viewMeta,
    wbsItemPath,
    editedExtensions.before
  )
  const afterInput = createRequestByRow(
    editedRowAfter,
    viewMeta,
    wbsItemPath,
    editedExtensions.after
  )
  return {
    uuid: afterInput.uuid,
    type: afterInput.type,
    status:
      beforeInput.status !== afterInput.status
        ? {
            oldValue: beforeInput.status,
            newValue: afterInput.status,
          }
        : undefined,
    substatus:
      beforeInput.substatus !== afterInput.substatus
        ? {
            oldValue: beforeInput.substatus,
            newValue: afterInput.substatus,
          }
        : undefined,
    displayName:
      beforeInput.displayName !== afterInput.displayName
        ? {
            oldValue: beforeInput.displayName,
            newValue: afterInput.displayName,
          }
        : undefined,
    description:
      beforeInput.description !== afterInput.description
        ? {
            oldValue: beforeInput.description,
            newValue: afterInput.description,
          }
        : undefined,
    startIf:
      beforeInput.startIf !== afterInput.startIf
        ? {
            oldValue: beforeInput.startIf,
            newValue: afterInput.startIf,
          }
        : undefined,
    completeIf:
      beforeInput.completeIf !== afterInput.completeIf
        ? {
            oldValue: beforeInput.completeIf,
            newValue: afterInput.completeIf,
          }
        : undefined,
    rule:
      beforeInput.rule !== afterInput.rule
        ? {
            oldValue: beforeInput.rule,
            newValue: afterInput.rule,
          }
        : undefined,
    teamUuid:
      beforeInput.teamUuid !== afterInput.teamUuid
        ? {
            oldValue: beforeInput.teamUuid,
            newValue: afterInput.teamUuid,
          }
        : undefined,
    accountableUuid:
      beforeInput.accountableUuid !== afterInput.accountableUuid
        ? {
            oldValue: beforeInput.accountableUuid,
            newValue: afterInput.accountableUuid,
          }
        : undefined,
    responsibleUuid:
      beforeInput.responsibleUuid !== afterInput.responsibleUuid
        ? {
            oldValue: beforeInput.responsibleUuid,
            newValue: afterInput.responsibleUuid,
          }
        : undefined,
    assigneeUuid:
      beforeInput.assigneeUuid !== afterInput.assigneeUuid
        ? {
            oldValue: beforeInput.assigneeUuid,
            newValue: afterInput.assigneeUuid,
          }
        : undefined,
    watchers: afterInput.watchers,
    closedByUuid:
      beforeInput.closedByUuid !== afterInput.closedByUuid
        ? {
            oldValue: beforeInput.closedByUuid,
            newValue: afterInput.closedByUuid,
          }
        : undefined,
    critical:
      beforeInput.critical !== afterInput.critical
        ? {
            oldValue: beforeInput.critical,
            newValue: afterInput.critical,
          }
        : undefined,
    deadline:
      beforeInput.deadline !== afterInput.deadline
        ? {
            oldValue: beforeInput.deadline,
            newValue: afterInput.deadline,
          }
        : undefined,
    difficulty:
      beforeInput.difficulty !== afterInput.difficulty
        ? {
            oldValue: beforeInput.difficulty,
            newValue: afterInput.difficulty,
          }
        : undefined,
    estimatedStoryPoint:
      beforeInput.estimatedStoryPoint !== afterInput.estimatedStoryPoint
        ? {
            oldValue: beforeInput.estimatedStoryPoint,
            newValue: afterInput.estimatedStoryPoint,
          }
        : undefined,
    estimatedHour:
      beforeInput.estimatedHour !== afterInput.estimatedHour
        ? {
            oldValue: beforeInput.estimatedHour,
            newValue: afterInput.estimatedHour,
          }
        : undefined,
    estimatedAmount:
      beforeInput.estimatedAmount !== afterInput.estimatedAmount
        ? {
            oldValue: beforeInput.estimatedAmount,
            newValue: afterInput.estimatedAmount,
          }
        : undefined,
    actualAmount:
      beforeInput.actualAmount !== afterInput.actualAmount
        ? {
            oldValue: beforeInput.actualAmount,
            newValue: afterInput.actualAmount,
          }
        : undefined,
    priority:
      beforeInput.priority !== afterInput.priority
        ? {
            oldValue: beforeInput.priority,
            newValue: afterInput.priority,
          }
        : undefined,
    scheduledDate: hasDiffDateTerm(
      beforeInput.scheduledDate,
      afterInput.scheduledDate
    )
      ? {
          oldValue: beforeInput.scheduledDate,
          newValue: afterInput.scheduledDate,
        }
      : undefined,
    actualDate: hasDiffDateTerm(beforeInput.actualDate, afterInput.actualDate)
      ? {
          oldValue: beforeInput.actualDate,
          newValue: afterInput.actualDate,
        }
      : undefined,
    extensions: viewMeta.serializeEntityExtensionsDeltaForApi({
      oldExtensions: editedExtensions.before,
      newExtensions: editedExtensions.after,
    }),
    sprintUuid:
      beforeInput.sprintUuid !== afterInput.sprintUuid
        ? {
            oldValue: beforeInput.sprintUuid,
            newValue: afterInput.sprintUuid,
          }
        : undefined,
  } as WbsItemDeltaInput
}

export const taskActualResultExists = (wbsItem: WbsItemRow): boolean => {
  return !!wbsItem?.wbsItemType?.isTask() && Number(wbsItem.actualHour) > 0
}

export type GetWbsItemProps = GetTasksProps &
  GetDeliverablesProps &
  GetProcessesProps

// detail
export interface WbsItemGetDetailProps {
  uuid: string
}

export const succeedAccountableToAddRow = (
  parentType?: WbsItemTypeVO
): boolean => {
  return !!parentType?.isDeliverableList()
}

export const succeedResponsibleToAddRow = (type?: WbsItemTypeVO): boolean => {
  return !!type?.isTask()
}

export const succeedScheduledDateToAddRow = (
  type?: WbsItemTypeVO,
  parentType?: WbsItemTypeVO
): boolean => {
  return !!type?.isTask() || !!parentType?.isDeliverableList()
}

export class WbsItem extends EntitySearch implements SingleSheetRepository {
  updateBatchDelta(request: WbsItemBatchDeltaRequest): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/wbs_items/delta/batch',
      request
    )
  }

  create(props: WbsItemInput): Promise<APIResponse> {
    throw new Error('Not implemented.')
  }

  update(props: unknown): Promise<APIResponse> {
    throw new Error('Can not use wbsItem.update method.')
  }

  updateDelta(props: WbsItemUpdateDeltaRequest): Promise<APIResponse> {
    switch (props.input.type) {
      case WbsItemType.PROCESS:
        return API.functional.request(
          'PUT',
          '/api/v1/projects/wbs_items/delta/process',
          props
        )
      case WbsItemType.DELIVERABLE_LIST:
        return API.functional.request(
          'PUT',
          '/api/v1/projects/wbs_items/delta/deliverable_list',
          props
        )
      case WbsItemType.DELIVERABLE:
        return API.functional.request(
          'PUT',
          '/api/v1/projects/wbs_items/delta/deliverable',
          props
        )
      case WbsItemType.TASK:
        return API.functional.request(
          'PUT',
          '/api/v1/projects/wbs_items/delta/task',
          props
        )
      default:
        throw new SystemError({
          errorCode: 'NOT_IMPLEMENTED',
          message: 'Not implemented.',
        })
    }
  }

  updateWorkDelta(props: WbsItemWorkDeltaInput): Promise<APIResponse> {
    return API.functional.request(
      'PUT',
      '/api/v1/projects/wbs_items/delta/works',
      props
    )
  }

  getDetail(props: WbsItemGetDetailProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/detail',
      {
        wbsItemUuid: props.uuid,
      },
      true
    )
  }

  getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/projects/wbs_items/basic', {
      code,
    })
  }

  public search = async (rawQuery: string, searchOptions?: any) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        projectUuid: store.getState().project.selected,
        all: query,
        limit: query.length === 0 ? 10 : undefined,
        ...searchOptions,
      }),
      this.toResponse
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      () => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse
    )
  }

  toResponse = (wbsItem: any) => {
    return {
      ...wbsItem,
      name: `[${wbsItemTypeLabel[wbsItem.type]}] [${wbsItem.code}] ${
        wbsItem.displayName
      }`,
    }
  }

  entitySearchApi = async (
    props: GetWbsItemProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/find',
      props,
      true,
      signal
    )
  }

  findWbsItem(props: WbsItemFindProps): Promise<APIResponse> {
    let param = {
      projectUuid: props.projectUuid,
      all: props.all,
      team: props.team,
      ...toPlainSearchFilterForApi(props.searchFilter),
    }
    if (props.searchFilter) {
      if (props.searchFilter.task) {
        objectUtil.setValue(param, 'displayName', props.searchFilter.task)
      }
      if (props.searchFilter.parent && props.searchFilter.parent.uuid) {
        objectUtil.setValue(param, 'rootUuid', props.searchFilter.parent.uuid)
      }
    }

    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/find',
      this.removeNameField(param)
    )
  }

  searchWbsItem = (
    request: WbsItemSearchConditionApiRequest
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/find',
      request
    )
  }

  findByUuid(projectUuid: string, uuid: string): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/find/uuid',
      { projectUuid, uuid }
    )
  }

  private removeNameField(param) {
    let result = _.cloneDeep(param)
    if (result.deliverable) {
      delete result.deliverable.name
    }
    if (result.accountable) {
      delete result.accountable.name
    }
    if (result.responsible) {
      delete result.responsible.name
    }
    if (result.assignee) {
      delete result.assignee.name
      delete result.sprint.name
    }
    if (result.team) {
      delete result.team.name
    }
    if (result.task) {
      delete result.task.name
      delete result.task.displayName
      delete result.task.uuid
    }
    if (result.parent) {
      delete result.parent.name
      delete result.parent.uuid
    }
    if (result.createdBy) {
      delete result.createdBy.name
    }
    if (result.updatedBy) {
      delete result.updatedBy.name
    }
    return result
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.getDetail({ uuid })
    return { status: 200, json: this.toResponse(response.json) }
  }

  createAttachments = async (params: {
    wbsItemUuid: string
    attachmentType: WbsItemAttachmentType
    attachments: Attachment[]
  }): Promise<APIResponse> => {
    return API.functional.request(
      'POST',
      '/api/v1/projects/wbs_items/attachments/items',
      params
    )
  }

  deleteAttachments = async (params: {
    wbsItemUuid: string
    attachmentType: WbsItemAttachmentType
    attachmentItemUuids: string[]
  }): Promise<APIResponse> => {
    return API.functional.request(
      'DELETE',
      '/api/v1/projects/wbs_items/attachments/items',
      params
    )
  }

  searchCommentUserSummaries = (request: {
    displayName?: string
    offset: number
    limit: number
  }): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/comment_user_summaries/find',
      request
    )
  }

  getCommentUserSummary = (request: {
    wbsItemUuid: string
  }): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/comment_user_summaries',
      request
    )
  }

  confirmCommentUserSummary = (request: {
    wbsItemUuid: string
  }): Promise<APIResponse> => {
    return API.functional.request(
      'PUT',
      '/api/v1/projects/wbs_items/comment_user_summaries/confirmed',
      request
    )
  }

  updateMyWbsItemsDelta(
    request: WbsItemBatchDeltaRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/wbs_items/delta/myWbsItems',
      request
    )
  }
}

export default new WbsItem()
