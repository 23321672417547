import _ from 'lodash'
import {
  CellKeyDownEvent,
  GridOptions,
  ProcessCellForExportParams,
  RowNode,
  SuppressKeyboardEventParams,
  ValueFormatterFunc,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import DomainError from '../../../../error/DomainError'
import { intl } from '../../../../i18n'
import { CustomEnumValue } from '../../../../lib/commons/appFunction'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import { SprintDetail } from '../../../../lib/functions/sprint'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { format as commentFormat } from '../../../../utils/comment'
import { formatDateTime } from '../../../../utils/date'
import { normalize } from '../../../../utils/multilineText'
import objects from '../../../../utils/objects'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { mergeExtensionColumns } from '../../../containers/BulkSheetView/gridOptions/extension'
import { overwriteColumnByPropertyConfiguration } from '../../../containers/BulkSheetView/gridOptions/propertyConfiguration'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  frameworkComponents,
  processDataFromClipboard,
} from '../../../containers/commons/AgGrid'
import { getTicketType } from '../../../containers/commons/AgGrid/components/cell/custom/ticketType'
import {
  formatPercentage,
  getStatusSummary,
} from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/cellRenderer'
import formatter from '../../../containers/meta/formatter'
import { ExtensionInfo } from '../hooks/extension'
import { MULTI_PROPERTY_DELIMITER, TicketRow } from '../tickets'
import { useColumnDefs, Props as PropsForGetColumnDefs } from './columnDefs'
import { useMemo } from 'react'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { useWbsItemAdditionalPropertyColumns } from '../../../containers/BulkSheetView/hooks/wbsItemAdditionalProperties'
import { WbsItemAdditionalPropertyEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import {
  wbsItemAdditionalPropertyValuesVoService,
  WbsItemAdditionalPropertyValueType,
} from '../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'

type Props = PropsForGetColumnDefs & {
  extensions?: ExtensionInfo
  destroyGrid: () => void
  wbsItemAdditionalProperties?: WbsItemAdditionalPropertyLayoutEntity
}

export const useTicketGridOptions = (props: Props): GridOptions | undefined => {
  const {
    projectUuid,
    extensions,
    context,
    destroyGrid,
    wbsItemAdditionalProperties,
  } = props

  const wbsItemAdditionalPropertyColumnDefs =
    useWbsItemAdditionalPropertyColumns(
      projectUuid,
      wbsItemAdditionalProperties,
      {
        getAdditionalPropertyValues: (rowData: TicketRow) =>
          rowData.wbsItem?.additionalPropertyValues,
        setAdditionalPropertyValues: (
          rowNode: RowNode<TicketRow>,
          wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
          oldValue: WbsItemAdditionalPropertyValueType,
          newValue: WbsItemAdditionalPropertyValueType
        ) => {
          const rowData = rowNode.data
          if (!rowData || !rowData.wbsItem) {
            return
          }
          let newAdditionalPropertyValues =
            rowData.wbsItem.additionalPropertyValues
          if (!newAdditionalPropertyValues) {
            newAdditionalPropertyValues =
              wbsItemAdditionalPropertyValuesVoService.construct()
          }
          wbsItemAdditionalPropertyValuesVoService.setValue(
            newAdditionalPropertyValues,
            wbsItemAdditionalProperty.uuid,
            newValue
          )

          rowData.wbsItem.additionalPropertyValues = newAdditionalPropertyValues
          rowData.edited = true
          if (!rowData.editedData) {
            rowData.editedData = {}
          }
          let oldAdditionalPropertyValues =
            rowData.editedData.additionalPropertyValues
          if (!oldAdditionalPropertyValues) {
            oldAdditionalPropertyValues =
              wbsItemAdditionalPropertyValuesVoService.construct()
          }
          wbsItemAdditionalPropertyValuesVoService.setValue(
            oldAdditionalPropertyValues,
            wbsItemAdditionalProperty.uuid,
            oldValue
          )
          rowNode.setData(rowData)
        },
        getWbsItemType: (rowData: TicketRow) => rowData.wbsItem?.wbsItemType,
        columnFilterType: 'CLIENT_SIDE',
      }
    )
  const columnDefs = useColumnDefs(props)
  const gridOptions = useMemo(() => {
    if (!extensions || !context) return
    // Re-create the Grid to resolve the issue of GridOptions not switching.
    destroyGrid()

    if (extensions && !_.isEmpty(extensions.extensionProperties)) {
      mergeExtensionColumns({
        projectUuid,
        defaultColumns: columnDefs,
        extensionProperties: extensions.extensionProperties,
        propertyConfigurations: extensions.propertyConfigurations,
        generateColDefOptions: {
          filterType: 'CLIENT_SIDE',
        },
      })
      overwriteColumnByPropertyConfiguration({
        columns: columnDefs,
        propertyConfigurations: extensions.propertyConfigurations,
      })
    }
    // @ts-ignore
    columnDefs.push(...wbsItemAdditionalPropertyColumnDefs)

    return {
      // Grid context.
      context: {
        aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
        workloadUnit: WorkloadUnit.DAY,
        ...context,
        ...extensions?.extensionOptions,
      },
      // Styling
      rowHeight: ROW_HEIGHT.SMALL,
      // Row grouping
      treeData: false,
      // Row actions
      enterMovesDownAfterEdit: true,
      rowDragManaged: false,
      rowDragMultiRow: true,
      suppressLastEmptyLineOnPaste: true,
      suppressMoveWhenRowDragging: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column definition
      columnTypes: {
        ...columnTypes(),
        priorityColumn: {
          valueFormatter: () => {
            return ''
          },
        },
        ticketTypeColumn: {
          ...columnTypes().ticketTypeColumn,
          filterValueGetter: (params: ValueGetterParams) => {
            return getTicketType(params.data?.wbsItem?.baseWbsItemType)?.name
          },
        },
        path: {
          cellStyle: { direction: 'rtl' },
        },
      },
      components: frameworkComponents,
      defaultColDef: {
        ...defaultColDef({ editable: false, sortable: true }),
        cellRenderer: DefaultCellRenderer,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          // When editing in Japanese, any keyboard event should be suppressed.
          if (params.editing && params.event.isComposing) {
            return true
          }
          // Prevent editing cell for DELETE or BACKSPACE
          return (
            !params.editing &&
            ['Delete', 'Backspace'].includes(params.event.key)
          )
        },
        valueSetter: (params: ValueSetterParams<TicketRow>) => {
          const field = params.colDef.field || params.colDef.colId
          if (
            !field ||
            (!params.oldValue && !params.newValue) ||
            params.oldValue === params.newValue
          ) {
            return false
          }
          objects.setValue(params.data, field, params.newValue)
          params.data.edited = true
          if (!params.data.editedData) {
            params.data.editedData = { [field]: params.oldValue }
          } else if (!params.data.editedData.hasOwnProperty(field)) {
            params.data.editedData[field] = params.oldValue
          }
          store.dispatch(requireSave())
          return true
        },
        cellClassRules: {
          'grid-edited-cell': params => {
            const { colDef, data, value } = params
            const field = colDef.field ?? colDef.colId
            if (
              !data.editedData ||
              !field ||
              !colDef.editable ||
              (typeof colDef.editable === 'function' &&
                !colDef.editable(params))
            ) {
              return false
            }
            return (
              data.editedData.hasOwnProperty(field) &&
              value !== data.editedData[field]
            )
          },
        },
      },
      columnDefs,
      // Copy & paste
      processCellForClipboard,
      processCellFromClipboard,
      processDataFromClipboard,
      // Footer
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['sum', 'count'],
            },
            align: 'left',
          },
          {
            statusPanel: 'countWbsStatusComponent',
            align: 'left',
          },
        ],
      },
      localeText: {
        sum: intl.formatMessage({
          id: 'bulksheet.statusPanel.sum',
        }),
        count: intl.formatMessage({
          id: 'bulksheet.statusPanel.count',
        }),
        totalAndFilteredRows: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
        }),
        of: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
        }),
      },
      excelStyles: [
        {
          id: 'dateType',
          dataType: 'DateTime',
          numberFormat: {
            format: 'yyyy/mm/dd',
          },
        },
        {
          id: 'dateTimeType',
          dataType: 'DateTime',
          numberFormat: {
            format: 'yyyy/mm/dd hh:MM:ss',
          },
        },
      ],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUuid, extensions, wbsItemAdditionalPropertyColumnDefs, context])

  return gridOptions as GridOptions
}

export const processCellForClipboard = (
  params: ProcessCellForExportParams<TicketRow>
) => {
  const { api, column, value, node, context } = params
  const colDef = column.getColDef()
  const { field, type, cellRendererParams, cellEditorParams, valueFormatter } =
    colDef
  const row: TicketRow | undefined = node?.data

  if (field) {
    if (['action', 'deliverableAttachmentSummary'].includes(field)) {
      return ''
    }
    if (field === 'rowNumber') {
      const rendererParams = cellRendererParams({ node })
      return rendererParams.value
    }
    if (field === 'wbsItem.ticketType') {
      const wbsItemType = row?.wbsItem?.wbsItemType
      return wbsItemType?.isTicket() ? wbsItemType?.name : ''
    }
    if (field === 'wbsItem.status') {
      if (row?.isTotal) {
        const { countTotal, countDone } = getStatusSummary(api, field)
        const percentage = formatPercentage(countDone, countTotal)
        const done = formatter.format(
          countDone,
          colDef.cellRendererParams.uiMeta
        )
        const total = formatter.format(
          countTotal,
          colDef.cellRendererParams.uiMeta
        )
        return `${percentage} (${done} / ${total})`
      }
      return value
    }
    if (field === 'wbsItem.tags') {
      return value?.map(v => v.name).join(TAG_DELIMITER)
    }
    if (field === 'wbsItem.description') {
      return `"${normalize(params.value || '')}"`
    }
    if (field === 'commentSummary.latestComment') {
      return value ? `"${commentFormat(params.value)}"` : ' '
    }
    if (field === 'wbsItem.watchers') {
      return value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER)
    }
  }
  if (!type) {
    if (cellEditorParams) {
      const { valuesAllowed, entity } = cellEditorParams
      if (valuesAllowed) {
        // For extension select column
        const options: CustomEnumValue[] = valuesAllowed ?? []
        const option = options.find(v => v.value === value)
        return option?.name || ''
      }
      if (entity) {
        // For extension EntitySearch column
        const options = context[entity] ?? []
        const option = options.find(v =>
          [value, value?.uuid].filter(v => !!v).includes(v.uuid)
        )
        return (
          getLabel(option?.nameI18n) ||
          option?.displayName ||
          option?.name ||
          ''
        )
      }
    }
    if (valueFormatter && typeof valueFormatter === 'function' && node) {
      // For extension date and datetime column
      const func: ValueFormatterFunc = valueFormatter
      return (
        func({
          ...params,
          node,
          data: node.data,
          column,
          colDef,
        }) ?? ''
      )
    }
    if (typeof value === 'object') {
      return value.name ?? value.displayName
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    return (
      getLabel(value?.nameI18n) ||
      value?.name ||
      value?.displayName ||
      value?.officialName
    )
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const option = options.find(v => v.value === value)
    return getLabel(option?.nameI18n) || option?.name || ''
  }
  if (type.includes(ColumnType.date)) {
    return value ? dateVoService.toString(dateVoService.construct(value)) : ''
  }
  if (type.includes(ColumnType.dateTime)) {
    return value ? formatDateTime(value) : ''
  }
  if (type.includes(ColumnType.multiLineText)) {
    return `"${normalize(params.value || '')}"`
  }
  if (type.includes(ColumnType.multiSelect)) {
    return params?.value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER) ?? ''
  }
  return value
}

export const processCellFromClipboard = ({
  column,
  value,
  context,
  node,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellRendererParams, cellEditorParams, valueFormatter } =
    colDef
  const row: TicketRow | undefined = node?.data

  if (field) {
    if (field === 'wbsItem.tags') {
      const projectUuid = store.getState().project.selected
      if (!projectUuid) return
      const names = value.split(TAG_DELIMITER).filter(v => !!v)
      const options = store.getState().tag[projectUuid]
      return options.filter(v => names.includes(v.name))
    }
    if (field === 'wbsItem.watchers') {
      const names = value.split(MULTI_PROPERTY_DELIMITER).filter(v => !!v)
      const options = context['member']
      return options.filter(o => names.includes(o.name))
    }
    if (field === 'wbsItem.sprint') {
      const team = node?.data?.wbsItem.team
      if (!team) return undefined
      return context.sprint?.find(
        (v: SprintDetail) => v.teamUuid === team.uuid && v.name === value
      )
    }
  }
  if (!type) {
    if (cellEditorParams) {
      const { valuesAllowed, entity } = cellEditorParams
      if (valuesAllowed) {
        // For extension select column
        const options: CustomEnumValue[] = valuesAllowed ?? []
        return options.find(o => o.name === value)?.value
      }
      if (entity) {
        // For extension EntitySearch column
        const options = context[entity] ?? []
        return options.find(o =>
          [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
            .filter(v => !!v)
            .includes(value)
        )
      }
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    if (cellEditorParams.entity) {
      const options = context[cellEditorParams.entity]
      return options.find(o =>
        [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
          .filter(v => !!v)
          .includes(value)
      )
    }
    return value
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const target = options.find(o =>
      [getLabel(o.nameI18n), o.name].filter(v => !!v).includes(value)
    )
    return target?.value
  }
  if (type.includes(ColumnType.date)) {
    const date = dateVoService.construct(value)
    return dateVoService.isValid(date)
      ? dateVoService.toString(date)
      : undefined
  }
  if (type.includes(ColumnType.dateTime)) {
    try {
      return new DateTimeVO(value).toPlainValue()
    } catch (e) {
      if (e instanceof DomainError) return undefined
      throw e
    }
  }
  if (type.includes(ColumnType.multiSelect)) {
    const { valuesAllowed, entity } = cellEditorParams?.uiMeta
    if (!valuesAllowed) return
    const names = value.split(MULTI_PROPERTY_DELIMITER).filter(v => !!v)
    const options: CustomEnumValue[] = valuesAllowed ?? []
    return options.filter(v => names?.includes(v.name)).filter(v => !!v)
  }
  return value
}
