import _ from 'lodash'
import { intl } from '../../../i18n'
import { LEVEL_SEPARATOR } from '../../containers/BulkSheet/const'
import { getLabel } from '../../../lib/commons/i18nLabel'
import {
  NewWbsItemRow,
  ProjectPlanNewRow,
  ProjectPlanRowBody,
} from './projectPlanNew'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { generateUuid } from '../../../utils/uuids'
import DateVO from '../../../vo/DateVO'
import { dateValueParser } from '../../containers/commons/AgGrid'
import store from '../../../store'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { ProjectMemberProps } from '../../../lib/functions/projectMember'
import { SprintDetail } from '../../../lib/functions/sprint'
import {
  parseFullNumberToHalf,
  toInteger,
  toNumber,
} from '../../../utils/number'
import { TAG_DELIMITER } from '../../../lib/functions/tag'

export class ExcelParser {
  private data: ProjectPlanNewRow[]
  private context: object
  private treeRootUuid: string | undefined

  private source: object[]
  private cols: { [key: string]: string }

  private updatedData = new Map<string, ProjectPlanNewRow>()
  private addedChildMap = new Map<string, ProjectPlanNewRow>()
  private addedNextSiblingMap = new Map<string, ProjectPlanNewRow>()
  private addedRootList: ProjectPlanNewRow[] = []

  constructor(
    source: object[],
    {
      data,
      context,
      treeRootUuid,
    }: {
      data: ProjectPlanNewRow[]
      context: object
      treeRootUuid?: string
    }
  ) {
    this.source = source.slice(1)
    this.data = data
    this.context = context
    this.treeRootUuid = treeRootUuid

    const importHeader = source[0]
    const headers = Object.fromEntries(
      Object.entries(importHeader).map(([k, v]) => [v, k])
    )
    const columnName = id => intl.formatMessage({ id })
    this.cols = {
      displayName: headers[columnName('projectPlan.displayName')],
      code: headers[columnName('projectPlan.code')],
      type: headers[columnName('projectPlan.type')],
      ticketType: headers[columnName('projectPlan.ticketType')],
      status: headers[columnName('projectPlan.status')],
      substatus: headers[columnName('projectPlan.substatus')],
      priority: headers[columnName('projectPlan.priority')],
      difficulty: headers[columnName('projectPlan.difficulty')],
      description: headers[columnName('projectPlan.description')],
      team: headers[columnName('projectPlan.team')],
      sprint: headers[columnName('projectPlan.sprint')],
      accountable: headers[columnName('projectPlan.accountable')],
      responsible: headers[columnName('projectPlan.responsible')],
      assignee: headers[columnName('projectPlan.assignee')],
      watchers: headers[columnName('projectPlan.watcher')],
      estimatedStoryPoint:
        headers[columnName('projectPlan.estimatedStoryPoint')],
      deliverableEstimatedHour:
        headers[columnName('projectPlan.estimated.deliverable')],
      taskEstimatedHour: headers[columnName('projectPlan.estimated.task')],
      scheduledStartDate:
        headers[columnName('projectPlan.scheduledDate.start')],
      scheduledEndDate: headers[columnName('projectPlan.scheduledDate.end')],
      actualStartDate: headers[columnName('projectPlan.actualDate.start')],
      actualEndDate: headers[columnName('projectPlan.actualDate.end')],
      deliverableEstimatedAmount:
        headers[columnName('projectPlan.estimatedAmount.deliverable')],
      taskEstimatedAmount:
        headers[columnName('projectPlan.estimatedAmount.task')],
      actualAmount: headers[columnName('projectPlan.actualAmount')],
      tags: headers[columnName('projectPlan.tags')],
    }
  }

  public validate(): string[] {
    const messages: string[] = []
    const addMessage = (index, messageId, option?) => {
      const message = intl.formatMessage({ id: messageId }, option)
      messages.push(
        intl.formatMessage(
          { id: 'projectPlan.import.message.format' },
          { index, message }
        )
      )
    }
    let prevLevel: number | undefined
    let sourceTree: ProjectPlanNewRow[] = []
    const embodiedRows = _.cloneDeep(
      this.data.filter(v => v.body?.wbsItem?.code)
    )
    const wbsItemTypes = store.getState().project.wbsItemTypes.getAll()
    const typeMap = new Map(wbsItemTypes.map(v => [v.name, v]))
    const rootData = this.treeRootUuid
      ? this.data.find(v => v.uuid === this.treeRootUuid)
      : undefined
    this.source.forEach((s, i) => {
      const currentLevel = this.getLevel(s)
      const treeStr = s[this.cols.displayName].toString()
      const displayName = treeStr.replaceAll(LEVEL_SEPARATOR, '')
      const type: WbsItemTypeVO | undefined = typeMap.get(s[this.cols.type])
      try {
        // Validation
        // Invalid tree
        if (currentLevel - (prevLevel ?? currentLevel) > 1) {
          addMessage(i + 1, 'projectPlan.import.invalid.tree')
          return
        }
        // Name required
        if (!displayName) {
          addMessage(i + 1, 'projectPlan.import.displayName.required')
          return
        }
        const isAdded = !s[this.cols.code]
        if (isAdded) {
          if (!s[this.cols.type]) {
            addMessage(i + 1, 'projectPlan.import.type.required')
            return
          }

          // Invalid wbs type
          if (!type) {
            addMessage(i + 1, 'projectPlan.import.invalid.ticketType')
            return
          }

          const parent = this.getParentSource(s, i)
          if (!parent) {
            const rootType = rootData?.body?.wbsItem.wbsItemType

            // Invalid parent type
            const isInvalidParentType =
              rootType && !(rootType && type?.canBeChildOf(rootType))
            if (isInvalidParentType) {
              addMessage(i + 1, 'projectPlan.import.invalid.parentType', {
                parentType: rootType.name,
                type: s[this.cols.type],
              })
              return
            }

            // Invalid root type
            const isInvalidRootType =
              !rootType && !(type?.isProcess() || type?.isWorkgroup())
            if (isInvalidRootType) {
              addMessage(i + 1, 'projectPlan.import.invalid.type', {
                type: type?.rootType,
              })
              return
            }
          }

          if (parent) {
            const parentType = typeMap.get(parent[this.cols.type])
            const isInvalidParentType =
              !parentType || !type?.canBeChildOf(parentType)
            if (isInvalidParentType) {
              addMessage(i + 1, 'projectPlan.import.invalid.parentType', {
                parentType: parent[this.cols.type],
                type: s[this.cols.type],
              })
              return
            }
          }

          const createRandomCode = Math.random()
            .toString(36)
            .slice(-8)
            .toUpperCase()
          s[this.cols.code] = createRandomCode
        }
        if (messages.length) return

        // Prepare import
        if (!isAdded) {
          // Edited data
          const embodiedRow: ProjectPlanNewRow | undefined = embodiedRows.find(
            v => v.body!.wbsItem.code === s[this.cols.code]?.toString()
          )
          if (!embodiedRow) {
            return
          }
          const mergedWbsItem = this.mergeUpdatedRow(
            embodiedRow.body!.wbsItem,
            s
          )
          const newRow = {
            ...embodiedRow,
            edited: true,
            body: {
              ...embodiedRow.body,
              wbsItem: mergedWbsItem,
              sprint: this.getSprintFromSource(
                { wbsItem: mergedWbsItem, sprint: embodiedRow.body?.sprint },
                s
              ),
            },
          } as ProjectPlanNewRow
          if (this.hasDiff(embodiedRow.body!, newRow.body!)) {
            this.updatedData.set(embodiedRow.uuid, newRow)
          }
          sourceTree.splice(currentLevel, sourceTree.length, newRow)
        } else {
          // Added data
          const uuid = generateUuid()
          const wbsItemUuid = generateUuid()
          const rootType = type!.isWorkgroup()
            ? WbsItemType.PROCESS
            : type!.rootType

          const newWbsItem = this.mergeUpdatedRow(
            {
              uuid: wbsItemUuid,
              wbsItemType: type,
              baseWbsItemType: type,
              type: rootType,
            },
            s
          )
          const newRow = {
            uuid,
            wbsItemUuid,
            type: rootType,
            added: true,
            body: {
              wbsItem: newWbsItem,
              sprint: this.getSprintFromSource({ wbsItem: newWbsItem }, s),
            } as ProjectPlanRowBody,
          } as ProjectPlanNewRow

          if (prevLevel === currentLevel - 1) {
            const parentRow = sourceTree[sourceTree.length - 1]
            this.addedChildMap.set(parentRow.uuid, newRow)
          } else if (prevLevel === currentLevel) {
            const prevSiblingRow = sourceTree[sourceTree.length - 1]
            this.addedNextSiblingMap.set(prevSiblingRow.uuid, newRow)
          } else {
            const prevSiblingRow = sourceTree[currentLevel]
            if (prevSiblingRow) {
              this.addedNextSiblingMap.set(prevSiblingRow.uuid, newRow)
            } else {
              this.addedRootList.push(newRow)
            }
          }
          sourceTree.splice(currentLevel, sourceTree.length, newRow)
        }
      } finally {
        prevLevel = currentLevel
      }
    })
    return messages
  }

  public parse(): ProjectPlanNewRow[] {
    const addNext = (row: ProjectPlanNewRow) => {
      const childRow = this.addedChildMap.get(row.uuid)
      if (childRow) {
        childRow.treeValue = [...row.treeValue, childRow.uuid]
        newData.push(childRow)
        addNext(childRow)
        this.addedChildMap.delete(row.uuid)
      }
      const nextRow = this.addedNextSiblingMap.get(row.uuid)
      if (nextRow) {
        nextRow.treeValue = [
          ...row.treeValue.slice(0, row.treeValue.length - 1),
          nextRow.uuid,
        ]
        newData.push(nextRow)
        addNext(nextRow)
        this.addedNextSiblingMap.delete(row.uuid)
      }
    }
    const newData: ProjectPlanNewRow[] = []
    this.data.forEach(row => {
      const updated = this.updatedData.get(row.uuid)
      if (updated) {
        newData.push(updated)
      } else {
        newData.push(row)
      }
      addNext(row)
    })
    this.addedRootList.forEach(row => {
      row.treeValue = [
        ...(this.treeRootUuid ? [this.treeRootUuid] : []),
        row.uuid,
      ]
      newData.push(row)
      addNext(row)
    })
    return newData
  }

  private getLevel(s: object): number {
    const treeStr = s[this.cols.displayName].toString()
    return Array.from(treeStr.matchAll(LEVEL_SEPARATOR)).length
  }

  private getParentSource(s: object, i: number): object | undefined {
    const currentLevel = this.getLevel(s)
    if (currentLevel === 0) return undefined
    for (let j = i - 1; 0 <= j; j--) {
      const prevLevel = this.getLevel(this.source[j])
      if (prevLevel === currentLevel - 1) {
        return this.source[j]
      }
    }
    return undefined
  }

  private findFromContext(key, value) {
    return this.context[key]?.find(v =>
      [
        v.uuid,
        v.value,
        v.name,
        v.displayName,
        v.officialName,
        v.code,
        getLabel(v.nameI18n),
      ]
        .filter(v => !!v)
        .includes(value)
    )
  }

  private parseDate(value?: string) {
    if (!value) return undefined
    return new DateVO(dateValueParser(value?.toString())).serialize()
  }

  private mergeUpdatedRow(w, s): NewWbsItemRow {
    return {
      ...w,
      displayName: s[this.cols.displayName]
        .toString()
        .replaceAll(LEVEL_SEPARATOR, ''),
      code: s[this.cols.code]?.toString(),
      status: this.cols.status
        ? this.findFromContext('status', s[this.cols.status])?.value ??
          WbsItemStatus.TODO
        : w.status,
      substatus: this.cols.substatus
        ? this.findFromContext('substatus', s[this.cols.substatus])?.value
        : w.substatus,
      priority: this.cols.priority
        ? this.findFromContext('priority', s[this.cols.priority])?.value
        : w.priority,
      difficulty: this.cols.difficulty
        ? this.findFromContext('difficulty', s[this.cols.difficulty])?.value
        : w.difficulty,
      description: this.cols.description
        ? (s[this.cols.description] ?? '').toString()
        : w.description,
      team: this.cols.team
        ? this.findFromContext('team', s[this.cols.team])
        : w.team,
      accountable: this.cols.accountable
        ? this.findFromContext('member', s[this.cols.accountable])
        : w.accountable,
      responsible: this.cols.responsible
        ? this.findFromContext('member', s[this.cols.responsible])
        : w.responsible,
      assignee: this.cols.assignee
        ? this.findFromContext('member', s[this.cols.assignee])
        : w.assignee,
      watchers: this.cols.watchers
        ? s[this.cols.watchers]
            ?.toString()
            ?.split(',')
            .map(v => this.findFromContext('member', v))
            .filter(v => v)
        : w.watchers,
      estimatedStoryPoint: this.cols.estimatedStoryPoint
        ? toInteger(s[this.cols.estimatedStoryPoint])
        : w.estimatedStoryPoint,
      estimatedHour:
        w.wbsItemType.isDeliverable() && this.cols.deliverableEstimatedHour
          ? toNumber(s[this.cols.deliverableEstimatedHour])
          : w.wbsItemType.isTask() && this.cols.taskEstimatedHour
          ? toNumber(s[this.cols.taskEstimatedHour])
          : w.estimatedHour,
      scheduledDate: {
        startDate: this.cols.scheduledStartDate
          ? this.parseDate(s[this.cols.scheduledStartDate]) || undefined
          : w.scheduledDate?.startDate,
        endDate: this.cols.scheduledEndDate
          ? this.parseDate(s[this.cols.scheduledEndDate]) || undefined
          : w.scheduledDate?.endDate,
      },
      actualDate: {
        startDate: this.cols.actualStartDate
          ? this.parseDate(s[this.cols.actualStartDate]) || undefined
          : w.actualDate?.startDate,
        endDate: this.cols.actualEndDate
          ? this.parseDate(s[this.cols.actualEndDate]) || undefined
          : w.actualDate?.endDate,
      },
      estimatedAmount:
        w.wbsItemType.isDeliverable() && this.cols.deliverableEstimatedAmount
          ? toInteger(s[this.cols.deliverableEstimatedAmount])
          : w.wbsItemType.isTask() && this.cols.taskEstimatedAmount
          ? toInteger(s[this.cols.taskEstimatedAmount])
          : w.estimatedAmount,
      actualAmount: this.cols.actualAmount
        ? toInteger(s[this.cols.actualAmount])
        : w.actualAmount,
      tags: this.cols.tags
        ? s[this.cols.tags]
            ?.toString()
            ?.split(TAG_DELIMITER)
            .map(v => this.findFromContext('tag', v))
            .filter(v => !!v)
        : w.tags,
      sprint: this.cols.sprint
        ? this.getSprintFromSource({ wbsItem: w, sprint: w.sprint }, s)
        : w.sprint,
    } as NewWbsItemRow
  }

  private getSprintFromSource(
    b: ProjectPlanRowBody,
    s
  ): SprintDetail | undefined {
    const w = b.wbsItem
    const sprint = b.sprint
    if (!this.cols.sprint) return sprint
    const team = this.cols.team
      ? this.findFromContext('team', s[this.cols.team] ?? w.team?.uuid)
      : w.team
    return this.context['sprint'].find(
      v =>
        v.teamUuid === team?.uuid && v.name === s[this.cols.sprint]?.toString()
    )
  }

  private hasDiff(ab: ProjectPlanRowBody, bb: ProjectPlanRowBody): boolean {
    const a = ab.wbsItem
    const b = bb.wbsItem
    return (
      a.status !== b.status ||
      a.substatus !== b.substatus ||
      a.displayName !== b.displayName ||
      a.team?.uuid !== b.team?.uuid ||
      ab.sprint?.uuid !== bb.sprint?.uuid ||
      a.accountable?.uuid !== b.accountable?.uuid ||
      a.responsible?.uuid !== b.responsible?.uuid ||
      a.assignee?.uuid !== b.assignee?.uuid ||
      this.watcherNameToString(a.watchers) !==
        this.watcherNameToString(b.watchers) ||
      (a.estimatedStoryPoint ?? 0) !== (b.estimatedStoryPoint ?? 0) ||
      (a.estimatedHour ?? 0) !== (b.estimatedHour ?? 0) ||
      a.scheduledDate?.startDate !== b.scheduledDate?.startDate ||
      a.scheduledDate?.endDate !== b.scheduledDate?.endDate ||
      a.actualDate?.startDate !== b.actualDate?.startDate ||
      a.actualDate?.endDate !== b.actualDate?.endDate ||
      a.priority !== b.priority ||
      a.difficulty !== b.difficulty ||
      a.description !== b.description ||
      (a.estimatedAmount ?? 0) !== (b.estimatedAmount ?? 0) ||
      (a.actualAmount ?? 0) !== (b.actualAmount ?? 0) ||
      a.tags !== b.tags
    )
  }

  private watcherNameToString(watchers?: ProjectMemberProps[]): string {
    if (!watchers) return ''
    return (
      watchers
        .map(v => v?.name ?? '')
        .sort()
        .join(',') ?? ''
    )
  }
}
