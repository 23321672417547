import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { TimerRepository } from '../../applications/ports/timerRepository'
import {
  MeasuringTimer,
  TimerMeasuringTaskListDetail,
} from '../../domain/entity/TimerEntity'
import store from '../../store'

export const useTimerRepository = (): TimerRepository => {
  const start = useCallback(async (userUuid: string, taskUuid: string) => {
    const response = await api.functional.request(
      'POST',
      '/api/v1/projects/timer/start',
      {
        userUuid,
        taskUuid,
      }
    )
    return response.json.timers
  }, [])

  const stop = useCallback(async (userUuid: string, taskUuid: string) => {
    const response = await api.functional.request(
      'PUT',
      '/api/v1/projects/timer/stop',
      {
        userUuid,
        taskUuid,
      }
    )
    return response.json.timers
  }, [])

  return {
    start,
    stop,
  }
}

export const getMeasuringTimer = async (): Promise<MeasuringTimer> => {
  const response = await api.functional.request(
    'GET',
    '/api/v1/projects/timer/measuring/me'
  )
  return response.json
}

export const getTimerMeasuringTaskList = async (
  projectUuid: string
): Promise<TimerMeasuringTaskListDetail[]> => {
  const response = await api.functional.request(
    'GET',
    '/api/v1/projects/timer/measuring/list/project',
    { projectUuid: projectUuid }
  )
  return response.json.map((res: any) => ({
    userName: res.userName,
    taskName: res.taskName ?? undefined,
    parentTaskName: res.parentTaskName ?? undefined,
    startTime: res.timerStartDateTime ?? undefined,
    elapsedTime: undefined,
  }))
}
