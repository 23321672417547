import {
  ProcessCellForExportParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { intl } from '../../../../i18n'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import { MyWbsItemRow } from '../myWbsItems'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import {
  processCellForClipboard as projectPlanProcessCellForClipboard,
  processCellFromClipboard as projectPlanProcessCellFromClipboard,
} from '../../ProjectPlanNew/gridOptions'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { InputError } from '../../../containers/BulkSheetView/lib/validation'

const defaultGridOptions = () => {
  return {
    // Grid context
    context: {
      errors: new InputError(),
    },
    // Styling
    groupHeaderHeight: 25,
    headerHeight: 45,
    rowHeight: 32,
    rowClassRules: {
      'ag-row-copied-or-selected-row': p =>
        p.context.copied?.some(v => v.id === p.node.id),
      'ag-row-copied-single-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'both'),
      'ag-row-copied-start-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'top'),
      'ag-row-copied-middle-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'middle'),
      'ag-row-copied-end-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'bottom'),
    },
    // Row Grouping
    treeData: false,
    // Column definition
    columnTypes: columnTypes(),
    components: frameworkComponents,
    enterMovesDownAfterEdit: true,
    defaultColDef: {
      ...defaultColDef(),
      cellRenderer: DefaultCellRenderer,
      floatingFilter: true,
      hide: false,
      valueSetter: (params: ValueSetterParams) => {
        const { colDef, data, oldValue, newValue } = params
        const field = colDef.field || colDef.colId
        if (!field || (!oldValue && !newValue) || oldValue === newValue) {
          return false
        }
        objects.setValue(data, field, newValue)
        data.edited = true
        if (!data.editedData) {
          data.editedData = { [field]: oldValue }
        } else if (!data.editedData.hasOwnProperty(field)) {
          data.editedData[field] = oldValue
        }
        store.dispatch(requireSave())
        return true
      },
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
      },
    },
    // Footer
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'count'],
          },
          align: 'left',
        },
      ],
    },
    localeText: {
      sum: intl.formatMessage({
        id: 'bulksheet.statusPanel.sum',
      }),
      count: intl.formatMessage({
        id: 'bulksheet.statusPanel.count',
      }),
      totalAndFilteredRows: intl.formatMessage({
        id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
      }),
      of: intl.formatMessage({
        id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
      }),
    },
    processCellForClipboard,
    processCellFromClipboard,
  }
}

const getMyWbsItemCustomEnumColumnOptions = (
  row: MyWbsItemRow | undefined,
  cellEditorParams,
  context
) => {
  const { customEnumCode, getOptionKey } = cellEditorParams
  if (customEnumCode) {
    const options = context[customEnumCode]
    if (options.length === 0 || !getOptionKey) return options
    return options.get(getOptionKey(row))
  }
  return []
}

const processCellForClipboard = (props: ProcessCellForExportParams) => {
  const { column, value, node, context } = props
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  if (!value) return value

  const row: MyWbsItemRow = node?.data
  if (field === 'body.wbsItem.type') {
    return row?.body?.wbsItem?.baseWbsItemType?.getNameWithSuffix() || ''
  }
  if (field === 'body.tags') {
    return value?.map(v => v.name)?.join(TAG_DELIMITER)
  }
  if (type?.includes(ColumnType.myWbsItemCustomEnum)) {
    const option = getMyWbsItemCustomEnumColumnOptions(
      row,
      cellEditorParams,
      context
    )?.find(o => o.value === value)
    return getLabel(option?.nameI18n) || option?.name || ''
  }
  return projectPlanProcessCellForClipboard(props)
}

const processCellFromClipboard = (
  params: ProcessCellForExportParams<MyWbsItemRow>
) => {
  const { column, value, context, node } = params
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  if (!value) return

  const row: MyWbsItemRow | undefined = node?.data
  if (field === 'body.wbsItem.watchers') {
    if (!row) return value
    const names = value.split(',').filter(v => !!v)
    if (!context || !('member' in context)) return value
    const options = context['member']
    return options.filter(
      o =>
        o.projectUuid === row?.body?.wbsItem?.projectUuid &&
        names.includes(o.name)
    )
  }
  if (field === 'body.tags') {
    const names = value.split(TAG_DELIMITER).filter(v => !!v)
    const projectUuid = row?.body?.project?.uuid
    const options = context['tags'].get(projectUuid) ?? []
    return options.filter(v => names.includes(v.name))
  }
  if (type?.includes(ColumnType.autocomplete)) {
    const { entity, optionsFilter } =
      typeof cellEditorParams === 'function'
        ? cellEditorParams(params)
        : cellEditorParams
    if (!entity || !(entity in context)) return value
    const options = optionsFilter
      ? context[entity].filter(o => optionsFilter(o, row)) || []
      : context[entity] || []

    return options.find(o =>
      [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
        .filter(v => !!v)
        .includes(value)
    )
  }
  if (type?.includes(ColumnType.myWbsItemCustomEnum)) {
    const options = getMyWbsItemCustomEnumColumnOptions(
      row,
      cellEditorParams,
      context
    )
    return (
      options?.find(o =>
        [getLabel(o.nameI18n), o.name].filter(v => !!v).includes(value)
      )?.value || value
    )
  }
  return projectPlanProcessCellFromClipboard(params)
}

export default defaultGridOptions
