import React, { useMemo } from 'react'
import _ from 'lodash'
import { IHeaderParams } from 'ag-grid-community'
import DateVO from '../../../../../../../../vo/DateVO'
import { intl } from '../../../../../../../../i18n'
import { CalendarDateVO } from '../../../../../../../../domain/value-object/CalendarDateVO'
import { GanttReportParameter } from './ganttUtil'
import {
  DayOfWeek,
  mapDayOfWeekToNumber,
} from '../../../../../../../../lib/functions/report'

export const GanttHeaderGroup = (props: IHeaderParams) => {
  const timeScale: CalendarDateVO[] =
    props.context.ganttTimeScale?.slice(
      0,
      props.context.ganttTimeScale?.length - 1
    ) ?? []

  const parameter: GanttReportParameter = props.context.ganttParameter

  const groups: { label: string; length: number }[] = useMemo(() => {
    const groupedTimeScale = timeScale.map(v => {
      const date = new DateVO(v.date)
      const { unit, step } = parameter
      if (unit === 'day') {
        return (
          date
            .subtractDays(
              (date.getDay() + 7 - mapDayOfWeekToNumber(DayOfWeek.MONDAY)) % 7
            )
            .format('M/D') + intl.formatMessage({ id: 'week' })
        )
      } else if (unit === 'week') {
        return date.format(intl.formatMessage({ id: 'date.format.yyyy.m' }))
      } else if (unit === 'month') {
        if (step === 1) {
          return (
            date.getYear() +
            intl.formatMessage({ id: 'year.ja' }) +
            Math.floor((date.getMonth() + 2) / 3) +
            'Q'
          )
        } else {
          return date.getYear().toString()
        }
      }
      return ''
    })
    const uniqTimeScale = _.sortedUniq(groupedTimeScale)
    return uniqTimeScale.map(label => {
      return {
        label: label,
        length: groupedTimeScale.filter(v => v === label).length,
      }
    })
  }, [timeScale, parameter])

  if (!timeScale || timeScale.length === 0 || parameter.unit === 'year') {
    return <div>{props?.column?.getColDef()?.headerName}</div>
  }

  return (
    <GanttGroupHeader>
      {groups.map(({ label, length }) => (
        <GanttGroupMonthHeader
          key={`group-gantt-header-${label}`}
          style={{
            width: `${(length / timeScale.length) * 100}%`,
          }}
        >
          {label}
        </GanttGroupMonthHeader>
      ))}
    </GanttGroupHeader>
  )
}

const GanttGroupHeader = ({ children }) => (
  <div className="sevend-ag-cell-gantt-group-header">{children}</div>
)

const GanttGroupMonthHeader = ({ children, style }) => (
  <span
    style={style}
    className="sevend-ag-cell-gantt-group-header__month-header"
  >
    {children}
  </span>
)
