import { ICellRendererParams } from 'ag-grid-community'
import { AgGridRowResizeDragger } from '../../../commons/AgGrid/components/dragger/rowResizeDragger'

type Props = ICellRendererParams & {
  onChangeRowSize?: (rowHeight: number) => void
}

export const DragCellRenderer = ({ node, api, onChangeRowSize }: Props) => {
  return (
    <div style={{ marginLeft: '-33px' }}>
      <AgGridRowResizeDragger
        node={node}
        api={api}
        onChangeRowSize={onChangeRowSize}
      />
    </div>
  )
}
