import numeral from 'numeral'
import { Box, DialogActions } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import CircularButton from '../../components/buttons/CircularButton'
import { useCallback, useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import TaskActualWorkAPI, {
  ActualWorkDetail,
  TaskActualWorkUpdateBatchDeltaRequest,
} from '../../../lib/functions/taskActualWork'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import store, { AllState } from '../../../store'
import {
  DateCell,
  FilterInputCell,
  RowHeaderCell,
  StyledTableRow,
} from '../../containers/DataCell'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import {
  withKeyBind,
  WithKeyBindProps,
} from '../../higher-order-components/keyBind'
import DateVO from '../../../vo/DateVO'
import { intl } from '../../../i18n'
import { FontSize } from '../../../styles/commonStyles'
import {
  parseFullNumberToHalf,
  parseToHalfNumberOrEmptyString,
} from '../../../utils/number'
import { UserDetail } from '../../../lib/functions/user'
import { connect } from 'react-redux'
import { KEY_SAVE } from '../../model/keyBind'

interface Props extends WrappedComponentProps, WithKeyBindProps {
  open: boolean
  closeHandler: Function
  onSave?: Function
  taskUuid: string
  externalId: string
  user?: UserDetail
}

const mapStateToProps = (state: AllState) => ({
  user: state.user.user,
})

const TaskActualWorkRegistrationDialog = connect(mapStateToProps)(
  ({
    open,
    closeHandler,
    onSave,
    taskUuid,
    externalId,
    user,
    addKeyBindListeners,
    removeKeyBindListeners,
  }: Props) => {
    const [taskName, setTaskName] = useState('')
    const [actualDate, setActualDate] = useState(DateVO.now())
    const [actualHour, setActualHour] = useState('')
    const [taskActualWorks, setTaskActualWorks] = useState<ActualWorkDetail[]>(
      []
    )
    const [initialHour, setInitialHour] = useState(0)
    const [isComposing, setIsComposing] = useState<boolean>(false)

    useEffect(() => {
      if (open) {
        setTaskActualWork()
        addKeyBindListeners([
          {
            key: KEY_SAVE,
            fn: submit,
            stopDefaultBehavior: true,
          },
        ])
      }
    }, [open])

    const setTaskActualWork = async () => {
      if (!taskUuid) {
        return
      }
      const response = await TaskActualWorkAPI.getTaskActualWorkByTaskUuid(
        taskUuid
      )
      const { task, actualWorks } = response.json
      const taskActualWorks = actualWorks.filter(
        actualWork => actualWork.projectMember.uuid === user?.uuid
      )
      const taskActualWork = taskActualWorks.find(
        v => v.actualDate === formatDate(actualDate.toDate())
      )
      setTaskName(task.displayName)
      setActualHour(taskActualWork ? formatHour(taskActualWork.hour) : '')
      setTaskActualWorks(taskActualWorks)
      setInitialHour(taskActualWork?.hour ?? 0)
    }

    const formatDate = useCallback((date: Date): string => {
      return new DateVO(date).formatForApi() || ''
    }, [])

    const formatHour = useCallback((value: number): string => {
      if (0 < Number(value) && Number(value) <= 24) {
        return Number(value).toFixed(2)
      }
      return '0.00'
    }, [])

    const close = useCallback(() => {
      closeHandler()
      removeKeyBindListeners()
    }, [])

    const submit = useCallback(async () => {
      try {
        const response = await TaskActualWorkAPI.updateBatchDelta(
          createTaskActualWorkRequest()
        )
        if (!response.hasError) {
          store.dispatch(
            addScreenMessage(externalId, {
              type: MessageLevel.SUCCESS,
              title: intl.formatMessage({ id: 'registration.complete' }),
            })
          )
          if (onSave) {
            onSave(createTaskActualWorkRegisterResult(response.json))
          }
        }
      } finally {
        close()
      }
    }, [actualDate, actualHour, initialHour])

    const createTaskActualWorkRegisterResult = useCallback(
      (response: any) => {
        let output: any = {}
        if (response.added && response.added.length > 0) {
          output = response.added[0]
        }
        if (response.edited && response.edited.length > 0) {
          output = response.edited[0]
        }
        if (response.deleted && response.deleted.length > 0) {
          output = response.deleted[0]
        }
        return {
          hourDiff: Number(actualHour) - initialHour,
          actualDate: formatDate(actualDate.toDate()),
          actualWorkUuid: output.uuid,
          actualWorkLockVersion: output.lockVersion,
        }
      },
      [actualDate, actualHour, initialHour]
    )

    const createTaskActualWorkRequest =
      useCallback((): TaskActualWorkUpdateBatchDeltaRequest => {
        let input: TaskActualWorkUpdateBatchDeltaRequest = {
          taskActualWorks: [],
        }
        const hour = Number(actualHour)
        const hourIsEmpty = Number(actualHour) < 0.01
        const actualWorkValue = {
          hour,
          actualDate: formatDate(actualDate.toDate()),
          actualDateTime: {
            startDateTime: Date.parse(`${actualDate} 00:00:00`),
            endDateTime: Date.parse(`${actualDate} 00:00:00`),
          },
        }
        const currentTaskActualWork = taskActualWorks.find(
          v => v.actualDate === formatDate(actualDate.toDate())
        )
        if (
          (!currentTaskActualWork && !hourIsEmpty) ||
          (currentTaskActualWork && currentTaskActualWork.hour !== hour)
        ) {
          input.taskActualWorks.push({
            ...actualWorkValue,
            taskUuid: taskUuid,
          })
        }
        return input
      }, [actualDate, actualHour])

    const dateChanged = useCallback(
      (date: DateVO | undefined) => {
        if (!date) {
          return
        }
        const taskActualWork = taskActualWorks.find(
          v => v.actualDate === formatDate(date.toDate())
        )
        setActualDate(date)
        setActualHour(taskActualWork ? formatHour(taskActualWork.hour) : '')
      },
      [taskActualWorks]
    )

    const hourChanged = useCallback(value => {
      setActualHour(parseToHalfNumberOrEmptyString(value))
    }, [])

    useEffect(() => {
      if (isComposing) return
      hourChanged(actualHour)
    }, [actualHour])

    const blurred = useCallback((event: any) => {
      const input = parseFullNumberToHalf(event.target.value)
      const value: number = numeral(input).value()
      setActualHour(formatHour(value))
    }, [])

    return (
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogContent sx={{ width: '410px', height: '100%' }}>
          <Box
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
              fontSize: FontSize.LARGE,
            }}
          >
            {taskName}
          </Box>
          <Box
            sx={{
              '@global': {
                '.react-datepicker': {
                  marginLeft: '-205px!important',
                  marginTop: '-10px!important',
                  position: 'absolute!important',
                },
                '.react-datepicker__triangle': {
                  left: '35px!important',
                },
              },
            }}
          >
            <TableContainer component={Paper}>
              <Table
                sx={{ width: '100%', height: '100%' }}
                size="small"
                aria-label="customized table"
              >
                <TableBody>
                  <StyledTableRow key="actualDate">
                    <RowHeaderCell align="left">
                      {intl.formatMessage({
                        id: 'taskActualWorkList.header.date',
                      })}
                    </RowHeaderCell>
                    <DateCell
                      colSpan={4}
                      value={actualDate}
                      onChange={dateChanged}
                    />
                  </StyledTableRow>
                  <StyledTableRow key="hour">
                    <RowHeaderCell align="left">
                      {intl.formatMessage({
                        id: 'taskActualWorkList.header.hour',
                      })}
                    </RowHeaderCell>
                    <FilterInputCell
                      colSpan={4}
                      value={actualHour}
                      type={'text'}
                      onChange={setActualHour}
                      placeholder="0.00"
                      onBlur={blurred}
                      onCompositionStart={() => setIsComposing(true)}
                      onCompositionEnd={(e: { target: HTMLInputElement }) => {
                        setIsComposing(false)
                        hourChanged(e.target.value)
                      }}
                    />
                  </StyledTableRow>
                  <StyledTableRow key="user">
                    <RowHeaderCell align="left">
                      {intl.formatMessage({
                        id: 'taskActualWorkList.header.user',
                      })}
                    </RowHeaderCell>
                    <FilterInputCell
                      colSpan={4}
                      value={user?.name}
                      type={'text'}
                      disabled={true}
                    />
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            {intl.formatMessage({ id: 'dialog.close' })}
          </Button>
          <CircularButton
            variant="contained"
            color="primary"
            title={intl.formatMessage({ id: 'dialog.register' })}
            onClick={submit}
          />
        </DialogActions>
      </Dialog>
    )
  }
)

export default injectIntl(withKeyBind(TaskActualWorkRegistrationDialog))
