import _ from 'lodash'
import { useCallback, useState } from 'react'
import {
  DateVO,
  dateVoService,
} from '../../../../../../domain/value-object/DateVO'
import { intl } from '../../../../../../i18n'
import { DateFilterOperator } from './index'

export const useDateFilter = (): {
  operator: DateFilterOperator
  setOperator: (_: DateFilterOperator) => void
  value: DateVO | undefined
  setValue: (_: DateVO | undefined) => void
  isActive: () => boolean
  model: () => { operator: DateFilterOperator; value: string } | undefined
  reset: () => void
  getModelAsString: () => string
  getLabel: (opeartor: DateFilterOperator) => string
} => {
  const [operator, setOperator] = useState<DateFilterOperator>(
    DateFilterOperator.EQUALS
  )
  const [value, setValue] = useState<DateVO>()

  const reset = useCallback(() => {
    setOperator(DateFilterOperator.EQUALS)
    setValue(undefined)
  }, [])

  const isActive = useCallback(
    () => operator === DateFilterOperator.BLANK || !!value,
    [operator, value]
  )

  const model = useCallback(() => {
    if (isActive()) {
      return {
        operator: operator,
        value: value ? dateVoService.format(value) : '',
      }
    }
    return undefined
  }, [operator, value])

  const getModelAsString = useCallback(() => {
    if (!isActive()) return ''
    if (operator === DateFilterOperator.BLANK) {
      return `(${operator.charAt(0)}${_.lowerCase(operator.slice(1))})`
    }
    return `${
      operator === DateFilterOperator.EQUALS
        ? '='
        : operator === DateFilterOperator.AFTER
        ? '≥'
        : operator === DateFilterOperator.BEFORE
        ? '≤'
        : ''
    } ${value && dateVoService.format(value, 'YYYY/MM/DD')}`
  }, [operator, value])

  const getLabel = useCallback((operator: DateFilterOperator) => {
    switch (operator) {
      case DateFilterOperator.EQUALS:
        return intl.formatMessage({
          id: 'bulksheet.filter.dateFilter.operator.equal',
        })
      case DateFilterOperator.BLANK:
        return intl.formatMessage({
          id: 'bulksheet.filter.dateFilter.operator.notSet',
        })
      case DateFilterOperator.AFTER:
        return intl.formatMessage({
          id: 'bulksheet.filter.dateFilter.operator.greaterThanEqual',
        })
      case DateFilterOperator.BEFORE:
        return intl.formatMessage({
          id: 'bulksheet.filter.dateFilter.operator.lessThanEqual',
        })
      default:
        return ''
    }
  }, [])

  return {
    operator,
    setOperator,
    value,
    setValue,
    isActive,
    model,
    reset,
    getModelAsString,
    getLabel,
  }
}
