// Ag grid options
import {
  CellKeyDownEvent,
  GridOptions,
  ProcessCellForExportParams,
  RowNode,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import _ from 'lodash'
import {
  AggregateField,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
  processDataFromClipboard,
} from '../../../containers/commons/AgGrid'
import {
  ServerSideTextFilter,
  TextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import { ServerSideTextFloatingFilter } from '../../../containers/BulkSheetView/components/floatingFilter'
import { filter, ProjectPlanNewRow } from '../projectPlanNew'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import { ProjectPlanTreeCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemName/projectPlanTreeCellRenderer'
import {
  ApplicationFunctionPropertyConfiguration,
  CustomEnumValue,
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { SprintDetail } from '../../../../lib/functions/sprint'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import DateTimeVO from '../../../../vo/DateTimeVO'
import {
  FlagxsColumnDef,
  mergeExtensionColumns,
} from '../../../containers/BulkSheetView/gridOptions/extension'
import { normalize } from '../../../../utils/multilineText'
import BoolExpression from '../../../../utils/boolExpression'
import { MutableRefObject, useMemo, useState } from 'react'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import useDefaultColumns from './defaultColumns'
import { overwriteColumnByPropertyConfiguration } from '../../../containers/BulkSheetView/gridOptions/propertyConfiguration'
import { useWbsItemAdditionalPropertyColumns } from '../../../containers/BulkSheetView/hooks/wbsItemAdditionalProperties'
import {
  wbsItemAdditionalPropertyValuesVoService,
  WbsItemAdditionalPropertyValueType,
} from '../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { WbsItemAdditionalPropertyEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { excelStyles } from '../../../containers/BulkSheet/excel'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { DISPLAY_DATE_FORMAT } from '../../../../utils/date'

export const useProjectPlanNewGridOptions = ({
  projectUuid,
  switchRootWbsItem,
  refreshWbsItem,
  submitSingleRow,
  updateRows,
  addRowToChild,
  extensionProperties,
  propertyConfigurations,
  wbsItemAdditionalProperties,
  onChangeRowHeight,
  openWbsDetailDialog,
  closeWbsDetailDialog,
}: {
  projectUuid: string
  switchRootWbsItem: (event: MouseEvent, rootProjectPlanUuid?: string) => void
  refreshWbsItem: (uuid: string) => void
  submitSingleRow: MutableRefObject<
    ((uuid: string) => Promise<boolean | undefined>) | undefined
  >
  updateRows?: (rows: ProjectPlanNewRow[]) => void
  addRowToChild: (type: WbsItemTypeVO, parentUuid: string) => void
  extensionProperties: FunctionProperty[]
  propertyConfigurations: ApplicationFunctionPropertyConfiguration[]
  wbsItemAdditionalProperties?: WbsItemAdditionalPropertyLayoutEntity
  onChangeRowHeight?: (value: number) => void
  openWbsDetailDialog?: () => void
  closeWbsDetailDialog?: () => void
}): GridOptions => {
  const columnDefs = useDefaultColumns({
    projectUuid,
    switchRootWbsItem,
    updateRows,
    addRowToChild,
    onChangeRowHeight,
  }) as FlagxsColumnDef[]
  const wbsItemAdditionalPropertyColumns =
    useWbsItemAdditionalPropertyColumns<ProjectPlanNewRow>(
      projectUuid,
      wbsItemAdditionalProperties,
      {
        getAdditionalPropertyValues: (rowData: ProjectPlanNewRow) =>
          rowData.body?.wbsItem.additionalPropertyValues,
        setAdditionalPropertyValues: (
          rowNode: RowNode<ProjectPlanNewRow>,
          wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
          oldValue: WbsItemAdditionalPropertyValueType,
          newValue: WbsItemAdditionalPropertyValueType
        ) => {
          const rowData = rowNode.data
          if (!rowData || !rowData.body) {
            return
          }
          let newAdditionalPropertyValues =
            rowData.body.wbsItem.additionalPropertyValues
          if (!newAdditionalPropertyValues) {
            newAdditionalPropertyValues =
              wbsItemAdditionalPropertyValuesVoService.construct()
          }
          wbsItemAdditionalPropertyValuesVoService.setValue(
            newAdditionalPropertyValues,
            wbsItemAdditionalProperty.uuid,
            newValue
          )

          rowData.body.wbsItem.additionalPropertyValues =
            newAdditionalPropertyValues
          rowData.edited = true
          if (!rowData.editedData) {
            rowData.editedData = {}
          }
          let oldAdditionalPropertyValues =
            rowData.editedData.additionalPropertyValues
          if (!oldAdditionalPropertyValues) {
            oldAdditionalPropertyValues =
              wbsItemAdditionalPropertyValuesVoService.construct()
          }
          wbsItemAdditionalPropertyValuesVoService.setValue(
            oldAdditionalPropertyValues,
            wbsItemAdditionalProperty.uuid,
            oldValue
          )
          rowNode.setData(rowData)
        },
        getWbsItemType: (rowData: ProjectPlanNewRow) =>
          rowData.body?.wbsItem.wbsItemType,
        columnFilterType: 'SERVER_SIDE',
      }
    )

  const gridOptions = useMemo(
    () => {
      mergeExtensionColumns({
        projectUuid,
        defaultColumns: columnDefs,
        extensionProperties,
        propertyConfigurations,
      })
      overwriteColumnByPropertyConfiguration({
        columns: columnDefs,
        propertyConfigurations,
      })
      columnDefs.push(
        ...(wbsItemAdditionalPropertyColumns as FlagxsColumnDef[])
      )

      const displayNameUiMeta = {
        propertyType: PropertyType.Text,
        maxLength: 256,
        requiredIf: BoolExpression.of(true),
      } as Partial<FunctionProperty>

      return {
        // Grid context. Define grid specific properties
        context: {
          aggregateTarget: WbsItemType.DELIVERABLE,
          aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
        },
        /**
         * contents below could be commonized.
         */
        // Styling
        groupHeaderHeight: 25,
        headerHeight: 45,
        rowHeight: 32,
        rowClassRules: {
          'ag-row-copied-or-selected-row': p =>
            p.context.copied?.some(v => v.id === p.node.id),
          'ag-row-copied-single-row': p =>
            p.context.copied?.some(
              v => v.id === p.node.id && v.place === 'both'
            ),
          'ag-row-copied-start-row': p =>
            p.context.copied?.some(
              v => v.id === p.node.id && v.place === 'top'
            ),
          'ag-row-copied-middle-row': p =>
            p.context.copied?.some(
              v => v.id === p.node.id && v.place === 'middle'
            ),
          'ag-row-copied-end-row': p =>
            p.context.copied?.some(
              v => v.id === p.node.id && v.place === 'bottom'
            ),
        },
        // Row grouping
        treeData: true,
        excludeChildrenWhenTreeDataFiltering: true,
        autoGroupColumnDef: {
          field: 'body.wbsItem.displayName',
          headerName: intl.formatMessage({ id: 'projectPlan.displayName' }),
          width: 400,
          pinned: true,
          cellRendererParams: {
            suppressCount: true,
            suppressDoubleClickExpand: true,
            innerRenderer: ProjectPlanTreeCellRenderer,
            onCloseWbsItemDetail: wbsItem => {
              refreshWbsItem(wbsItem.uuid)
              closeWbsDetailDialog && closeWbsDetailDialog()
            },
            beforeOpenWbsItemDetail: async uuid => {
              const success = await submitSingleRow.current?.(uuid)
              return success
            },
            onOpenWbsItemDetail: () => {
              openWbsDetailDialog && openWbsDetailDialog()
            },
            uiMeta: displayNameUiMeta,
          },
          editable: true,
          cellEditorParams: {
            uiMeta: displayNameUiMeta,
          },
          filter: ServerSideTextFilter,
          floatingFilter: true,
          floatingFilterComponent: ServerSideTextFloatingFilter,
          filterParams: {
            fetch: (v: TextFilter) => {
              return filter({ projectUuid, displayName: v })
            },
          },
          valueGetter: (params: ValueGetterParams) => {
            return params.data.body?.wbsItem?.displayName ?? ''
          },
          cellClassRules: {
            'hover-over-can-drop': params => {
              return (
                params.context?.onTree &&
                params.context?.draggableNodeId &&
                params.node?.id === params.context.draggableNodeId
              )
            },
          },
        },
        // Row actions
        rowDragManaged: false,
        rowDragMultiRow: true,
        suppressMoveWhenRowDragging: true,
        enterMovesDownAfterEdit: true,
        onCellKeyDown: (params: CellKeyDownEvent) => {
          // @ts-ignore
          if (!['Delete', 'Backspace'].includes(params.event.key)) return
          const cellRanges = params.api.getCellRanges() || []
          cellRanges.forEach(range => {
            const start = Math.min(
              range.startRow!.rowIndex,
              range.endRow!.rowIndex
            )
            const end = Math.max(
              range.startRow!.rowIndex,
              range.endRow!.rowIndex
            )
            range.columns.forEach(column => {
              const colDef = column.getColDef()
              if (
                typeof colDef.editable === 'function'
                  ? colDef.editable(params)
                  : colDef.editable
              ) {
                for (let i = start; i <= end; i++) {
                  const rowNode = params.api.getDisplayedRowAtIndex(i)
                  rowNode?.setDataValue(column, undefined)
                }
              }
            })
          })
        },
        // Column definition
        columnTypes: columnTypes(),
        components: frameworkComponents,
        defaultColDef: {
          width: 150,
          editable: false,
          enableValue: false,
          sortable: true,
          resizable: true,
          suppressMenu: true,
          menuTabs: ['filterMenuTab', 'generalMenuTab'],
          suppressSizeToFit: true,
          singleClickEdit: false,
          cellEditor: 'textEditor',
          cellRenderer: DefaultCellRenderer,
          onCellClicked: defaultOnCellClicked,
          suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
            // When editing in Japanese, any keyboard event should be suppressed.
            if (params.editing && params.event.isComposing) {
              return true
            }
            // Prevent editing cell for DELETE or BACKSPACE
            return (
              !params.editing &&
              ['Delete', 'Backspace'].includes(params.event.key)
            )
          },
          valueSetter: (params: ValueSetterParams) => {
            const field = params.colDef.field || params.colDef.colId
            if (
              !field ||
              (!params.oldValue && !params.newValue) ||
              params.oldValue === params.newValue
            ) {
              return false
            }
            objects.setValue(params.data, field, params.newValue)
            params.data.edited = true
            if (!params.data.editedData) {
              params.data.editedData = { [field]: params.oldValue }
            } else if (!params.data.editedData.hasOwnProperty(field)) {
              params.data.editedData[field] = params.oldValue
            }

            store.dispatch(requireSave())
            return true
          },
          cellClassRules: {
            'grid-edited-cell': params => {
              const { colDef, data, value } = params
              const field = colDef.field ?? colDef.colId
              if (
                !data.editedData ||
                !field ||
                !colDef.editable ||
                (typeof colDef.editable === 'function' &&
                  !colDef.editable(params))
              ) {
                return false
              }
              return (
                data.editedData.hasOwnProperty(field) &&
                value !== data.editedData[field]
              )
            },
          },
        },
        columnDefs,
        getRowStyle: params => {
          const w = params.data.body?.wbsItem
          if (w && !w.wbsItemType.isTask()) {
            return { fontWeight: 'bold' }
          }
        },
        // Copy & paste
        processCellForClipboard,
        processCellFromClipboard,
        processDataFromClipboard,
        // Footer
        statusBar: {
          statusPanels: [
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left',
            },
            {
              statusPanel: 'agAggregationComponent',
              statusPanelParams: {
                aggFuncs: ['sum', 'count'],
              },
              align: 'left',
            },
          ],
        },
        localeText: {
          sum: intl.formatMessage({
            id: 'bulksheet.statusPanel.sum',
          }),
          count: intl.formatMessage({
            id: 'bulksheet.statusPanel.count',
          }),
          totalAndFilteredRows: intl.formatMessage({
            id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
          }),
          of: intl.formatMessage({
            id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
          }),
        },
        excelStyles: excelStyles,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return gridOptions as GridOptions
}

export const processCellForClipboard = ({
  column,
  value,
  node,
  context,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  if (!value) return value
  if (['action', 'body.deliverableAttachmentSummary'].includes(field!)) {
    return ''
  }
  if (field === 'body.wbsItem.type') {
    return value?.name
  }
  if (field === 'body.wbsItem.ticketType') {
    const wbsItemType = node?.data?.body?.wbsItem.wbsItemType
    return wbsItemType?.isTicket() ? wbsItemType?.name : ''
  }
  if (field === 'body.wbsItem.description') {
    return `"${normalize(value ?? '')}"`
  }
  if (field === 'body.commentSummary.latestComment') {
    return value?.text
  }
  if (field === 'body.wbsItem.watchers') {
    return value?.map(v => v.name).join(',')
  }
  if (field === 'body.wbsItem.tags') {
    return value?.map(v => v.name).join(TAG_DELIMITER)
  }
  if (!type) {
    if (typeof value === 'object') {
      return value.name ?? value.displayName
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    return (
      getLabel(value?.nameI18n) ||
      value?.name ||
      value?.displayName ||
      value?.officialName
    )
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const option = options.find(v => v.value === value)
    return getLabel(option?.nameI18n) || option?.name || ''
  }
  if (type.includes(ColumnType.date)) {
    const dateVO = dateVoService.construct(value)
    return dateVoService.isValid(dateVO)
      ? dateVoService.format(dateVO, DISPLAY_DATE_FORMAT)
      : ''
  }
  if (type.includes(ColumnType.dateTime)) {
    return new DateTimeVO(value).format()
  }
  if (type.includes(ColumnType.multiLineText)) {
    return `"${normalize(value ?? '')}"`
  }
  return value
}

export const processCellFromClipboard = ({
  column,
  value,
  context,
  node,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  if (!value) return value
  if (field === 'body.wbsItem.watchers') {
    const names = value.split(',').filter(v => !!v)
    const options = context['member']
    return options.filter(o => names.includes(o.name))
  }
  if (field === 'body.wbsItem.sprint') {
    const team = node?.data?.body?.wbsItem.team
    if (!team) return undefined
    return context.sprint?.find(
      (v: SprintDetail) => v.teamUuid === team.uuid && v.name === value
    )
  }
  if (field === 'body.wbsItem.tags') {
    const names = value.split(TAG_DELIMITER).filter(v => !!v)
    const options = context['tag'] ?? []
    return options.filter(v => names.includes(v.name))
  }
  if (!type) return value
  if (type.includes(ColumnType.autocomplete)) {
    if (cellEditorParams.entity) {
      const options = context[cellEditorParams.entity]
      return options.find(o =>
        [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
          .filter(v => !!v)
          .includes(value)
      )
    }
    return value
  }
  if (type.includes(ColumnType.customEnum)) {
    const options: CustomEnumValue[] =
      context[cellEditorParams.customEnumCode] ?? []
    const target = options.find(o =>
      [getLabel(o.nameI18n), o.name].filter(v => !!v).includes(value)
    )
    return target?.value
  }
  return value
}
