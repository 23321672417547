import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { GridOptions } from 'ag-grid-community'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import store from '../../../../store'
import { BaseWbsItemType } from '../../../../store/project'
import {
  focusRow,
  getSelectedNode,
} from '../../../containers/BulkSheetView/lib/gridApi'
import { ButtonType } from '../components/ActionPopper'
import { NewWbsItemRow, ProjectPlanNewRow } from '../projectPlanNew'

export const useActionPopper = ({
  gridOptions,
  wbsItemTypes,
  addRowAbove,
  addRowBelow,
  addToChildRow,
}: {
  gridOptions: GridOptions
  wbsItemTypes: BaseWbsItemType
  addRowAbove: (type: WbsItemTypeVO, ticketListUuid?: string) => void
  addRowBelow: (type: WbsItemTypeVO, ticketListUuid?: string) => void
  addToChildRow: (
    type: WbsItemTypeVO,
    ticketListUuid?: string
  ) => ProjectPlanNewRow | undefined
}) => {
  const [offsetX, setOffsetX] = useState<number>(0)
  const [offsetY, setOffsetY] = useState<number>(0)
  const [anchor, setAnchor] = useState<HTMLElement | undefined>()
  const [visibleButtonType, setVisibleButtonType] = useState<
    ButtonType[] | undefined
  >(undefined)

  const open = useCallback(
    ({
      offsetX,
      offsetY,
      anchor,
      visibleButtonType,
    }: {
      offsetX: number
      offsetY: number
      anchor: HTMLElement
      visibleButtonType?: ButtonType[] | undefined
    }) => {
      setOffsetX(offsetX)
      setOffsetY(offsetY)
      setAnchor(anchor)
      setVisibleButtonType(visibleButtonType)
    },
    []
  )

  const close = useCallback(() => {
    setAnchor(undefined)
  }, [])

  const getSelectedRowHeight = useCallback(() => {
    if (!gridOptions?.api) return
    const selectedNodes = getSelectedNode(gridOptions?.api)
    return !_.isEmpty(selectedNodes)
      ? selectedNodes[0].rowHeight ?? undefined
      : undefined
  }, [gridOptions?.api])

  const getSelectedRowDataForActionPopper = useCallback(() => {
    if (!gridOptions?.api) return {}
    const selectedNodes = getSelectedNode(gridOptions.api)
    if (_.isEmpty(selectedNodes)) return {}
    const row: ProjectPlanNewRow = selectedNodes[0].data
    const wbsItem: NewWbsItemRow | undefined = row?.body?.wbsItem
    return {
      wbsItemType: wbsItem?.baseWbsItemType,
      ticketType: wbsItem?.ticketType,
      ticketListUuid:
        row.body?.ticketCumulation?.ticketListUuid ?? wbsItem?.ticketListUuid,
    }
  }, [gridOptions?.api])

  const onClickButtonAddAbove = useCallback(() => {
    const {
      wbsItemType: selectedRowType,
      ticketType,
      ticketListUuid,
    } = getSelectedRowDataForActionPopper()
    if (!selectedRowType) return

    const addRowType = selectedRowType.isTicket()
      ? store
          .getState()
          .project.ticketTypes.find(type => type.code === ticketType)
      : selectedRowType

    if (!addRowType) return
    addRowAbove(
      addRowType,
      selectedRowType.isTicket() ? ticketListUuid : undefined
    )
  }, [getSelectedRowDataForActionPopper, addRowAbove])

  const onClickButtonAddBelow = useCallback(() => {
    const {
      wbsItemType: selectedRowType,
      ticketType,
      ticketListUuid,
    } = getSelectedRowDataForActionPopper()
    if (!selectedRowType) return

    const addRowType = selectedRowType.isTicket()
      ? store
          .getState()
          .project.ticketTypes.find(type => type.code === ticketType)
      : selectedRowType

    if (!addRowType) return
    addRowBelow(
      addRowType,
      selectedRowType.isTicket() ? ticketListUuid : undefined
    )
  }, [getSelectedRowDataForActionPopper, addRowBelow])

  const onClickButtonAddChild = useCallback(() => {
    const {
      wbsItemType: selectedRowType,
      ticketType,
      ticketListUuid,
    } = getSelectedRowDataForActionPopper()
    if (!selectedRowType) return

    const addRowType =
      selectedRowType.isTicket() && selectedRowType.isDeliverable()
        ? store
            .getState()
            .project.ticketTypes.find(type => type.code === ticketType)
        : selectedRowType.isWorkgroup()
        ? wbsItemTypes.process
        : selectedRowType.isProcess()
        ? wbsItemTypes.deliverableList
        : selectedRowType.isDeliverableList()
        ? wbsItemTypes.deliverable
        : selectedRowType.isDeliverable()
        ? wbsItemTypes.task
        : selectedRowType.isTask()
        ? wbsItemTypes.task
        : undefined

    if (!addRowType) return
    const newRow = addToChildRow(
      addRowType,
      selectedRowType.isTicket() && selectedRowType.isDeliverable()
        ? ticketListUuid
        : undefined
    )
    newRow && gridOptions?.api && focusRow(gridOptions.api, newRow.uuid)
  }, [
    gridOptions,
    wbsItemTypes,
    getSelectedRowDataForActionPopper,
    addToChildRow,
  ])

  const onCellValueChanged = useCallback(() => {
    !!anchor && close()
  }, [anchor, close])

  const returnVal = useMemo(
    () => ({
      offsetX,
      offsetY,
      anchor,
      visibleButtonType,
      open,
      close,
      isOpen: !!anchor,
      getSelectedRowHeight,
      onCellValueChanged,
      onClickButtonAddAbove,
      onClickButtonAddBelow,
      onClickButtonAddChild,
    }),
    [
      offsetX,
      offsetY,
      anchor,
      visibleButtonType,
      open,
      close,
      getSelectedRowHeight,
      onCellValueChanged,
      onClickButtonAddAbove,
      onClickButtonAddBelow,
      onClickButtonAddChild,
    ]
  )
  return returnVal
}
