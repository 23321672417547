import {
  CellClassParams,
  CellStyle,
  EditableCallbackParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import _ from 'lodash'
import {
  WbsItemStatus,
  AggregateField,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import { ColumnType } from '../../../containers/commons/AgGrid'
import {
  BoolFilter,
  CommentCreatedAtFilter,
  CommentTextFilter,
  DateFilter,
  NumberFilter,
  SelectFilter,
  ServerSideBoolFilter,
  ServerSideCommentFilter,
  ServerSideDateFilter,
  ServerSideNumberFilter,
  ServerSideSelectFilter,
  ServerSideTextFilter,
  ServerSideUuidFilter,
  ServerSideWbsItemTypeFilter,
  TextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import { ServerSideTextFloatingFilter } from '../../../containers/BulkSheetView/components/floatingFilter'
import { filter, NewWbsItemRow, ProjectPlanNewRow } from '../projectPlanNew'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import {
  CustomEnumCellEditor,
  DateCellEditor,
  EntitySearchCellEditor,
} from '../../../containers/BulkSheetView/components/cellEditor'
import {
  getWbsItemStatusColorCode,
  getWbsItemTreeStatusColorCode,
} from '../../../../lib/functions/wbsItem'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { ProjectPlanSequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/ProjectPlanSequenceNoCellRenderer'
import { filterValuesByCombination } from '../../../../lib/commons/customEnum'
import ProjectMember, {
  ProjectMemberProps,
} from '../../../../lib/functions/projectMember'
import {
  AttachmentCellRenderer,
  CustomEnumCellRenderer,
  EntitySearchCellRenderer,
  ProjectPlanActionCellRenderer,
  ProjectPlanStatusCellRenderer,
  TicketTypeCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import Sprint, { SprintStatus } from '../../../../lib/functions/sprint'
import MultiLineTextCell from '../../../containers/commons/AgGrid/components/cell/custom/multiLineText'
import { Progress, ProgressDetail, ReportReCalculator } from './cumulation'
import {
  BackgroundColor,
  FontWeight,
  TextColor,
} from '../../../../styles/commonStyles'
import DateVO from '../../../../vo/DateVO'
import { customHeaderTemplate } from '../../../containers/commons/AgGrid/components/header/CustomHeader'
import { toInteger, toNumber } from '../../../../utils/number'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import Team from '../../../../lib/functions/team'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { IDateCellEditorParams } from '../../../containers/BulkSheetView/components/cellEditor/DateCellEditor'
import { useCallback, useMemo } from 'react'
import {
  useCode,
  useDescription,
  useEstimatedStoryPoint,
  useEstimatedWorkLoadDeliverable,
  useEstimatedWorkLoadTask,
  useLatestComment,
  useTags,
  useWatchers,
} from '../../../page-properties/bulksheet-properties/wbsItem'
import { getTagFilterOptionUuids } from '../../../../lib/functions/tag'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import { ProjectPlanNewActualHourCellRenderer } from '../components/CellRenderer/ProjectPlanNewActualHourCellRenderer'
import { DragCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/DragCellRenderer'

export const FIELD_WBSITEM_ACTUALDATE_START: string =
  'body.wbsItem.actualDate.startDate'
export const FIELD_WBSITEM_ACTUALDATE_END: string =
  'body.wbsItem.actualDate.endDate'
export const FIELD_WBSITEM_ACTUALHOUR: string = 'body.cumulation.actualHour'

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'body.wbsItem.type'
    case 'TICKET_TYPE':
      return 'body.wbsItem.ticketType'
    case 'WBS_STATUS':
      return 'body.wbsItem.status'
    case 'WBS_SUBSTATUS':
      return 'body.wbsItem.substatus'
  }
  return undefined
}

const cumulationSourceValueSetter = (
  wbsItemValueSetter: (params: ValueSetterParams) => boolean,
  updateRows?: (rows: ProjectPlanNewRow[], report?: boolean) => void
) => {
  return (params: ValueSetterParams<ProjectPlanNewRow>) => {
    if (params.oldValue === params.newValue || !params.data.body?.wbsItem) {
      return false
    }

    const before = _.cloneDeep(params.data)
    if (!wbsItemValueSetter(params)) return false
    params.data.edited = true
    const field = params.colDef.field ?? params.colDef.colId ?? ''
    if (!params.data.editedData) {
      params.data.editedData = { [field]: params.oldValue }
    } else if (!params.data.editedData.hasOwnProperty(field)) {
      params.data.editedData[field] = params.oldValue
    }
    const after = params.data

    // Update ancestor cumulations
    const ancestors = before.treeValue
      .slice(0, -1)
      .map(id => params.api.getRowNode(id)?.data)
      .filter(v => v) as ProjectPlanNewRow[]
    updateRows?.(ReportReCalculator.update(before, after, ancestors), true)
    params.api.refreshCells({ columns: ['body.wbsItem.status'], force: true })

    store.dispatch(requireSave())
    return true
  }
}

const today = DateVO.now()
const isStartDelayed = (wbsItem?: NewWbsItemRow): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledStart = scheduledDate?.startDate
    ? new DateVO(scheduledDate.startDate)
    : undefined
  const actualStart = actualDate?.startDate
    ? new DateVO(actualDate.startDate)
    : undefined
  return !!(
    scheduledStart &&
    wbsItem.status === WbsItemStatus.TODO &&
    ((!actualStart && today.isAfter(scheduledStart)) ||
      (actualStart && actualStart.isAfter(scheduledStart)))
  )
}

const isEndDelayed = (wbsItem?: NewWbsItemRow): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledEnd = scheduledDate?.endDate
    ? new DateVO(scheduledDate.endDate)
    : undefined
  const actualEnd = actualDate?.endDate
    ? new DateVO(actualDate.endDate)
    : undefined
  return !!(
    scheduledEnd &&
    ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(wbsItem.status) &&
    ((!actualEnd && today.isAfter(scheduledEnd)) ||
      (actualEnd && actualEnd.isAfter(scheduledEnd)))
  )
}

const cumulationCellType = {
  width: 80,
  editable: false,
  hide: true,
  cellStyle: (params: CellClassParams<ProjectPlanNewRow>) => {
    const type = params.data?.body?.wbsItem?.wbsItemType
    const aggregateTarget = params.context.aggregateTarget
    const isDetailRow =
      (type?.isDeliverable() && aggregateTarget === WbsItemType.DELIVERABLE) ||
      (type?.isTask() && aggregateTarget === WbsItemType.TASK)
    return {
      fontWeight: isDetailRow ? FontWeight.NORMAL : FontWeight.BOLD,
      color: TextColor.DARK_BLACK,
      justifyContent: 'flex-end',
    }
  },
}
const percentageValueFormatter = (
  params: ValueFormatterParams<ProjectPlanNewRow>
) => {
  const type = params.data?.body?.wbsItem?.wbsItemType
  if (!type) return ''
  const { aggregateTarget } = params.context
  if (type.isTask() && aggregateTarget === WbsItemType.DELIVERABLE) return ''
  const value: number | undefined = params.value
  if (
    (type.isDeliverable() && aggregateTarget === WbsItemType.DELIVERABLE) ||
    (type.isTask() && aggregateTarget === WbsItemType.TASK)
  ) {
    return ' -'
  }
  if (
    type.isWorkgroup() ||
    type.isProcess() ||
    type.isDeliverableList() ||
    (type.isDeliverable() && aggregateTarget === WbsItemType.TASK)
  ) {
    return value === undefined ? '-' : `${(value * 100).toFixed(1)}%`
  }
  return ''
}
const isProgressDetailRow = (
  params: Partial<ValueGetterParams<ProjectPlanNewRow>>
): boolean => {
  const type = params.data?.body?.wbsItem?.wbsItemType
  const { aggregateTarget } = params.context
  if (!type || !aggregateTarget) return false
  return (
    (type.isDeliverable() && aggregateTarget === WbsItemType.DELIVERABLE) ||
    (type.isTask() && aggregateTarget === WbsItemType.TASK)
  )
}

const progressValueFormatter = (
  params: ValueFormatterParams<ProjectPlanNewRow>
) => {
  const type = params.data?.body?.wbsItem?.wbsItemType
  if (!type) return ''
  const { aggregateTarget, aggregateField } = params.context
  if (type.isTask() && aggregateTarget === WbsItemType.DELIVERABLE) return ''
  const value: number | undefined = params.value
  const isDetailRow = isProgressDetailRow(params)
  if (aggregateField === AggregateField.WBS_ITEM_WORKLOAD) {
    if (value === undefined) return ''
    return (
      (isDetailRow ? ' ' : '') +
      (
        value / (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
      ).toFixed(1)
    )
  }
  return (isDetailRow ? ' ' : '') + (value?.toString() ?? '')
}
const evmValueFormatter = (params: ValueFormatterParams<ProjectPlanNewRow>) => {
  const type = params.data?.body?.wbsItem?.wbsItemType
  if (!type) return ''
  const { aggregateTarget } = params.context
  if (type.isTask()) {
    return aggregateTarget === WbsItemType.DELIVERABLE ? '' : '-'
  }
  if (type.isDeliverable() && aggregateTarget === WbsItemType.DELIVERABLE) {
    return '-'
  }
  const value: number | undefined = params.value
  const isDetailRow = isProgressDetailRow(params)
  if (value === undefined) return '-'
  return (
    (isDetailRow ? ' ' : '') +
    (
      value / (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
    ).toFixed(1)
  )
}
const getRate = (numerator: number, denominator?: number) => {
  if (!denominator) return undefined
  return numerator / denominator
}

const deliverableEstimatedAmount = (
  data?: ProjectPlanNewRow
): number | undefined => {
  const w = data?.body?.wbsItem
  const c = data?.body?.cumulation
  if (!w) return
  if (w.wbsItemType?.isTask()) return
  if (w.wbsItemType?.isDeliverable()) {
    return w.estimatedAmount ?? 0
  }
  return c?.sumDeliverableEstimatedAmount
}
const taskEstimatedAmount = (data?: ProjectPlanNewRow): number | undefined => {
  const w = data?.body?.wbsItem
  const c = data?.body?.cumulation
  if (!w) return
  if (w.wbsItemType?.isTask()) return w.estimatedAmount || 0
  return c?.sumTaskEstimatedAmount
}

const actualAmount = (data?: ProjectPlanNewRow) => {
  const w = data?.body?.wbsItem
  const c = data?.body?.cumulation
  if (!w) return
  if (w.wbsItemType?.isTask()) return w.actualAmount ?? 0
  return c?.sumTaskActualAmount
}

const customEnumValueSetter = (params: ValueSetterParams) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field || params.oldValue === params.newValue) return false
  const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
  const options: CustomEnumValue[] = params.context[customEnumCode]

  const customEnumValue = options.find(o => o.value === params.newValue)
  const bidirection = customEnumValue?.combinations?.find(
    v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
  )
  objects.setValue(params.data, field, params.newValue)
  if (bidirection && combinedValuePath) {
    objects.setValue(
      params.data,
      combinedValuePath?.(bidirection.combinedEnumCode),
      bidirection.combinedValues?.[0]?.value
    )
  }
  params.data.edited = true
  if (!params.data.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

const useDefaultColumns = ({
  projectUuid,
  switchRootWbsItem,
  updateRows,
  addRowToChild,
  onChangeRowHeight,
}: {
  projectUuid: string
  switchRootWbsItem: (event: MouseEvent, rootProjectPlanUuid?: string) => void
  updateRows?: (rows: ProjectPlanNewRow[]) => void
  addRowToChild: (type: WbsItemTypeVO, parentUuid: string) => void
  onChangeRowHeight?: (value: number) => void
}) => {
  const getWbsItemProjectUuid = useCallback(
    (row: ProjectPlanNewRow | undefined) => row?.body?.wbsItem?.projectUuid,
    []
  )
  const getWbsItemType = useCallback(
    (row: ProjectPlanNewRow | undefined) => row?.body?.wbsItem?.wbsItemType,
    []
  )

  const codeCol = useCode('body.wbsItem.code')

  const commentColDef = {
    externalId: 'projectPlan.new.commentSummary.latestComment',
    ...useLatestComment('body.commentSummary.latestComment'),
    filter: ServerSideCommentFilter,
    floatingFilter: true,
    filterParams: {
      fetch: (t?: CommentTextFilter, d?: CommentCreatedAtFilter) => {
        return filter({
          projectUuid,
          commentText: t,
          commentCreatedAt: d,
        })
      },
    },
  }

  // Basic information group column common def
  const tagsCol = useTags({
    field: 'body.wbsItem.tags',
    getWbsItemProjectUuid,
  })

  const descriptionCol = useDescription('body.wbsItem.description')

  // Assignee group column common def
  const watchersCol = useWatchers({
    field: 'body.wbsItem.watchers',
  })

  // Estimated group column common def
  const estimatedWorkLoadDeliverableCol = useEstimatedWorkLoadDeliverable({
    field: 'body.wbsItem.estimatedHour',
    getWbsItemType,
  })
  const estimatedWorkLoadTaskCol = useEstimatedWorkLoadTask({
    field: 'body.wbsItem.estimatedHour',
    getWbsItemType,
  })
  const estimatedStoryPointCol = useEstimatedStoryPoint(
    'body.wbsItem.estimatedStoryPoint'
  )

  const columns = useMemo(
    () => [
      {
        externalId: 'uuid',
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: ServerSideUuidFilter,
      },
      {
        externalId: 'drag',
        field: 'drag',
        headerName: '',
        type: [ColumnType.drag],
        // TODO: Consider disable while loading.
        rowDrag: _ => true,
        cellClassRules: {
          'hover-over-can-drop': params => {
            return (
              !params.context?.onTree &&
              params.context?.draggableNodeId &&
              params.node?.id === params.context?.draggableNodeId
            )
          },
        },
        cellRenderer: DragCellRenderer,
        cellRendererParams: {
          onChangeRowSize: (value: number) => {
            onChangeRowHeight && onChangeRowHeight(value)
          },
        },
      },
      {
        externalId: 'rowNumber',
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: ProjectPlanSequenceNoCellRenderer,
        cellRendererParams: {
          switchRootWbsItem,
          onChangeRowSize: (value: number) => {
            onChangeRowHeight && onChangeRowHeight(value)
          },
        },
        valueGetter: (params: ValueGetterParams) => getRowNumber(params.node),
      },
      {
        headerName: intl.formatMessage({ id: 'projectPlan.information' }),
        externalId: 'projectPlan.new.basic',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.code',
            ...codeCol,
            lockPosition: 'left',
            width: 90,
            filter: ServerSideTextFilter,
            floatingFilter: true,
            floatingFilterComponent: ServerSideTextFloatingFilter,
            filterParams: {
              fetch: (v: TextFilter) => {
                return filter({ projectUuid, code: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.type',
            field: 'body.wbsItem.type',
            headerName: intl.formatMessage({ id: 'projectPlan.type' }),
            hide: true,
            pinned: true,
            lockPosition: 'left',
            width: 90,
            valueGetter: (params: ValueGetterParams) =>
              params.data.body?.wbsItem.baseWbsItemType,
            valueFormatter: params =>
              params.data.body?.wbsItem?.baseWbsItemType?.getNameWithSuffix(),
            filter: ServerSideWbsItemTypeFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, type: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.ticketType',
            field: 'body.wbsItem.ticketType',
            headerName: intl.formatMessage({ id: 'projectPlan.ticketType' }),
            hide: true,
            pinned: true,
            lockPosition: 'left',
            width: 90,
            cellRenderer: TicketTypeCellRenderer,
            valueGetter: (params: ValueGetterParams) =>
              params.data.body?.wbsItem?.baseWbsItemType,
          },
          {
            externalId: 'projectPlan.new.wbsItem.status',
            field: 'body.wbsItem.status',
            headerName: intl.formatMessage({ id: 'projectPlan.status' }),
            pinned: true,
            lockPosition: 'left',
            width: 150,
            editable: true,
            type: [ColumnType.customEnum],
            cellEditor: CustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'status',
              combinedValuePath: statusCombinedValuePath,
            },
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>): boolean => {
                params.data.body!.wbsItem.status =
                  params.newValue ?? WbsItemStatus.TODO

                // Update substatus
                const { combinedValuePath } = params.colDef.cellEditorParams
                const substatusOptions = filterValuesByCombination(
                  params.context['substatus'],
                  code =>
                    objects.getValue(params.data, combinedValuePath?.(code)),
                  true
                )
                objects.setValue(
                  params.data,
                  'body.wbsItem.substatus',
                  substatusOptions?.[0]?.value
                )
                return true
              },
              updateRows
            ),
            cellRenderer: ProjectPlanStatusCellRenderer,
            cellStyle: params => {
              const style = {}
              const wbsItem: NewWbsItemRow = params.data.body?.wbsItem
              if (!wbsItem) return { ...style, backgroundColor: '#F7F7F7' }
              if (wbsItem.wbsItemType.isTask()) {
                return {
                  ...style,
                  backgroundColor: `${getWbsItemStatusColorCode(
                    wbsItem.status!
                  )}`,
                }
              }
              return {
                ...style,
                background: getWbsItemTreeStatusColorCode(wbsItem.status!),
              }
            },
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'status',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, status: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.substatus',
            field: 'body.wbsItem.substatus',
            headerName: intl.formatMessage({ id: 'projectPlan.substatus' }),
            pinned: true,
            hide: true,
            lockPosition: 'left',
            width: 90,
            editable: true,
            type: [ColumnType.customEnum],
            cellEditor: CustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'substatus',
              combinedValuePath: statusCombinedValuePath,
            },
            valueSetter: customEnumValueSetter,
            cellRenderer: CustomEnumCellRenderer,
            cellRendererParams: { customEnumCode: 'substatus' },
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'substatus',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, substatus: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.action',
            field: 'action',
            headerName: 'Action',
            pinned: true,
            lockPosition: 'left',
            width: 80,
            cellRenderer: ProjectPlanActionCellRenderer,
            cellRendererParams: {
              getCommentSummary: data => data?.body?.commentSummary,
              getWbsItem: data => data?.body?.wbsItem,
              addRowToChild,
            },
            valueGetter: (params: ValueGetterParams) =>
              params.data?.body?.commentSummary?.hasComment ||
              params.data?.body?.wbsItem?.uuid, // This valueGetter is to notify the action cell of the necessary that it needs to be refreshed.
            filter: ServerSideBoolFilter,
            floatingFilter: true,
            filterParams: {
              labelExist: intl.formatMessage({ id: 'openDetail.hasComment' }),
              labelNotExist: intl.formatMessage({ id: 'openDetail.noComment' }),
              fetch: (v: BoolFilter) => {
                return filter({ projectUuid, commentExist: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.deliverableAttachmentSummary',
            field: 'body.deliverableAttachmentSummary',
            headerName: intl.formatMessage({ id: 'projectPlan.attachments' }),
            pinned: true,
            lockPosition: 'left',
            width: 50,
            hide: true,
            cellRenderer: AttachmentCellRenderer,
            filter: ServerSideBoolFilter,
            floatingFilter: true,
            filterParams: {
              labelExist: intl.formatMessage({
                id: 'openDetail.hasAttachment',
              }),
              labelNotExist: intl.formatMessage({
                id: 'openDetail.noAttachment',
              }),
              fetch: (v: BoolFilter) => {
                return filter({ projectUuid, attachment: v })
              },
            },
          },
          {
            // Column for exporting excel
            externalId: 'projectPlan.new.wbsItem.displayName',
            field: 'body.wbsItem.displayName',
            headerName: intl.formatMessage({ id: 'projectPlan.displayName' }),
            hide: true,
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
          },
          {
            externalId: 'projectPlan.new.wbsItem.priority',
            field: 'body.wbsItem.priority',
            headerName: intl.formatMessage({ id: 'projectPlan.priority' }),
            width: 90,
            hide: true,
            pinned: true,
            editable: true,
            type: [ColumnType.customEnum],
            cellEditor: CustomEnumCellEditor,
            cellEditorParams: { customEnumCode: 'priority' },
            cellRenderer: CustomEnumCellRenderer,
            cellRendererParams: { customEnumCode: 'priority' },
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'priority',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, priority: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.difficulty',
            field: 'body.wbsItem.difficulty',
            headerName: intl.formatMessage({ id: 'projectPlan.difficulty' }),
            hide: true,
            width: 90,
            editable: true,
            pinned: true,
            type: [ColumnType.customEnum],
            cellEditor: CustomEnumCellEditor,
            cellEditorParams: { customEnumCode: 'difficulty' },
            cellRenderer: CustomEnumCellRenderer,
            cellRendererParams: { customEnumCode: 'difficulty' },
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'difficulty',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, difficulty: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.tags',
            ...tagsCol,
            filter: ServerSideSelectFilter,
            filterParams: {
              optionKey: 'tag',
              getValue: option => option.uuid,
              getLabel: option => option.name,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, tags: v })
              },
              getValuesForOptionFilter: async context => {
                const response = await getTagFilterOptionUuids({
                  projectUuid,
                  rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                })
                return response.json
              },
            },
            hide: true,
          },
        ],
      },
      {
        headerName: intl.formatMessage({
          id: 'projectPlan.description.comment',
        }),
        externalId: 'projectPlan.new.description.comment',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.description',
            ...descriptionCol,
            field: 'body.wbsItem.description',
            width: 90,
            filter: ServerSideTextFilter,
            floatingFilterComponent: ServerSideTextFloatingFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: TextFilter) => {
                return filter({ projectUuid, description: v })
              },
            },
          },
          commentColDef,
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectPlan.assignment' }),
        externalId: 'projectPlan.new.assignment',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.team',
            field: 'body.wbsItem.team',
            headerName: intl.formatMessage({ id: 'projectPlan.team' }),
            width: 65,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: { entity: 'team' },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'team',
              getValue: option => option.uuid,
              getLabel: option => option.displayName,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, team: v })
              },
              getValuesForOptionFilter: async context => {
                const response = await Team.getFilterOptionUuids({
                  projectUuid,
                  rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                })
                return response.json
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.accountable',
            field: 'body.wbsItem.accountable',
            headerName: intl.formatMessage({ id: 'projectPlan.accountable' }),
            width: 65,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'member',
              search: (text: string) =>
                ProjectMember.search(text, { projectUuid }),
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'member',
              getValue: option => option.uuid,
              getLabel: option => option.name,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, accountable: v })
              },
              getValuesForOptionFilter: async context => {
                const response =
                  await ProjectMember.getAccountableFilterOptionUuids({
                    projectUuid,
                    rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                  })
                return response.json
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.responsible',
            field: 'body.wbsItem.responsible',
            headerName: intl.formatMessage({ id: 'projectPlan.responsible' }),
            width: 65,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'member',
              search: (text: string) =>
                ProjectMember.search(text, { projectUuid }),
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'member',
              getValue: option => option.uuid,
              getLabel: option => option.name,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, responsible: v })
              },
              getValuesForOptionFilter: async context => {
                const response =
                  await ProjectMember.getResponsibleFilterOptionUuids({
                    projectUuid,
                    rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                  })
                return response.json
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.assignee',
            field: 'body.wbsItem.assignee',
            headerName: intl.formatMessage({ id: 'projectPlan.assignee' }),
            width: 65,
            hide: true,
            editable: true,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'member',
              search: (text: string) =>
                ProjectMember.search(text, { projectUuid }),
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'member',
              getValue: option => option.uuid,
              getLabel: option => option.name,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, assignee: v })
              },
              getValuesForOptionFilter: async context => {
                const response =
                  await ProjectMember.getAssigneeFilterOptionUuids({
                    projectUuid,
                    rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                  })
                return response.json
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.watchers',
            field: 'body.wbsItem.watchers',
            ...watchersCol,
            pinned: true,
            filter: ServerSideSelectFilter,
            filterParams: {
              optionKey: 'member',
              getValue: option => option.uuid,
              getLabel: option => option.name,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, watcher: v })
              },
              getValuesForOptionFilter: async context => {
                const response =
                  await ProjectMember.getWatcherFilterOptionUuids({
                    projectUuid,
                    rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                  })
                return response.json
              },
              sortValues: (options: ProjectMemberProps[]) => {
                return options.sort((a, b) => {
                  return (a?.name ?? '').localeCompare(b?.name ?? '')
                })
              },
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectPlan.term' }),
        externalId: 'projectPlan.new.term',
        children: [
          {
            externalId: 'projectPlan.new.sprint',
            field: 'body.wbsItem.sprint',
            headerName: intl.formatMessage({ id: 'projectPlan.sprint' }),
            hide: true,
            width: 90,
            pinned: true,
            editable: params => {
              const type = params.data?.body?.wbsItem?.wbsItemType
              return type?.isTask() || type?.isDeliverable()
            },
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              fetch: async data => {
                const teamUuid = data?.body?.wbsItem?.team?.uuid
                if (!teamUuid) return []
                return Sprint.search('', {
                  teamUuid,
                  statusList: [SprintStatus.INPROGRESS, SprintStatus.STANDBY],
                })
              },
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ServerSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              optionKey: 'sprint',
              getValue: option => option.uuid,
              getLabel: option =>
                `[${option.status}] (${option.teamName}) ${option.name}`,
              fetch: (v: SelectFilter) => {
                return filter({ projectUuid, sprint: v })
              },
              getValuesForOptionFilter: async context => {
                const response = await Sprint.getFilterOptionUuids({
                  projectUuid,
                  rootProjectPlanUuid: context.rootProjectPlan?.uuid,
                })
                return response.json
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.scheduledDate.startDate',
            field: 'body.wbsItem.scheduledDate.startDate',
            headerName: intl.formatMessage({
              id: 'projectPlan.scheduledDate.start',
            }),
            width: 90,
            pinned: true,
            editable: true,
            cellEditor: DateCellEditor,
            type: [ColumnType.date],
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                const startDate = DateVO.parse(params.newValue)
                if (!startDate) return false
                const end = params.data.body?.wbsItem?.scheduledDate?.endDate
                if (params.newValue && end) {
                  const endDate = new DateVO(end)
                  params.data.body!.wbsItem.scheduledDate = {
                    startDate: startDate.serialize(),
                    endDate: startDate.isAfter(endDate)
                      ? startDate.serialize()
                      : endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData[
                      'body.wbsItem.scheduledDate.endDate'
                    ] = endDate.serialize()
                  }
                  return true
                }
                objects.setValue(
                  params.data,
                  'body.wbsItem.scheduledDate.startDate',
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            filter: ServerSideDateFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: DateFilter) => {
                return filter({ projectUuid, scheduledStartDate: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.scheduledDate.endDate',
            field: 'body.wbsItem.scheduledDate.endDate',
            headerName: intl.formatMessage({
              id: 'projectPlan.scheduledDate.end',
            }),
            width: 90,
            pinned: true,
            editable: true,
            cellEditor: DateCellEditor,
            cellEditorParams: {
              getInitialValueOnCalendar: (params: IDateCellEditorParams) => {
                const startDate =
                  params.data.body?.wbsItem?.scheduledDate?.startDate
                return startDate
                  ? dateVoService.construct(startDate)
                  : undefined
              },
            },
            type: [ColumnType.date],
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                const endDate = DateVO.parse(params.newValue)
                if (!endDate) return false
                const start =
                  params.data.body?.wbsItem?.scheduledDate?.startDate
                if (params.newValue && start) {
                  const startDate = new DateVO(start)
                  params.data.body!.wbsItem.scheduledDate = {
                    startDate: startDate.isAfter(endDate)
                      ? endDate.serialize()
                      : startDate.serialize(),
                    endDate: endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData[
                      'body.wbsItem.scheduledDate.startDate'
                    ] = startDate.serialize()
                  }
                  return true
                }
                objects.setValue(
                  params.data,
                  'body.wbsItem.scheduledDate.endDate',
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            filter: ServerSideDateFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: DateFilter) => {
                return filter({ projectUuid, scheduledEndDate: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.actualDate.startDate',
            field: FIELD_WBSITEM_ACTUALDATE_START,
            headerName: intl.formatMessage({
              id: 'projectPlan.actualDate.start',
            }),
            width: 90,
            pinned: true,
            hide: true,
            editable: true,
            cellEditor: DateCellEditor,
            type: [ColumnType.date],
            filter: ServerSideDateFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: DateFilter) => {
                return filter({ projectUuid, actualStartDate: v })
              },
            },
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                const startDate = DateVO.parse(params.newValue)
                if (!startDate) return false
                const end = params.data.body?.wbsItem?.actualDate?.endDate
                if (params.newValue && end) {
                  const endDate = new DateVO(end)
                  params.data.body!.wbsItem.actualDate = {
                    startDate: startDate.serialize(),
                    endDate: startDate.isAfter(endDate)
                      ? startDate.serialize()
                      : endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData[FIELD_WBSITEM_ACTUALDATE_END] =
                      endDate.serialize()
                  }
                  return true
                }
                objects.setValue(
                  params.data,
                  FIELD_WBSITEM_ACTUALDATE_START,
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const wbsItem = params.data?.body?.wbsItem
              if (!wbsItem) return ''
              return wbsItem.actualDate?.startDate
            },
            cellRendererParams: {
              tooltip: (
                params: ICellRendererParams<ProjectPlanNewRow>
              ): string | undefined => {
                const wbsItem = params.data?.body?.wbsItem
                return wbsItem && isStartDelayed(wbsItem)
                  ? intl.formatMessage({ id: 'wbs.start.delayed' })
                  : undefined
              },
            },
            cellStyle: (
              params: CellClassParams<ProjectPlanNewRow>
            ): CellStyle => {
              const style = { justifyContent: 'flex-end' }
              const wbsItem = params.data?.body?.wbsItem
              if (isStartDelayed(wbsItem)) {
                return { ...style, backgroundColor: BackgroundColor.ALERT }
              }
              return style
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.actualDate.endDate',
            field: FIELD_WBSITEM_ACTUALDATE_END,
            headerName: intl.formatMessage({
              id: 'projectPlan.actualDate.end',
            }),
            width: 90,
            pinned: true,
            hide: true,
            editable: true,
            cellEditor: DateCellEditor,
            cellEditorParams: {
              getInitialValueOnCalendar: (params: IDateCellEditorParams) => {
                const startDate =
                  params.data.body?.wbsItem?.actualDate?.startDate
                return startDate
                  ? dateVoService.construct(startDate)
                  : undefined
              },
            },
            type: [ColumnType.date],
            filter: ServerSideDateFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: DateFilter) => {
                return filter({ projectUuid, actualEndDate: v })
              },
            },
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                const endDate = DateVO.parse(params.newValue)
                if (!endDate) return false
                const start = params.data.body?.wbsItem?.actualDate?.startDate
                if (params.newValue && start) {
                  const startDate = new DateVO(start)
                  params.data.body!.wbsItem.actualDate = {
                    startDate: startDate.isAfter(endDate)
                      ? endDate.serialize()
                      : startDate.serialize(),
                    endDate: endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData[FIELD_WBSITEM_ACTUALDATE_START] =
                      startDate.serialize()
                  }
                  return true
                }
                objects.setValue(
                  params.data,
                  FIELD_WBSITEM_ACTUALDATE_END,
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const wbsItem = params.data?.body?.wbsItem
              if (!wbsItem) return ''
              return wbsItem.actualDate?.endDate
            },
            cellRendererParams: {
              tooltip: (
                params: ICellRendererParams<ProjectPlanNewRow>
              ): string | undefined => {
                const wbsItem = params.data?.body?.wbsItem
                return wbsItem && isEndDelayed(wbsItem)
                  ? intl.formatMessage({ id: 'wbs.end.delayed' })
                  : undefined
              },
            },
            cellStyle: (
              params: CellClassParams<ProjectPlanNewRow>
            ): CellStyle => {
              const style = { justifyContent: 'flex-end' }
              const wbsItem = params.data?.body?.wbsItem
              if (isEndDelayed(wbsItem)) {
                return { ...style, backgroundColor: BackgroundColor.ALERT }
              }
              return style
            },
          },
          {
            externalId: 'projectPlan.new.startDelayDays',
            field: 'startDelayDays',
            headerName: intl.formatMessage({
              id: 'projectOverview.startDelayDays',
            }),
            width: 100,
            pinned: true,
            hide: true,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const w = params.data?.body?.wbsItem
              const scheduled = w?.scheduledDate?.startDate
              const actual = w?.actualDate?.startDate
              if (!scheduled) return
              const diff = (actual ? new DateVO(actual) : today).diff(
                new DateVO(scheduled)
              )
              return 0 < diff ? diff : undefined
            },
          },
          {
            externalId: 'projectPlan.new.endDelayDays',
            field: 'endDelayDays',
            headerName: intl.formatMessage({
              id: 'projectOverview.endDelayDays',
            }),
            width: 100,
            pinned: true,
            hide: true,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const w = params.data?.body?.wbsItem
              const scheduled = w?.scheduledDate?.endDate
              const actual = w?.actualDate?.endDate
              if (!scheduled) return
              const diff = (actual ? new DateVO(actual) : today).diff(
                new DateVO(scheduled)
              )
              return 0 < diff ? diff : undefined
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectPlan.workload' }),
        externalId: 'projectPlan.new.estimation',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.estimatedWorkload.deliverable',
            ...estimatedWorkLoadDeliverableCol,
            colId: 'body.wbsItem.estimatedHour.deliverable',
            headerName: intl.formatMessage({
              id: 'projectPlan.estimated.deliverable',
            }),
            width: 120,
            hide: false,
            cellStyle: (params: CellClassParams<ProjectPlanNewRow>) => {
              const type = params.data?.body?.wbsItem?.wbsItemType
              return {
                fontWeight:
                  type?.isDeliverable() || type?.isTask()
                    ? FontWeight.NORMAL
                    : FontWeight.BOLD,
                justifyContent: 'flex-end',
              }
            },
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                const value = toNumber(params.newValue)
                params.data.body!.wbsItem.estimatedHour = value
                  ? value *
                    (params.context.workloadUnitState?.hoursPerSelectedUnit ||
                      1)
                  : 0
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams) => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || w.wbsItemType?.isTask()) return undefined
              const hour = w.estimatedHour ?? 0
              const rate =
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              if (
                w.wbsItemType.isWorkgroup() ||
                w.wbsItemType.isProcess() ||
                w.wbsItemType.isDeliverableList()
              ) {
                if (!c) return undefined
                return (
                  (c.sumDeliverableEstimatedHour -
                    c.sumDeliverableEstimatedHourDiscard) /
                  rate
                )
              }
              return hour / rate
            },
            valueFormatter: params =>
              Number.isFinite(params.value)
                ? parseFloat(params.value).toFixed(2)
                : '',
            filter: ServerSideNumberFilter,
            filterParams: {
              fetch: (v: NumberFilter) => {
                return filter({ projectUuid, estimatedHour: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.estimatedWorkload.task',
            ...estimatedWorkLoadTaskCol,
            headerName: intl.formatMessage({
              id: 'projectPlan.estimated.task',
            }),
            width: 120,
            hide: false,
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                const value = toNumber(params.newValue)
                params.data.body!.wbsItem.estimatedHour = value
                  ? value *
                    (params.context.workloadUnitState?.hoursPerSelectedUnit ||
                      1)
                  : 0
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams) => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w) return undefined
              const hour = w.estimatedHour ?? 0
              const rate =
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              if (w.wbsItemType.isTask()) return hour / rate
              if (!c) return undefined
              return (
                (c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard) / rate
              )
            },
            valueFormatter: (
              params: ValueFormatterParams<ProjectPlanNewRow>
            ) => {
              const format = (v: number) =>
                Number.isFinite(v) ? parseFloat(v.toString()).toFixed(2) : ''
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (
                w &&
                w.wbsItemType.isTask() &&
                c &&
                0 < c.countTask - c.countStatusTaskDiscard
              ) {
                const rate =
                  params.context.workloadUnitState?.hoursPerSelectedUnit || 1
                const summary =
                  ((w.estimatedHour ?? 0) +
                    c.sumTaskEstimatedHour -
                    c.sumTaskEstimatedHourDiscard) /
                  rate
                return `${format(params.value)} / ${format(summary)}`
              }
              return format(params.value)
            },
            filter: ServerSideNumberFilter,
            filterParams: {
              fetch: (v: NumberFilter) => {
                return filter({ projectUuid, estimatedHour: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.cumulation.actualHour',
            field: FIELD_WBSITEM_ACTUALHOUR,
            headerName: intl.formatMessage({
              id: 'projectOverview.actualHour',
            }),
            width: 90,
            hide: true,
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              const rate =
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              if (!w || !c) return
              return w.wbsItemType?.isTask()
                ? c.actualHour / rate
                : c.sumActualHour / rate
            },
            cellRenderer: ProjectPlanNewActualHourCellRenderer,
            filter: ServerSideNumberFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: NumberFilter) => {
                return filter({ projectUuid, actualHour: v })
              },
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectPlan.amount' }),
        externalId: 'projectPlan.new.amouont',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.estimatedAmount.deliverable',
            field: 'body.wbsItem.estimatedAmount.deliverable',
            headerName: intl.formatMessage({
              id: 'projectPlan.estimatedAmount.deliverable',
            }),
            width: 105,
            hide: true,
            editable: (params: EditableCallbackParams<ProjectPlanNewRow>) =>
              !!params.data?.body?.wbsItem?.wbsItemType?.isDeliverable(),
            cellClass: 'ag-numeric-cell',
            cellStyle: (params: CellClassParams<ProjectPlanNewRow>) => {
              return {
                fontWeight:
                  !!params.data?.body?.wbsItem?.wbsItemType?.isDeliverable()
                    ? FontWeight.NORMAL
                    : FontWeight.BOLD,
                justifyContent: 'flex-end',
              }
            },
            valueParser: (params: ValueParserParams) =>
              toInteger(params.newValue),
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                params.data.body!.wbsItem.estimatedAmount = toInteger(
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              return deliverableEstimatedAmount(params.data)
            },
            valueFormatter: (
              params: ValueFormatterParams<ProjectPlanNewRow>
            ) => {
              const w = params.data?.body?.wbsItem
              if (w?.wbsItemType?.isDeliverable()) {
                return params.value ? Number(params.value).toLocaleString() : 0
              }
              return params.value
                ? Number(params.value).toLocaleString()
                : params.value
            },
            filter: ServerSideNumberFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: NumberFilter) => {
                return filter({
                  projectUuid,
                  type: {
                    values: [
                      store.getState().project.wbsItemTypes.deliverable.uuid,
                    ],
                    includeBlank: false,
                  },
                  estimatedAmount: v,
                })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.estimatedAmount.task',
            field: 'body.wbsItem.estimatedAmount.task',
            headerName: intl.formatMessage({
              id: 'projectPlan.estimatedAmount.task',
            }),
            width: 105,
            hide: true,
            editable: (params: EditableCallbackParams<ProjectPlanNewRow>) =>
              !!params.data?.body?.wbsItem?.wbsItemType?.isTask(),
            cellClass: 'ag-numeric-cell',
            cellStyle: (params: CellClassParams<ProjectPlanNewRow>) => {
              return {
                fontWeight: !!params.data?.body?.wbsItem?.wbsItemType?.isTask()
                  ? FontWeight.NORMAL
                  : FontWeight.BOLD,
                justifyContent: 'flex-end',
              }
            },
            valueParser: (params: ValueParserParams) =>
              toInteger(params.newValue),
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                params.data.body!.wbsItem.estimatedAmount = toInteger(
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              return taskEstimatedAmount(params.data)
            },
            valueFormatter: (
              params: ValueFormatterParams<ProjectPlanNewRow>
            ) => {
              const w = params.data?.body?.wbsItem
              if (w?.wbsItemType?.isTask()) {
                return params.value ? Number(params.value).toLocaleString() : 0
              }
              return params.value
                ? Number(params.value).toLocaleString()
                : params.value
            },
            filter: ServerSideNumberFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: NumberFilter) => {
                return filter({
                  projectUuid,
                  type: {
                    values: [store.getState().project.wbsItemTypes.task.uuid],
                    includeBlank: false,
                  },
                  estimatedAmount: v,
                })
              },
            },
          },
          {
            externalId: 'projectPlan.new.wbsItem.actualAmount',
            field: 'body.wbsItem.actualAmount',
            headerName: intl.formatMessage({ id: 'projectPlan.actualAmount' }),
            width: 90,
            hide: true,
            editable: (params: EditableCallbackParams<ProjectPlanNewRow>) =>
              !!params.data?.body?.wbsItem?.wbsItemType?.isTask(),
            cellClass: 'ag-numeric-cell',
            cellStyle: (params: CellClassParams<ProjectPlanNewRow>) => {
              return {
                fontWeight: !!params.data?.body?.wbsItem?.wbsItemType?.isTask()
                  ? FontWeight.NORMAL
                  : FontWeight.BOLD,
                justifyContent: 'flex-end',
              }
            },
            valueParser: (params: ValueParserParams) =>
              toInteger(params.newValue),
            valueSetter: cumulationSourceValueSetter(
              (params: ValueSetterParams<ProjectPlanNewRow>) => {
                params.data.body!.wbsItem.actualAmount = toInteger(
                  params.newValue
                )
                return true
              },
              updateRows
            ),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              return actualAmount(params.data)
            },
            valueFormatter: (
              params: ValueFormatterParams<ProjectPlanNewRow>
            ) => {
              const w = params.data?.body?.wbsItem
              if (w?.wbsItemType?.isTask()) {
                return params.value ? Number(params.value).toLocaleString() : 0
              }
              return params.value
                ? Number(params.value).toLocaleString()
                : params.value
            },
            filter: ServerSideNumberFilter,
            floatingFilter: true,
            filterParams: {
              fetch: (v: NumberFilter) => {
                return filter({ projectUuid, actualAmount: v })
              },
            },
          },
          {
            externalId: 'projectPlan.new.completionAmountRate',
            field: 'completionAmountRate',
            headerName: intl.formatMessage({
              id: 'projectPlan.completionAmountRate',
            }),
            width: 90,
            hide: true,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              if (params.context.aggregateTarget === WbsItemType.DELIVERABLE) {
                return getRate(
                  actualAmount(params.data) ?? 0,
                  deliverableEstimatedAmount(params.data) ?? 0
                )
              } else if (params.context.aggregateTarget === WbsItemType.TASK) {
                return getRate(
                  actualAmount(params.data) ?? 0,
                  taskEstimatedAmount(params.data) ?? 0
                )
              }
            },
            valueFormatter: (
              params: ValueFormatterParams<ProjectPlanNewRow>
            ) => {
              if (params.value === undefined) return '-'
              return `${(params.value * 100).toFixed(1)}%`
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectPlan.storyPoint' }),
        externalId: 'projectPlan.new.storyPoint',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.estimatedStoryPoint',
            ...estimatedStoryPointCol,
            headerName: intl.formatMessage({
              id: 'projectPlan.estimatedStoryPoint',
            }),
            width: 90,
            editable: params =>
              params.data.body?.wbsItem?.wbsItemType?.isDeliverable() ||
              params.data.body?.wbsItem?.wbsItemType?.isTask(),
            valueParser: (params: ValueParserParams) =>
              toNumber(params.newValue),
            valueSetter: (params: ValueSetterParams) => {
              const field = params.colDef.field || params.colDef.colId
              const value = toNumber(params.newValue)
              if (
                !field ||
                (!params.oldValue && !value) ||
                params.oldValue === value
              ) {
                return false
              }
              objects.setValue(params.data, field, value)
              params.data.edited = true
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }

              store.dispatch(requireSave())
              return true
            },
            floatingFilter: false,
          },
        ],
      },
      {
        externalId: 'projectPlan.new.progress',
        headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
        children: [
          {
            externalId: 'projectPlan.new.progress.scheduledProgressRate',
            field: 'progress.scheduledProgressRate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledProgressRate',
            }),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const c = params.data?.body?.cumulation
              if (!c) return
              return Progress.of(c, params.context).scheduledProgressRate
            },
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
            width: 90,
          },
          {
            externalId: 'projectPlan.new.progress.progressRate',
            field: 'progress.progressRate',
            headerName: intl.formatMessage({
              id: 'projectOverview.progressRate',
            }),
            valueGetter: params => {
              const c = params.data?.body?.cumulation
              if (!c) return
              return Progress.of(c, params.context).progressRate
            },
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.progress.total',
            field: 'progress.total',
            headerName: intl.formatMessage({ id: 'projectOverview.total' }),
            valueGetter: (params: ValueGetterParams<ProjectPlanNewRow>) => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!c || !w) return
              return isProgressDetailRow(params)
                ? ProgressDetail.of(w, params.context).total
                : Progress.of(c, params.context).total
            },
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.progress.scheduledToBe',
            field: 'progress.scheduledToBe',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledToBeCompleted',
            }),
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return isProgressDetailRow(params)
                ? ProgressDetail.of(w, params.context).scheduledToBeCompleted
                : Progress.of(c, params.context).plannedValue
            },
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.progress.completed',
            field: 'progress.completed',
            headerName: intl.formatMessage({
              id: 'projectOverview.completed',
            }),
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return isProgressDetailRow(params)
                ? ProgressDetail.of(w, params.context).completed
                : Progress.of(c, params.context).earnedValue
            },
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.progress.preceding',
            field: 'progress.preceding',
            headerName: intl.formatMessage({
              id: 'projectOverview.preceding',
            }),
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return isProgressDetailRow(params)
                ? ProgressDetail.of(w, params.context).endPreceding
                : Progress.of(c, params.context).preceding
            },
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.progress.delayed',
            field: 'progress.delayed',
            headerName: intl.formatMessage({ id: 'projectOverview.delayed' }),
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return isProgressDetailRow(params)
                ? ProgressDetail.of(w, params.context).endDelayed
                : Progress.of(c, params.context).delayed
            },
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.progress.remaining',
            field: 'progress.remaining',
            headerName: intl.formatMessage({
              id: 'projectOverview.remaining',
            }),
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return isProgressDetailRow(params)
                ? ProgressDetail.of(w, params.context).remaining
                : Progress.of(c, params.context).remaining
            },
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
        ],
      },
      {
        externalId: 'projectPlan.new.evm',
        headerName: intl.formatMessage({ id: 'projectOverview.evm' }),
        children: [
          {
            externalId: 'projectPlan.new.evm.costVariance',
            field: 'evm.costVariance',
            headerName: intl.formatMessage({
              id: 'projectOverview.costVariance',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.CV' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.CV' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).costVariance
            },
            valueFormatter: evmValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.evm.costPerformanceIndex',
            field: 'evm.costPerformanceIndex',
            headerName: intl.formatMessage({
              id: 'projectOverview.costPerformanceIndex',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.CPI' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.CPI' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).costPerformanceIndex
            },
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.evm.scheduleVariance',
            field: 'evm.scheduleVariance',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduleVariance',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.SV' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.SV' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).scheduleVariance
            },
            valueFormatter: evmValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.evm.schedulePerformanceIndex',
            field: 'evm.schedulePerformanceIndex',
            headerName: intl.formatMessage({
              id: 'projectOverview.schedulePerformanceIndex',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.SPI' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.SPI' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).schedulePerformanceIndex
            },
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.evm.estimateToComplete',
            field: 'evm.estimateToComplete',
            headerName: intl.formatMessage({
              id: 'projectOverview.estimateToComplete',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.ETC' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.ETC' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).estimateToComplete
            },
            valueFormatter: evmValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.evm.estimateAtCompletion',
            field: 'evm.estimateAtCompletion',
            headerName: intl.formatMessage({
              id: 'projectOverview.estimateAtCompletion',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.EAC' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.EAC' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).estimateAtCompletion
            },
            valueFormatter: evmValueFormatter,
            ...cumulationCellType,
          },
          {
            externalId: 'projectPlan.new.evm.varianceAtCompletion',
            field: 'evm.varianceAtCompletion',
            headerName: intl.formatMessage({
              id: 'projectOverview.varianceAtCompletion',
            }),
            headerTooltip: intl.formatMessage({ id: 'evm.description.VAC' }),
            headerComponentParams: {
              template: customHeaderTemplate({
                tooltip: intl.formatMessage({ id: 'evm.description.VAC' }),
              }),
            },
            valueGetter: params => {
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return Progress.of(c, params.context).varianceAtCompletion
            },
            valueFormatter: evmValueFormatter,
            ...cumulationCellType,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.ganttChart' }),
        headerGroupComponent: 'ganttHeaderGroupComponent',
        externalId: 'projectPlan.new.ganttChart',
        children: [
          {
            field: 'ganttChart',
            headerName: intl.formatMessage({
              id: 'projectOverview.ganttChart',
            }),
            type: [ColumnType.gantt],
            cellRendererParams: {
              dataPath: 'body',
              updateRows,
            },
            width: 560,
            cellStyle: {
              borderColor: 'transparent',
              backgroundColor: 'transparent',
            },
            valueGetter: (params: ValueGetterParams) => {
              const w = params.data?.body?.wbsItem
              if (!w) return undefined
              return JSON.stringify(w)
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'changeLog' }),
        externalId: 'projectPlan.new.changeLog',
        children: [
          {
            externalId: 'projectPlan.new.wbsItem.revision',
            field: 'body.wbsItem.revision',
            headerName: intl.formatMessage({ id: 'revision' }),
            hide: true,
            width: 90,
          },
          {
            externalId: 'projectPlan.new.wbsItem.createdBy',
            field: 'body.wbsItem.createdBy',
            headerName: intl.formatMessage({ id: 'createdBy' }),
            hide: true,
            width: 110,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.wbsItem?.createdBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.wbsItem.createdBy.name',
              iconUrlField: 'body.wbsItem.createdBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            externalId: 'projectPlan.new.wbsItem.createdAt',
            field: 'body.wbsItem.createdAt',
            headerName: intl.formatMessage({ id: 'createdAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
          {
            externalId: 'projectPlan.new.wbsItem.updatedBy',
            field: 'body.wbsItem.updatedBy',
            headerName: intl.formatMessage({ id: 'updatedBy' }),
            hide: true,
            width: 110,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.wbsItem?.updatedBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.wbsItem.updatedBy.name',
              iconUrlField: 'body.wbsItem.updatedBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            externalId: 'projectPlan.new.wbsItem.updatedAt',
            field: 'body.wbsItem.updatedAt',
            headerName: intl.formatMessage({ id: 'updatedAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
        ],
      },
    ],
    [
      projectUuid,
      switchRootWbsItem,
      updateRows,
      addRowToChild,
      tagsCol,
      watchersCol,
      estimatedWorkLoadDeliverableCol,
      estimatedWorkLoadTaskCol,
      estimatedStoryPointCol,
    ]
  )
  return columns
}

export default useDefaultColumns
