import { ColDef, IFilterParams } from 'ag-grid-community'
import { IconCellRenderer } from '../../../components/cellRenderer'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyEntity,
} from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import {
  ReferenceEntityValueType,
  wbsItemAdditionalPropertyValuesVoService,
} from '../../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { Option } from '..'
import { EntitySearchCellEditor } from '../../../components/cellEditor'
import { ProjectMemberRepository } from '../../../../../../applications/ports/projectMemberRepository'
import { TeamRepository } from '../../../../../../applications/ports/teamRepository'
import { WbsItemRepository } from '../../../../../../applications/ports/wbsItemRepository'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'
import {
  ClientSideSelectFilter,
  EntitySearchFilter,
  EntitySearchFilterType,
  SelectFilter,
  ServerSideEntitySearchFilter,
  ServerSideSelectFilter,
} from '../../../components/filter'

type EntitySearchRepositories = {
  wbsItemRepository: WbsItemRepository
  projectMemberRepository: ProjectMemberRepository
  teamRepository: TeamRepository
}
export const entitySearchColumnDef = <RowData>(
  column: ColDef,
  projectUuid: string,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>,
  repositories: EntitySearchRepositories
): void => {
  const { wbsItemRepository, projectMemberRepository, teamRepository } =
    repositories
  column.cellRenderer = IconCellRenderer
  column.cellRendererParams = {
    ...column.cellRendererParams,
    getLabelWithIconUrl: (
      rowData: RowData
    ):
      | {
          label: string
          iconUrl: string
        }
      | undefined => {
      const wbsItemAdditionalPropertyValues =
        option.getAdditionalPropertyValues(rowData)
      if (!wbsItemAdditionalPropertyValues) return undefined
      const wbsItemAdditionalPropertyValue =
        wbsItemAdditionalPropertyValuesVoService.getValue(
          wbsItemAdditionalPropertyValues,
          wbsItemAdditionalProperty.uuid
        )
      if (
        !wbsItemAdditionalPropertyValue ||
        !wbsItemAdditionalPropertyValue.value
      ) {
        return undefined
      }
      const referenceEntityValue =
        wbsItemAdditionalPropertyValue.value as ReferenceEntityValueType
      return {
        label: referenceEntityValue.name,
        iconUrl: referenceEntityValue.iconUrl,
      }
    },
  }
  column.cellEditor = EntitySearchCellEditor
  column.cellEditorParams = {
    fetch: data => {
      switch (wbsItemAdditionalProperty.entitySearchReferenceEntity) {
        case EntitySearchReferenceEntity.PROCESS:
          return wbsItemRepository.fetchListAsReferencedEntity(projectUuid, [
            WbsItemType.PROCESS,
          ])
        case EntitySearchReferenceEntity.DELIVERABLE_LIST:
          return wbsItemRepository.fetchListAsReferencedEntity(projectUuid, [
            WbsItemType.DELIVERABLE_LIST,
          ])
        case EntitySearchReferenceEntity.DELIVERABLE:
          return wbsItemRepository.fetchListAsReferencedEntity(projectUuid, [
            WbsItemType.DELIVERABLE,
          ])
        case EntitySearchReferenceEntity.TASK:
          return wbsItemRepository.fetchListAsReferencedEntity(projectUuid, [
            WbsItemType.TASK,
          ])
        case EntitySearchReferenceEntity.PROJECT_MEMBER:
          return projectMemberRepository.fetchListAsReferencedEntity(
            projectUuid
          )
        case EntitySearchReferenceEntity.TEAM:
          return teamRepository.fetchListAsReferencedEntity(projectUuid)
        default:
          console.error(
            `Unsupported the reference entity of this wbs item additional property(uuid=${wbsItemAdditionalProperty.uuid}).`
          )
          return []
      }
    },
    search: (text: string, data) => {
      switch (wbsItemAdditionalProperty.entitySearchReferenceEntity) {
        case EntitySearchReferenceEntity.PROCESS:
          return wbsItemRepository.searchAsReferencedEntity(
            projectUuid,
            [WbsItemType.PROCESS],
            text
          )
        case EntitySearchReferenceEntity.DELIVERABLE_LIST:
          return wbsItemRepository.searchAsReferencedEntity(
            projectUuid,
            [WbsItemType.DELIVERABLE_LIST],
            text
          )
        case EntitySearchReferenceEntity.DELIVERABLE:
          return wbsItemRepository.searchAsReferencedEntity(
            projectUuid,
            [WbsItemType.DELIVERABLE],
            text
          )
        case EntitySearchReferenceEntity.TASK:
          return wbsItemRepository.searchAsReferencedEntity(
            projectUuid,
            [WbsItemType.TASK],
            text
          )
        case EntitySearchReferenceEntity.PROJECT_MEMBER:
          return projectMemberRepository.searchAsReferencedEntity(
            projectUuid,
            text
          )
        case EntitySearchReferenceEntity.TEAM:
          // TODO Implement search method to search by text.
          return teamRepository.fetchListAsReferencedEntity(projectUuid)
        default:
          console.error(
            `Unsupported the reference entity of this wbs item additional property(uuid=${wbsItemAdditionalProperty.uuid}).`
          )
          return []
      }
    },
  }
  setFilterParams(column, wbsItemAdditionalProperty, option, repositories)
}

const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>,
  {
    wbsItemRepository,
    teamRepository,
    projectMemberRepository,
  }: EntitySearchRepositories
) => {
  const projectUuid = wbsItemAdditionalProperty.projectUuid
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = ClientSideSelectFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        valueGetter: params => {
          if (!params.node.data) return undefined

          const wbsItemAdditionalPropertyValues =
            option.getAdditionalPropertyValues(params.node.data)
          if (!wbsItemAdditionalPropertyValues) return undefined
          const wbsItemAdditionalPropertyValue =
            wbsItemAdditionalPropertyValuesVoService.getValue(
              wbsItemAdditionalPropertyValues,
              wbsItemAdditionalProperty.uuid
            )
          if (
            !wbsItemAdditionalPropertyValue ||
            !wbsItemAdditionalPropertyValue.value
          ) {
            return undefined
          }
          const referenceEntityValue =
            wbsItemAdditionalPropertyValue.value as ReferenceEntityValueType
          return referenceEntityValue
        },
        getValue: (option: ReferenceEntityValueType) => {
          return option.uuid
        },
        getLabel: (option: ReferenceEntityValueType) => {
          return option.name
        },
      }
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideEntitySearchFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        searchOptions: async (params: IFilterParams, text: string) => {
          switch (wbsItemAdditionalProperty.entitySearchReferenceEntity) {
            case EntitySearchReferenceEntity.PROCESS:
              return wbsItemRepository.searchAsReferencedEntity(
                projectUuid,
                [WbsItemType.PROCESS],
                text
              )
            case EntitySearchReferenceEntity.DELIVERABLE_LIST:
              return wbsItemRepository.searchAsReferencedEntity(
                projectUuid,
                [WbsItemType.DELIVERABLE_LIST],
                text
              )
            case EntitySearchReferenceEntity.DELIVERABLE:
              return wbsItemRepository.searchAsReferencedEntity(
                projectUuid,
                [WbsItemType.DELIVERABLE],
                text
              )
            case EntitySearchReferenceEntity.TASK:
              return wbsItemRepository.searchAsReferencedEntity(
                projectUuid,
                [WbsItemType.TASK],
                text
              )
            case EntitySearchReferenceEntity.PROJECT_MEMBER:
              return projectMemberRepository.searchAsReferencedEntity(
                projectUuid,
                text
              )
            case EntitySearchReferenceEntity.TEAM:
              const teams = await teamRepository.fetchListAsReferencedEntity(
                projectUuid
              )
              return teams.filter(v => v.name.includes(text))
            default:
              console.error(
                `Unsupported the reference entity of this wbs item additional property(uuid=${wbsItemAdditionalProperty.uuid}).`
              )
              return []
          }
        },
        fetch: (v: EntitySearchFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                values: v.selectedValues.map(v => v.uuid),
                includeBlank: v.filterType === EntitySearchFilterType.BLANK,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}
