import React, { useCallback, useEffect, useRef, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  Autocomplete,
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  Slider,
  styled,
  Typography,
} from '@mui/material'
import TextInput from '../editors/input/TextInput'
import { connect } from 'react-redux'
import store, { AllState } from '../../../store'
import ProjectMemberApi, {
  ProjectMemberProps,
} from '../../../lib/functions/projectMember'
import WbsItemApi, {
  WbsItemDeltaInput,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import TaskActualWorkApi, {
  ActualWorkDetail,
} from '../../../lib/functions/taskActualWork'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../pages'
import { EventNote } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import RadioStepper from '../editors/stepper/RadioStepper'
import { intl } from '../../../i18n'
import DateVO from '../../../vo/DateVO'
import DatePicker from 'react-datepicker'
import SubmitButton from '../buttons/SubmitButton'
import { BorderColor, Color, FontSize } from '../../../styles/commonStyles'
import { parseToHalfNumberOrEmptyString, toNumber } from '../../../utils/number'
import { CustomEnumValue } from '../../../lib/commons/appFunction'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../lib/functions/customEnumValue'
import { getLabel, parse } from '../../../lib/commons/i18nLabel'
import moment from 'moment'
import { DateTerm } from '../../../utils/date'
import Select from '../editors/select/Select'
import {
  finishEditComment,
  generateCommentDraftKey,
  postComment,
} from '../../../store/comments'
import CommentEditor from '../../containers/Comment/CommentEditor'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { MentionSuggestionData } from '../../containers/Comment/CommentEditor/lib/Converter'
import { DelayIcon } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/delayIcon'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import {
  runAsyncWithPerfMonitoring,
  runUseCaseAsyncWithPerfMonitoring,
} from '../../../utils/monitoring'
import { NewWbsItemRow } from '../../pages/ProjectPlanNew/projectPlanNew'
import { createDelta } from '../../../domain/value-object/ItemDeltaInputVO'
import {
  APIResponse,
  extractValuesFromResponse,
  successDummyResponse,
} from '../../../lib/commons/api'
import { handleWarning } from '../../../handlers/globalErrorHandler'

interface Props extends WrappedComponentProps, StateProps, ComponentOwnProps {}

export interface WbsItemForStatusChange {
  uuid: string
  lockVersion: number
  type: WbsItemType
  status: WbsItemStatus
  projectUuid: string
  assignee?: ProjectMemberProps
  priority?: string
  scheduledDate?: DateTerm
  actualDate?: DateTerm
  wbsItemType?: WbsItemTypeVO
  watchers?: ProjectMemberProps[]
}

interface WrapperProps {
  anchorEl: any
  projectUuid?: string
  wbsItem?: WbsItemRow | NewWbsItemRow
  wbsItemDelta?: Partial<WbsItemDeltaInput>
  onAfterUpdate: (wbsItem: WbsItemRow | NewWbsItemRow) => void
  onClose: () => void
}

export const StatusChangePopper = (props: WrapperProps) => {
  const projectUuid = props.wbsItem?.projectUuid ?? props.projectUuid
  if (!projectUuid || !props.wbsItem) return <></>
  return (
    <StatusChangePopperContent
      anchorEl={props.anchorEl}
      projectUuid={projectUuid}
      wbsItem={props.wbsItem}
      wbsItemDelta={props.wbsItemDelta}
      onAfterUpdate={props.onAfterUpdate}
      onClose={props.onClose}
    />
  )
}

const mapStateToProps = (state: AllState, ownProps: ComponentOwnProps) => ({
  applicationFunctionUuid: state.appFunction.functions.find(
    func => func.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
  )?.uuid,
  draft: state.comments.drafts.get(
    generateCommentDraftKey(
      state.appFunction.functions.find(
        func => func.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
      )?.uuid || '',
      ownProps.wbsItem?.uuid || ''
    )
  ),
})

export interface ComponentOwnProps {
  anchorEl: any
  projectUuid: string
  wbsItem: WbsItemRow | NewWbsItemRow
  wbsItemDelta?: Partial<WbsItemDeltaInput>
  onAfterUpdate: (wbsItem: WbsItemRow | NewWbsItemRow) => void
  onClose: () => void
}

interface StateProps {
  applicationFunctionUuid?: string
  draft?: string
}

const StatusChangePopperContent = connect(mapStateToProps)(
  injectIntl((props: Props) => {
    const prevProjectUuid = useRef<string>()
    const [initialized, setInitialized] = useState<boolean>(false)
    // Wbs item input
    const [status, setStatus] = useState<WbsItemStatus>(WbsItemStatus.TODO)
    const [priority, setPriority] = useState<string>()
    const [assignee, setAssignee] = useState<ProjectMemberProps>()
    const [watchers, setWatchers] = useState<ProjectMemberProps[]>()
    const [assigneeText, setAssigneeText] = useState<string>('')
    // Actual work
    const [actualDate, setActualDate] = useState<DateVO>(DateVO.now())
    const [actualHour, setActualHour] = useState<string>('')
    const currentTaskActualWorks = useRef<ActualWorkDetail[]>([])
    const [taskActualWorks, setTaskActualWorks] = useState<ActualWorkDetail[]>(
      []
    )
    const [isComposing, setIsComposing] = useState<boolean>(false)
    // options
    const [assigneeOptions, setAssigneeOptions] = useState<
      ProjectMemberProps[]
    >([])
    const [assigneeOptionLabels, setAssigneeOptionLabels] = useState<string[]>(
      []
    )
    const [priorityOptions, setPriorityOptions] = useState<CustomEnumValue[]>(
      []
    )
    // Comment
    const [comment, setComment] = useState<string>('')
    const [mentions, setMentions] = useState<MentionSuggestionData[]>([])

    const [submitButtonDisabled, setSubmitButtonDisabled] =
      useState<boolean>(false)

    const formatDate = (date: Date): string => {
      return moment(date).format('YYYY-MM-DD')
    }
    const formatHour = (value: number): string => {
      if (0 < Number(value) && Number(value) <= 24) {
        return Number(value).toFixed(2)
      }
      return '0.00'
    }

    useEffect(() => {
      if (props.wbsItem.uuid) {
        const wbsItem = props.wbsItem
        setStatus(wbsItem.status!)
        setPriority(wbsItem.priority)
        setAssignee(wbsItem.assignee)
        setWatchers(wbsItem.watchers)
        if (props.wbsItem.wbsItemType?.isTask()) {
          fetchActualHour()
        }
        setComment(props.draft || '')
        setInitialized(true)
      }
    }, [props.wbsItem.uuid])

    useEffect(() => {
      if (props.projectUuid && prevProjectUuid.current !== props.projectUuid) {
        fetchPriorityOptions()
        fetchAssigneeOptions()
        prevProjectUuid.current = props.projectUuid
      }
    }, [props.projectUuid])

    const clear = useCallback(() => {
      setActualHour('')
      setAssignee(undefined)
      setWatchers(undefined)
      setPriority(undefined)
      setAssigneeText('')
      setTaskActualWorks([])
      currentTaskActualWorks.current = []
    }, [])

    const onClose = useCallback(() => {
      clear()
      props.onClose?.()
      setInitialized(false)
    }, [])

    const fetchAssigneeOptions = async () => {
      const response = await ProjectMemberApi.searchAll({
        projectUuid: props.projectUuid,
      })
      const options = response.map(res => res.name)
      setAssigneeOptions(response)
      setAssigneeOptionLabels(options)
    }

    const fetchPriorityOptions = async () => {
      const response = await getCustomEnumValues({
        customEnumCode: CustomEnumCode.WBS_PRIORITY,
        groupUuid: props.projectUuid,
      })
      const options = response.json.map(option => ({
        ...option,
        nameI18n: parse(option.nameI18n),
      }))
      setPriorityOptions(options)
    }

    const fetchActualHour = async () => {
      const response = await TaskActualWorkApi.getActualHoursByUser({
        taskUuid: props.wbsItem.uuid!,
      })
      const actualWorks = response.json || []
      setTaskActualWorks(actualWorks)
      const formattedDate = actualDate.format('YYYY-MM-DD')
      const actualWork = actualWorks.find(v => v.actualDate === formattedDate)
      const hour = actualWork ? formatHour(actualWork.hour) : ''
      setActualHour(hour)
      currentTaskActualWorks.current = actualWorks
    }

    const onChangeActualDate = async (value: Date) => {
      const targetDate = formatDate(value)
      const actualWork = taskActualWorks.find(v => v.actualDate === targetDate)
      const actualHour = actualWork ? actualWork.hour.toString() : ''

      setActualDate(new DateVO(value))
      setActualHour(actualHour)
    }

    const onChangeComment = (
      html: string,
      text: string,
      mentions: MentionSuggestionData[]
    ) => {
      setComment(text === '' ? '' : html)
      setMentions(mentions)
    }

    const onSubmit = async () => {
      runUseCaseAsyncWithPerfMonitoring(
        'Submit StatusChangePopper',
        async () => {
          setSubmitButtonDisabled(true)
          try {
            const actualWorkResponse = await submitActualWork()
            const wbsItemResult = await submitWbsItem()
            if (!actualWorkResponse.hasError && !wbsItemResult.hasError) {
              store.dispatch(
                addScreenMessage(props.applicationFunctionUuid!, {
                  type: MessageLevel.SUCCESS,
                  title: intl.formatMessage({ id: 'registration.complete' }),
                })
              )
            }
            if (actualWorkResponse.hasWarning) {
              const messages = extractValuesFromResponse(
                actualWorkResponse.json,
                'messages'
              )
              handleWarning(messages, () => props.wbsItem)
            }
            if (wbsItemResult.hasWarning) {
              const messages = extractValuesFromResponse(
                wbsItemResult.json,
                'messages'
              )
              handleWarning(messages, () => props.wbsItem)
            }

            submitComment()

            const wbsItemAfterUpdate: WbsItemForStatusChange = {
              uuid: props.wbsItem.uuid!,
              lockVersion: props.wbsItem.lockVersion!,
              type: props.wbsItem.type!,
              status: status,
              projectUuid: props.projectUuid,
              assignee: assignee,
              priority: priority,
              scheduledDate: props.wbsItem.scheduledDate,
              actualDate: props.wbsItem.actualDate,
              watchers: watchers,
            }
            runAsyncWithPerfMonitoring(
              'Run after-update-process of StatusChangePopper',
              async () => props.onAfterUpdate(wbsItemAfterUpdate)
            )
          } finally {
            setSubmitButtonDisabled(false)
          }
          onClose()
        }
      )
    }

    const submitActualWork = useCallback(async (): Promise<APIResponse> => {
      if (!actualHourChanged()) return successDummyResponse

      return runAsyncWithPerfMonitoring(
        'Update work hour on status popper',
        async () => {
          return TaskActualWorkApi.update({
            taskUuid: props.wbsItem.uuid!,
            hour: Number(actualHour),
            actualDate: formatDate(actualDate.toDate()),
          })
        }
      )
    }, [actualHour, actualDate])

    const submitWbsItem = useCallback(async (): Promise<APIResponse> => {
      if (!wbsItemChanged()) return successDummyResponse
      const wbsItemRequest = {
        uuid: props.wbsItem.uuid!,
        type: props.wbsItem.type!,
        ...props.wbsItemDelta,
      } as WbsItemDeltaInput
      if (statusChanged()) {
        wbsItemRequest.status = createDelta(
          props.wbsItemDelta?.status?.oldValue ?? props.wbsItem.status!,
          status
        )
      }
      if (priorityChanged()) {
        wbsItemRequest.priority = createDelta(
          props.wbsItemDelta?.priority?.oldValue ?? props.wbsItem.priority,
          priority
        )
      }
      if (assigneeChanged()) {
        wbsItemRequest.assigneeUuid = createDelta(
          props.wbsItemDelta?.assigneeUuid?.oldValue ??
            props.wbsItem.assignee?.uuid,
          assignee?.uuid
        )
      }
      if (watchersChanged()) {
        wbsItemRequest.watchers = props.wbsItem.watchers?.map(v => v.uuid)
      }

      return runAsyncWithPerfMonitoring(
        'Update wbs item on status popper',
        async () =>
          WbsItemApi.updateDelta({
            input: wbsItemRequest,
            watchers: wbsItemRequest.watchers,
          })
      )
    }, [status, priority, assignee, watchers, props.wbsItemDelta])

    const submitComment = useCallback(async () => {
      if (!comment) return
      store.dispatch(
        postComment(
          props.applicationFunctionUuid!,
          props.wbsItem.uuid!,
          comment,
          mentions.map(v => ({
            mentionType: v.mentionType || '',
            uuid: v.userUuid || '',
          }))
        )
      )
      setComment('')
      setMentions([])
      if (props.wbsItem) {
        store.dispatch(
          finishEditComment(
            props.applicationFunctionUuid!,
            props.wbsItem.uuid!,
            undefined,
            undefined
          )
        )
      }
    }, [comment, mentions, props.applicationFunctionUuid, props.wbsItem.uuid])

    const actualHourChanged = (): boolean => {
      if (isComposing || !props.wbsItem?.wbsItemType?.isTask()) return false
      const currentValue =
        currentTaskActualWorks.current.find(
          v => v.actualDate === formatDate(actualDate.toDate())
        )?.hour ?? undefined
      const oldValue = currentValue ? formatHour(currentValue) : ''
      return oldValue !== actualHour
    }

    const statusChanged = useCallback((): boolean => {
      return status !== props.wbsItem?.status
    }, [status, props.wbsItem])
    const priorityChanged = useCallback((): boolean => {
      return priority !== props.wbsItem?.priority
    }, [priority, props.wbsItem])
    const assigneeChanged = useCallback((): boolean => {
      return assignee?.uuid !== props.wbsItem?.assignee?.uuid
    }, [assignee, props.wbsItem])
    const watchersChanged = useCallback((): boolean => {
      return (
        watchers?.map(v => v.uuid) !== props.wbsItem?.watchers?.map(v => v.uuid)
      )
    }, [watchers, props.wbsItem])
    const wbsItemChanged = useCallback((): boolean => {
      return (
        statusChanged() ||
        priorityChanged() ||
        assigneeChanged() ||
        watchersChanged() ||
        0 < Object.keys(props.wbsItemDelta ?? {}).length
      )
    }, [status, priority, assignee, watchers, props.wbsItemDelta])

    const formatMD = (date: string | undefined): string => {
      if (!date) return intl.formatMessage({ id: 'popper.tbd' })
      return new DateVO(date).format('M/D')
    }

    const isEdited = () => actualHourChanged() || wbsItemChanged() || comment

    const onActualHourChange = useCallback((value: string | undefined) => {
      setActualHour(parseToHalfNumberOrEmptyString(value))
    }, [])

    useEffect(() => {
      if (isComposing) return
      onActualHourChange(actualHour)
    }, [actualHour])

    const { anchorEl } = props
    const open = Boolean(anchorEl)
    if (!props.anchorEl || !initialized) {
      return <></>
    }
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={'auto'}
        style={{
          width: 280,
          padding: '5px 10px',
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          boxShadow: '0px 0px 6px #0000004d',
          borderRadius: '5px',
          zIndex: 1300,
          margin: '5px 10px !important',
        }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Fade in={true} timeout={100}>
            <Paper style={{ boxShadow: 'none' }}>
              <FormControl
                style={{
                  padding: '7px 0',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <RadioStepper
                  value={status}
                  steps={[
                    {
                      label: 'Discard',
                      value: WbsItemStatus.DISCARD,
                      activeColor: '#888888',
                      captionFt: (
                        <>
                          <ItemLabel>
                            {props.intl.formatMessage({
                              id: 'popper.scheduled',
                            })}
                          </ItemLabel>
                          <ItemLabel>
                            {props.intl.formatMessage({
                              id: 'popper.actual',
                            })}
                          </ItemLabel>
                        </>
                      ),
                    },
                    {
                      label: 'ToDo',
                      value: WbsItemStatus.TODO,
                      activeColor: '#A4A4A4',
                    },
                    {
                      label: 'Doing',
                      value: WbsItemStatus.DOING,
                      activeColor: '#FDF800',
                      captionFt: (
                        <>
                          <DateLabel
                            label={formatMD(
                              props.wbsItem?.scheduledDate?.startDate
                            )}
                            key={'popper-scheduled-start-date'}
                          />
                          {props.wbsItem?.actualDate?.startDate &&
                            [
                              WbsItemStatus.DOING,
                              WbsItemStatus.REVIEW,
                              WbsItemStatus.DONE,
                            ].includes(props.wbsItem?.status!) && (
                              <DateLabel
                                label={formatMD(
                                  props.wbsItem.actualDate.startDate
                                )}
                                key={'popper-actual-start-date'}
                              />
                            )}
                        </>
                      ),
                    },
                    {
                      label: 'Review',
                      value: WbsItemStatus.REVIEW,
                      activeColor: '#7CDF53',
                    },
                    {
                      label: 'Done',
                      value: WbsItemStatus.DONE,
                      activeColor: '#59C5DE',
                      captionFt: (
                        <>
                          <DateLabel
                            label={formatMD(
                              props.wbsItem?.scheduledDate?.endDate
                            )}
                            key={'popper-scheduled-end-date'}
                          />
                          {props.wbsItem?.actualDate?.endDate &&
                            props.wbsItem?.status === WbsItemStatus.DONE && (
                              <DateLabel
                                label={formatMD(
                                  props.wbsItem?.actualDate?.endDate
                                )}
                                key={'popper-actual-end-date'}
                              />
                            )}
                        </>
                      ),
                    },
                  ]}
                  onChange={v => setStatus(v)}
                  icon={
                    <DelayIcon
                      fontSize={20}
                      wbsItem={props.wbsItem as Partial<WbsItemRow>}
                    />
                  }
                />
              </FormControl>
              <PopperRow>
                <FormControl style={{ flexGrow: 1 }}>
                  <Box style={{ alignItems: 'center', display: 'flex' }}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: FontSize.SMALL, width: '108px' }}
                    >
                      {props.intl.formatMessage({
                        id: 'popper.actualHour',
                      })}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: FontSize.SMALL, width: '108px' }}
                    >
                      {actualDate.toLabel()}
                    </Typography>
                    <DatePicker
                      selected={actualDate.toDate()}
                      dateFormat={'yyyy/MM/dd'}
                      onChange={onChangeActualDate}
                      customInput={
                        <IconButton size={'small'}>
                          <EventNote
                            style={{ width: '15px', height: '15px' }}
                          />
                        </IconButton>
                      }
                      popperPlacement="right"
                      fixedHeight={true}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ActualHourSlider
                      size="small"
                      value={Number(actualHour) || 0}
                      disabled={!props.wbsItem?.wbsItemType?.isTask()}
                      step={0.25}
                      min={0}
                      max={12}
                      onChange={(event: any, value: number | number[]) => {
                        if (Array.isArray(value)) return
                        setActualHour(value.toFixed(2))
                      }}
                      marks={Array.from({ length: 12 }).map((_, index) => {
                        return {
                          value: index + 1,
                          label: `${index + 1}`,
                        }
                      })}
                      getAriaValueText={v => `${v}`}
                    />
                    <TextInput
                      id="actualHour"
                      variant="outlined"
                      onBlur={e => {
                        const num = toNumber(e.target.value)
                        if (!num) return '0.00'
                        setActualHour(formatHour(num))
                      }}
                      disabled={!props.wbsItem?.wbsItemType?.isTask()}
                      value={actualHour}
                      onChange={e => {
                        setActualHour(e.target.value)
                      }}
                      onCompositionStart={() => setIsComposing(true)}
                      onCompositionEnd={e => {
                        setIsComposing(false)
                        onActualHourChange((e.target as HTMLInputElement).value)
                      }}
                      style={{ maxWidth: 60 }}
                      placeholder={props.intl.formatMessage({
                        id: 'popper.actualHour.placeholder',
                      })}
                      inputProps={{
                        style: { textAlign: 'right', padding: '5px 14px' },
                      }}
                    />
                  </Box>
                </FormControl>
              </PopperRow>
              <PopperRow>
                <FormControl>
                  <ItemLabel>
                    {props.intl.formatMessage({
                      id: 'popper.priority',
                    })}
                  </ItemLabel>
                  <Select
                    value={priority}
                    onChange={e => setPriority(e.target.value as string)}
                    style={{
                      width: 110,
                      height: 30,
                      border: `1px solid ${BorderColor.GREY}`,
                      borderRadius: '5px',
                    }}
                    disableUnderline={true}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      disablePortal: true,
                      anchorReference: 'anchorPosition',
                      anchorPosition: {
                        top: 200,
                        left: 5,
                      },
                    }}
                    options={priorityOptions}
                    getOptionIconUrl={o => o.iconUrl}
                    getOptionLabel={o =>
                      o.nameI18n ? getLabel(o.nameI18n) : o.name
                    }
                  />
                </FormControl>
                <FormControl>
                  <ItemLabel>
                    {props.intl.formatMessage({
                      id: 'popper.assignee',
                    })}
                  </ItemLabel>
                  <Autocomplete
                    value={assignee?.name || ''}
                    style={{ width: 145 }}
                    options={assigneeOptionLabels}
                    renderOption={(props, option: string) => {
                      const assignee = assigneeOptions.find(
                        m => m.name === option
                      )
                      if (!assignee) {
                        return option
                      }
                      return (
                        <MenuItem
                          {...props}
                          style={{ fontSize: FontSize.MEDIUM, display: 'flex' }}
                        >
                          <Avatar
                            variant="circular"
                            src={assignee.iconUrl}
                            style={{
                              width: 20,
                              height: 20,
                              margin: '0 0 0 5px',
                              marginRight: '5px',
                            }}
                          />
                          {assignee.name}
                        </MenuItem>
                      )
                    }}
                    size={'small'}
                    popupIcon={<ExpandMoreIcon color="action" />}
                    onChange={(_, selectedItemLabel) => {
                      setAssignee(
                        assigneeOptions.find(
                          option => option.name === selectedItemLabel
                        )
                      )
                    }}
                    inputValue={assigneeText}
                    onInputChange={(event, value) => {
                      setAssigneeText(value)
                    }}
                    PopperComponent={props => {
                      return <Popper {...props} placement="bottom-start" />
                    }}
                    renderInput={params => {
                      let InputProps = { ...params.InputProps }
                      const value = assigneeText
                      const assignee = assigneeOptions.find(
                        member => member.name === value
                      )
                      if (assignee) {
                        InputProps = {
                          ...InputProps,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ marginRight: 0 }}
                            >
                              <Avatar
                                variant="circular"
                                style={{
                                  width: 20,
                                  height: 20,
                                  margin: '0 0 0 5px',
                                }}
                                src={assignee.iconUrl}
                              />
                            </InputAdornment>
                          ),
                        }
                      }
                      return (
                        <div
                          style={{ fontSize: FontSize.MEDIUM, display: 'flex' }}
                        >
                          <TextInput
                            {...params}
                            variant="outlined"
                            InputProps={InputProps}
                            fullWidth={true}
                          />
                        </div>
                      )
                    }}
                  />
                </FormControl>
              </PopperRow>
              <PopperRow>
                <FormControl>
                  <ItemLabel>
                    {props.intl.formatMessage({
                      id: 'popper.comment',
                    })}
                  </ItemLabel>
                  {props.wbsItem && (
                    <div style={{ width: 260, maxWidth: 260 }}>
                      <CommentEditor
                        applicationFunctionUuid={props.applicationFunctionUuid!}
                        dataUuid={props.wbsItem.uuid}
                        isEditing={false}
                        hideToolbar={true}
                        projectUuid={props.projectUuid}
                        setComment={onChangeComment}
                      />
                    </div>
                  )}
                </FormControl>
              </PopperRow>
              <Box style={{ textAlign: 'center', padding: '5px 0' }}>
                <SubmitButton
                  onClick={onSubmit}
                  hideIcon={true}
                  disabled={submitButtonDisabled || !isEdited()}
                  notEdited={!isEdited()}
                  hideTooltip={true}
                />
              </Box>
            </Paper>
          </Fade>
        </ClickAwayListener>
      </Popper>
    )
  })
)

const PopperRow = styled(Box)({
  padding: '7px 0',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
})

const ItemLabel = ({ children }) => {
  return (
    <Typography variant="subtitle2" style={{ fontSize: FontSize.SMALL }}>
      {children}
    </Typography>
  )
}

const ActualHourSlider = styled(Slider)({
  width: '100%',
  flexGrow: 1,
  margin: '0 15px 0 5px',
  color: Color.MAIN,
  '& .MuiSlider-markLabel': {
    top: '18px',
    fontSize: FontSize.SMALL,
  },
  '& .MuiSlider-markLabelActive': {
    color: Color.MAIN,
  },
  '& .Mui-disabled.Mui-disabled': {
    color: Color.DISABLED,
  },
})

const DateLabel = ({ label }: { label: string }) => {
  return (
    <Typography
      variant="subtitle2"
      style={{
        zIndex: 1,
        display: 'inline',
        fontSize: FontSize.SMALL,
        textAlign: 'center',
      }}
    >
      {label}
    </Typography>
  )
}
